<template>
  <div
    class="t-checkbox"
    :class="{
      't-checkbox--checked': mutableValue,
      't-checkbox--disabled': disabled,
      't-checkbox--indeterminate': indeterminate,
      't-checkbox--error': hasError,
    }"
  >
    <label
      class="t-checkbox__wrapper"
    >
      <input
        :id="id"
        v-model="mutableValue"
        type="checkbox"
        class="t-checkbox__input"
        :disabled="disabled"
        @input="$emit('input', $event.target.checked)"
        @change="$emit('change', $event.target.checked)"
      >
      <div class="t-checkbox__image-wrap">
        <TIconSvg
          name="checkbox-checked"
          :stroke="stroke"
          view-box="0 0 24 24"
          width="24"
          height="24"
          class="t-checkbox__image"
        />
      </div>
      <span
        v-if="label || $slots.label"
        class="t-checkbox__label"
      >
        <slot name="label">
          {{label}}
        </slot>
      </span>
    </label>
  </div>
</template>

<script>
import colors from '@/assets/scss/_variables.scss';

export default {
  name: 'TCheckbox',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },
  },

  computed: {
    indeterminate() {
      return this.value === null;
    },

    stroke() {
      return this.disabled ? colors.GRAY_LIGHT_2 : colors.BLUE_LIGHT;
    },

    mutableValue: {
      get() {
        return this.value;
      },
      set() {
        return this.value;
      },
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

.t-checkbox {
  display: inline-flex;

  $self: &;
  $size: 16px;

  &:hover {
    .t-checkbox__image-wrap {
      border-color: darken($gray-light-2, 10%);
    }
  }

  &--disabled {
    cursor: default;

    .t-checkbox__wrapper {
      cursor: default;
    }

    .t-checkbox__image-wrap {
      background-color: $gray-ultra-light-1;
    }

    &:hover {
      .t-checkbox__image-wrap {
        border-color: $gray-light-2;
      }
    }

    &#{$self}--indeterminate {
      .t-checkbox__image-wrap::after {
        background-color: $gray-dark-1;
      }
    }

    #{$self}__image {
      stroke: #347AF0;
    }
  }

  &--indeterminate {
    #{$self}__image-wrap::after {
      @include squareBox(8px);
      position: absolute;
      content: '';
      background-color: $blue-light;
      border-radius: 1px;
    }
  }

  &__wrapper {
    display: inline-flex;
    cursor: pointer;
  }

  &__input {
    display: none;
  }

  &__label {
    font: $t3-regular;

    user-select: none;
    margin-left: 10px;
  }

  &__image-wrap {
    position: relative;
    @include centerFlexXY;
    @include squareBox($size);
    flex: none;
    border: 1px solid $gray-light-2;
    border-radius: 2px;
  }

  &__image {
    @include squareBox($size);
    opacity: 0;
    transition: opacity .2s ease-in-out;

    #{$self}--checked & {
      opacity: 1;
    }
  }

  &--checked {
    #{$self}__image-wrap {
      border: 1px solid $blue-light;
    }

    &:hover {
      .t-checkbox__image-wrap {
        border-color: $blue-light;
      }
    }
  }
}
</style>
