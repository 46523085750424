export const locales = [
  {
    code: 'ru',
    file: 'ru.js',
    name: 'Русский',
  },
];

export default ({ app }) => {
  // eslint-disable-next-line no-param-reassign
  app.i18n.pluralizationRules = {
    // https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const last2Digits = Number(String(choice).slice(-2));

      const teen = last2Digits > 10 && last2Digits < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    },
  };
};
