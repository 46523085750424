<template>
  <div>
    <transition name="header-menu">
      <div
        class="header-menu"
        :class="{'header-menu--empty': companiesList.length < 2}"
      >
        <ul
          v-if="!isAdmin && companiesList.length > 1"
          class="header-menu__companies"
        >
          <li
            v-for="(company) of companiesList"
            :key="company.id"
            class="mb-2 header-menu__company"
            @click="changeCompany(company)"
          >
            <span class="header-menu__company-text">
              {{company.roles[0].label}} {{company.name}}
            </span>
            <TRadio
              :value="activeCompanyId"
              :property-value="company.id"
              @click.native.prevent
            />
          </li>
        </ul>
        <TFlex column>
          <TButton
            color="white"
            transparent
            icon="exit"
            icon-width="24"
            icon-height="24"
            icon-fill="#B5C3DB"
            icon-stroke="#B5C3DB"
            icon-view-box="0 0 24 24"
            class="header-menu__button"
            :disabled="isLogoutBtnLoading"
            @click="logout"
          >
            {{$t('sidepanel.Logout')}}
          </TButton>
        </TFlex>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

// Mixins
import accessMixin from '~/assets/js/mixins/accessMixin';

const RELOAD_TRIGGER = 'reloadTrigger';

export default {
  name: 'HeaderMenu',

  mixins: [accessMixin],

  props: {
    position: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      isLogoutBtnLoading: 'auth/login/GET_LOGOUT_CONTROL_LOADING',
      companiesList: 'main/getUserCompaniesList',
      activeCompanyId: 'main/getUserActiveCompanyId',
    }),
  },

  methods: {
    ...mapActions({
      logOut: 'auth/login/LOG_OUT',
      removeAuthCookie: 'general/REMOVE_AUTH_COOKIE',
      removeAuthHeader: 'general/REMOVE_AUTH_HEADER',
      updateCurrentCompany: 'main/updateCurrentCompany',
      saveLocale: 'main/saveLocale',
    }),

    changeCompany(newCompany) {
      this.updateCurrentCompany(newCompany.id);
    },

    async logout() {
      try {
        await this.logOut();
        this.removeAuthCookie();
        this.removeAuthHeader();

        this.$router.push('/auth');
        localStorage.setItem(RELOAD_TRIGGER, new Date().valueOf());
        localStorage.removeItem('last-orders-filter');
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

.header-menu {
  $self: &;

  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: 8px 1px;
  width: 100%;
  background-color: $white;
  transform: translateY(100%);
  border: 1px solid $gray-light-1;
  min-width: 200px;
  max-width: 200px;

  &:hover {
    background-color: $gray-ultra-light-2;
  }

  &__companies {
    margin: 0;
    padding-left: 0;
  }

  &__company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light-brown-grey;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &-text {
      max-width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .t-radio.t-radio--checked::before {
      background-color: $golden-yellow;
    }

    .t-radio__text-container {
      display: none;
    }
  }

  &__button {
    width: 100%;
    border-radius: 0;

    &:hover:not([disabled]) {
      color: $black;
    }
  }

  &__error {
    white-space: normal;
    background-color: rgba(#fff, 0.4);
  }

  .t-button {
    justify-content: flex-start;
    font: $t3-regular;
    transition: opacity 0.3s;
    padding: 8px 16px;
    color: $black;

    &--transparent:hover:not(.t-button--disabled) {
      color: $black;
    }

    &__icon {
      margin-right: 12px;
    }

    &:hover {
      //opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }
}

.header-menu-enter-active,
.header-menu-leave-active {
  transition: opacity 0.3s;
}

.header-menu-enter,
.header-menu-leave-to {
  opacity: 0;
}
</style>
