<template>
  <AppEntry>
    <div class="auth">
      <TFlex row>
        <TCol
          md="6"
          class="auth__col-left"
        >
          <div class="auth__left">
            <div class="auth__logo">
              <img
                src="~assets/img/logo.svg"
              >
            </div>
            <div class="auth__wrapper">
              <div class="auth__img">
                <img
                  src="~assets/img/auth-img-1x.png"
                  srcset="~assets/img/auth-img-2x.png 2x"
                >
              </div>
              <div class="auth__info">
                <div class="auth__info-title">
                  {{$t('auth.Title')}}
                </div>
                <div class="auth__info-statistic statistic">
                  <span class="statistic__count mb-1">
                    {{$t('auth.Statistic count')}}
                  </span>
                  <span class="statistic__transportation">
                    {{$t('auth.Statistic transportation')}}
                  </span>
                </div>
              </div>
              <HelpCenter />
            </div>
          </div>
        </TCol>
        <TCol
          md="6"
        >
          <div class="auth__right">
            <nuxt />
          </div>
        </TCol>
      </TFlex>
    </div>
  </AppEntry>
</template>

<script>
import HelpCenter from '~/components/Auth/HelpCenter';
import AppEntry from '~/AppEntry';

export default {
  components: { AppEntry, HelpCenter },
};
</script>

<style lang="scss">
@import '~/assets/scss/_variables';
@import '~/assets/scss/_mixins';

.auth {
  @include fullScreen;
  display: flex;
  background-color: $white;
  overflow: hidden;

  &__col-left{
    margin-top: 80px;
    margin-bottom: 32px;
  }

  &__left{
    padding-right: 90px;
    height: 100%;
  }

  &__logo,
  &__info,
  .help{
    padding-left: 80px;
  }

  &__logo{
    margin-bottom: 45px;

    img{
      display: block;
    }
  }

  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 88%;
  }

  &__info{
    display: flex;
  }

  &__info-title{
      font: $h3-medium;
      padding-right: 75px;
      max-width: 670px;
    }

  &__img{
    padding-left: 12px;
    margin-bottom: 50px;

    img{
      display: block;
    }
  }

  .statistic{
    display: flex;
    flex-direction: column;
    min-width: 145px;

    &__count{
      font: 500 46px / 100% $font-family;
      color: $orange-light;
    }

    &__transportation{
      font: $t3-regular;
    }
  }

  &__right{
    width: 500px;
    padding-top: 72px;
  }

  .t-field{
    max-width: 420px;
  }

  .t-link{
    font: $t1-regular;
  }
}
</style>
