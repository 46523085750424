import moment from 'moment';
import { formatCarTrailerNumber } from '~/assets/js/utils';

export const state = () => ({
  finesList: [],
  isTableLoading: false,
  pagination: null,
  labels: null,
  backendQuery: null,
  finesData: {},
  finePaymentStatuses: {},
  finePaidByLabes: {},
  org: {},
  divisions: [],
  fineHistory: null,
  isFineUpdated: false,
  fineAtStatuses: {},
  fineTmsStatuses: {},
  serviceOffices: [],
  hasPaymentDetails: true,
  mailForRequest: '',
  queryHistory: null,
});

export const getters = {
  getFinesData: state => state.finesData,
  getFineStatuses: state => state.finePaymentStatuses,
  getPaidByLabels: state => state.finePaidByLabes,
  getOrgLabels: state => state.org,
  getFineHistory: state => state.fineHistory,
  getFineAtStatuses: state => state.fineAtStatuses,
  getFineTmsStatuses: state => state.fineTmsStatuses,
  getDivisions: state => state.divisions,
  getServiceOffices: state => state.serviceOffices,
  getPaymentDetails: state => state.hasPaymentDetails,
  getMailForRequest: state => state.mailForRequest,
};

export const mutations = {
  setFinesList(state, payload) {
    state.finesList = payload.map(fine => ({
      ...fine,
      amount: Number.isInteger(Number(fine.amount))
        ? Number(fine.amount)
        : Number(fine.amount).toFixed(2),
      amountToPay: Number.isInteger(Number(fine.amount_to_pay))
        ? Number(fine.amount_to_pay)
        : Number(fine.amount_to_pay).toFixed(2),
      isDiscountExpired: moment().isAfter(fine.discount_date),
      discount: Number(fine.amount) * (fine.discount_size / 100),
      discountDateTo: moment(fine.discount_date).format('DD.MM.YYYY'),
      violationDate: moment(fine.violation_date).format('DD.MM.YYYY'),
      billDate: moment(fine.bill_date).format('DD.MM.YYYY'),
      driverFullname: fine.driver ? fine.driver?.profile.shortName : '-',
      carNumber: fine.car?.number ? formatCarTrailerNumber(fine.car?.number) : '',
      isNotPaid: fine.payment_status !== 'paid',
      paidAt: fine.payment_status === 'paid' ? moment(fine.paid_at).format('DD.MM.YYYY') : null,
    }));
  },

  setIsTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setFineIsUpdated(state, payload) {
    state.isFineUpdated = payload;
  },

  setPagination(state, payload) {
    state.pagination = payload;
  },

  setLabels(state, payload) {
    state.labels = payload;
  },

  setBackendQuery(state, payload) {
    state.backendQuery = payload;
  },

  setFinesData(state, payload) {
    state.finesData = {
      ...payload.fine,
      media: payload.media,
    };
  },

  setFineLabels(state, { at_statuses, payment_statuses, paid_by, org, tms_statuses }) {
    state.finePaymentStatuses = payment_statuses;
    state.finePaidByLabes = paid_by;
    state.org = org;
    state.fineAtStatuses = at_statuses;
    state.fineTmsStatuses = Object.entries(tms_statuses)
      .filter(status => status[1])
      .map(status => ({ id: status[0], name: status[1] }));
  },

  setFineHistory(state, payload) {
    state.fineHistory = payload;
  },

  setDivisions(state, payload) {
    state.divisions = payload;
  },

  setServiceOffices(state, payload) {
    state.serviceOffices = payload;
  },

  setPaymentDetails(state, payload) {
    state.hasPaymentDetails = payload;
  },

  setMailForRequest(state, payload) {
    state.mailForRequest = payload;
  },

  setQueryHistory(state, payload) {
    state.queryHistory = payload;
  },
};

export const actions = {
  async loadFines({ commit }, payload) {
    commit('setIsTableLoading', true);

    try {
      const {
        data: {
          data: fines,
          meta: pagination,
          query: backendQuery,
        },
      } = await this.$axios.get('/fines', { params: payload });
      commit('setPagination', pagination);
      commit('setBackendQuery', backendQuery);
      commit('setFinesList', fines);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setIsTableLoading', false);
    }
  },

  async loadLabels({ commit }) {
    try {
      const { data: { labels } } = await this.$axios.get('/fines/labels');
      commit('setLabels', labels);
      commit('setFineLabels', labels);
    } catch (error) {
      console.log(error);
    }
  },

  async changeStatuses(_, payload) {
    try {
      await this.$axios.post('/fines/status', { status: 'paid', fineIds: payload });
    } catch (error) {
      console.error(error);
    }
  },

  async fetchFinesData({ commit }, payload) {
    try {
      const { data } = await this.$axios.get(`/fines/show/${payload}`);
      commit('setFinesData', data.data);
      commit('setPaymentDetails', data.data.fine.details.isPaymentPossible);
      // commit('setPaymentDetails', data.data.fine.details.isPaymentPossible && !data.data.fine.comment_errors);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchFinesLabels({ commit }) {
    try {
      const { data } = await this.$axios.get('/fines/labels');
      commit('setFineLabels', data.labels);
    } catch (error) {
      console.error(error);
    }
  },

  async updateFine({ commit }, payload) {
    try {
      await this.$axios.put(`/fines/${payload.id}`, { ...payload });
      commit('setFineIsUpdated', true);
    } catch (error) {
      console.error(error);
    }
  },

  async saveColumn({ commit }, payload) {
    try {
      await this.$axios.post(`/fines/${payload.id}/save-column`, { ...payload });
      commit('setFineIsUpdated', true);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchFinesHistory({ commit }, payload) {
    try {
      const { data } = await this.$axios.get(`/fines/${payload}/history`);
      commit('setFineHistory', data.history);
    } catch (error) {
      console.error(error);
    }
  },

  async loadDivisions({ commit }) {
    try {
      const { data } = await this.$axios.get('/divisions/get-fesco-divisions');
      commit('setDivisions', data);
    } catch (error) {
      console.log(error);
    }
  },

  async loadServiceOffices({ commit }) {
    try {
      const { data } = await this.$axios.get('/registration/flow/offices');
      commit('setServiceOffices', data.service_offices);
    } catch (error) {
      console.log(error);
    }
  },

  async loadMailForRequest({ state, commit }) {
    try {
      const { data } = await this.$axios.get('fines/payment-recipient-email');
      commit('setMailForRequest', data.data.email);
    } catch (error) {
      console.log(error);
    }
  },
};
