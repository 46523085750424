// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.t-field__field--multiline::-webkit-scrollbar{position:relative;z-index:999;height:6px;width:6px}.t-field__field--multiline::-webkit-scrollbar-track{background:#c9d2e1;width:auto;height:auto;opacity:.4;border-radius:6px}.t-field__field--multiline::-webkit-scrollbar-thumb{background-color:rgba(0,50,160,.16);border-radius:6px}.t-field__field--disabled{border:1px solid red}.t-field__label.t-field__label-input{position:absolute;top:50%;transform:translateY(-50%);color:#a7b5ce;left:16px;transition:all .3s;padding-right:10px!important;max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content}.t-field__label.t-field__label-input.required:after{position:absolute;content:\"*\";right:0;top:0;color:#fe991f;font-size:12px}.t-field--active .t-field__label-input{font:400 12px/14px \"Graphik LCG\";background-color:#fff;padding:0 6px;top:0;left:10px;right:auto}.t-field--actions .t-field__field,.t-field--actions input{padding-right:45px}.t-field--actions .t-field__field--error,.t-field--actions input{padding-right:75px}.t-field__actions{position:absolute;top:50%;transform:translateY(-50%);right:5px;display:flex;align-items:center;grid-gap:8px;gap:8px;z-index:3}.t-field__actions .t-button.t-button--without-text{width:100%;display:flex}.t-field__actions-icon{padding-top:0;padding-bottom:0;display:flex;align-items:center;text-transform:uppercase;height:44px;cursor:pointer}.prepend-wrapper{display:flex;justify-content:space-between;align-items:center}.prepend-icon-input{position:relative;z-index:2;background-color:#fff;outline:none;width:100%;border:1px solid #c9d2e1;padding:19px 19px 19px 30px!important;resize:vertical;transition:border-color .15s ease;-webkit-appearance:none;-moz-appearance:none;appearance:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
