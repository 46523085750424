<template>
  <transition name="toast">
    <div>
      <div
        :style="[
          { width },
          { height },
        ]"
        class="c-toast"
        :class="{
          'c-toast--warning': warning,
          'c-toast--error': error,
          'c-toast--static': positionStatic
        }"
      >
        <TIconSvg
          :name="setIcon()"
          :stroke="stroke"
          fill="none"
          height="19"
          width="19"
          view-box="0 0 24 24"
        />
        <div class="c-toast__container">
          <div
            v-if="hasHeader"
            class="c-toast__header"
          >
            <slot name="header" />
          </div>
          <div
            v-if="hasBody"
            class="c-toast__body"
          >
            <slot name="body" />
          </div>
        </div>
        <button
          v-if="hasButton"
          type="button"
          :class="{'has-header': hasHeader}"
          class="c-toast__close-button"
          @click="closeToast"
        >
          <TIconSvg
            name="close"
            fill="#6f7b89"
            stroke="none"
            height="16"
            width="16"
            view-box="0 0 24 24"
          />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
// colors
import colors from '@/assets/scss/_variables.scss';

export default {
  name: 'TToast',

  props: {
    withTimer: {
      type: Boolean,
      default: true,
    },

    timerSeconds: {
      type: Number,
      default: 5,
    },

    width: {
      type: String,
      default: '',
    },

    height: {
      type: String,
      default: '',
    },

    isDesktopAndUp: {
      type: Boolean,
      default: false,
    },

    warning: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    positionStatic: {
      type: Boolean,
      default: false,
    },

    hasButton: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    countdownTimeout: null,
  }),

  computed: {
    hasHeader() {
      return this.$slots.header !== undefined;
    },

    hasBody() {
      return this.$slots.body !== undefined;
    },

    stroke() {
      if (this.warning) {
        return colors.ORANGE_LIGHT;
      } if (this.error) {
        return colors.RED_LIGHT;
      }
      return colors.BLUE_DARK;
    },
  },

  created() {
    if (this.withTimer) {
      this.setCountdownTimer();
    }
  },

  mounted() {
    setTimeout(() => {
      document.addEventListener('click', this.clickListener);
    }, 0);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.clickListener);
  },

  methods: {
    setCountdownTimer() {
      this.countdownTimeout = setTimeout(() => {
        this.$emit('close');
      }, this.timerSeconds * 1000);
    },

    closeToast() {
      clearTimeout(this.countdownTimeout);

      this.$emit('close');
    },
    clickListener() {
      this.$emit('close');
    },

    setIcon() {
      if (this.warning) {
        return 'warning';
      } if (this.error) {
        return 'danger-triangle';
      }
      return 'info';
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.c-toast {
  position: fixed;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  z-index: 9999;
  min-height: 44px;
  border-radius: 8px;
  padding: 14px 18px;
  background-color: $gray-ultra-light-2;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;

  &--warning{
    background-color: $orange-ultra-light;
  }

  &--error{
    background-color: $red-ultra-light;
  }

  &--static{
     position: static;
     transform: none;
     z-index: auto;
  }

  &__close-button {
    font-size: 0;
    cursor: pointer;
    transform: none;
    z-index: auto;
  }

  &__header {
    font-size: 16px;
    font-weight: 500;
    padding-right: 30px;
  }

  &__body {
    font: $t4-regular;
    padding: 0 10px;
  }
}

.toast-enter {
  opacity: 0;
}

.toast-leave-active {
  opacity: 0;
}
</style>
