// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.loading-wrapper{position:fixed;top:0;right:0;width:100%;z-index:1000;display:flex;flex-direction:column;align-items:flex-start;justify-content:center;overflow:hidden;height:2px;background-color:#1ba0de}.indeterminate{width:100%;height:100%}.indeterminate:before{-webkit-animation:indeterminate_first 2s ease-out infinite;animation:indeterminate_first 2s ease-out infinite}.indeterminate:after,.indeterminate:before{content:\"\";position:absolute;height:100%;background-color:#347af0}.indeterminate:after{-webkit-animation:indeterminate_second 2s ease-in infinite;animation:indeterminate_second 2s ease-in infinite}@-webkit-keyframes indeterminate_first{0%{left:-100%;width:100%}to{left:100%;width:10%}}@keyframes indeterminate_first{0%{left:-100%;width:100%}to{left:100%;width:10%}}@-webkit-keyframes indeterminate_second{0%{left:-150%;width:100%}to{left:100%;width:10%}}@keyframes indeterminate_second{0%{left:-150%;width:100%}to{left:100%;width:10%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
