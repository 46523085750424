import { isEmpty, scrollTo } from 'chober';

export default {
  patterns: {
    any: /.*/,
    letters: /[а-яёa-z]/i,
    cyrillicLetters: /[а-я]/i,
    numbers: /\d/,
    irrationalNumber: /^([0-9,.]{0,10}?)$/,
    negativeNumber: /^([0-9-]{0,10}?)$/,
    numbersOrLetters: /[A-zА-яЁё0-9]/i,
    numbersOrDashes: /[\d-]/,
    phoneCode: /[0-9]/,
  },

  regulars: {
    regularLettersAndSpaces: /^[\x20]*[-a-zA-ZЁё\u0410-\u044F`]+[\x20]*$/,
    regularOnlyLetters: /^[-a-zA-ZЁё\u0410-\u044F`]+$/,
    regularSurname: /^[а-яёa-z- ]+$/i,
    regularDecimalNumbers: /^\d*[.,]?\d+$/,
    regularCarNumbers: /[A-zА-яЁё]{1}\d{3}[A-zА-яЁё]{2}\d{2,3}|\d{4}[A-zА-яЁё]{2}\d{2,3}|[A-zА-яЁё]{2}\d{6,7}/,
    regularCarNumbersCyrillic: /[А-яЁё]{1}\d{3}[А-яЁё]{2}\s\d{2,3}|[А-яЁё]{2}\d{4}\s\d{2,3}/,
    regularTime: /^([0-1][0-9]|2[0-3]):([0-5][0-9])|24:00/,
    phone: /^(\+\d{1,3})[ ]+(\d|-| ){13,17}/,
  },

  bigNumbersMask: ['#', '##', '####', '# ###', '## ###', '### ###', '### ####',
    '# ### ###', '## ### ###', '### ### ###', '### ### ####',
    '# ### ### ###', '## ### ### ###', '### ### ### ###',
    '### ### ### ####', '# ### ### ### ###', '## ### ### ### ###',
    '### ### ### ### ###'],

  phoneMask: ['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##',
    '+# (###) ### ###-##-##', '+## (###) ### ###-##-##'],

  methods: {
    async validateForm() {
      const isValidForm = await this.$validator.validateAll();
      if (!isValidForm) {
        this.$nextTick(this.scrollToFirstError);
      }

      return isValidForm;
    },

    validateField(fieldName) {
      return this.$validator.validate(fieldName);
    },

    async validateFields(fieldsArray) {
      const isValidForm = await this.$validator.validateAll(fieldsArray);

      if (!isValidForm) {
        this.$nextTick(this.scrollToFirstError);
      }

      return isValidForm;
    },

    addErrors(errors, ids = null) {
      if (errors.response === undefined || isEmpty(errors.response.data)) {
        this.errors.add({
          field: Object.keys(this.fields)[0],
          msg: `${errors.message}: ${errors.request?.status}`,
        });
        console.log(`${errors.message}: ${errors.request?.status}`)
        throw errors;
      }

      const { errors: responseErrors } = errors.response.data;

      if (!responseErrors) {
        return;
      }

      if (isEmpty(responseErrors) && errors.request?.status === 400) {
        this.errors.add({
          field: Object.keys(this.fields)[0],
          msg: this.$t('ui.validation-error'),
        });
        throw errors.response;
      }

      Object.entries(responseErrors).forEach(([field, messages]) => {
        // let msg = null;
        // if (typeof messages === 'string') {
        //   msg = messages;
        // } else {
        const msg = messages[0];
        // }
        let formattedField = '';

        if (ids) {
          // eslint-disable-next-line radix
          const index = parseInt(field.match(/\d+/));
          formattedField = field.replace(/\d+/, ids[index]);
        }

        this.errors.add({ field: formattedField || field, msg });
      });

      this.$nextTick(this.scrollToFirstError);
    },

    addError(field, msg) {
      this.errors.add({ field, msg });
    },

    removeErrors(fields = []) {
      if (!isEmpty(fields)) {
        fields.forEach(field => {
          const errorIndex = this.errors.items.findIndex(errorItem => field === errorItem.field);

          if (errorIndex !== -1) {
            this.$delete(this.errors.items, errorIndex);
          }
        });
        return;
      }

      this.errors.clear();
    },

    scrollToFirstError() {
      const firstError = Array.from(document.querySelectorAll('.j-error'))
        .find(error => error.style.display !== 'none');

      if (!firstError) {
        return;
      }

      scrollTo(firstError);
    },
  },
};
