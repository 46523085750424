// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.comments__preloader{position:absolute!important;z-index:1;top:0;left:0}.comments-title{background-color:#f4f4f4;height:58px;padding:0 20px 0 32px;border-bottom:1px solid #d4d9dc}.comments-title p{line-height:20px;font-weight:600}.comments-title__close{cursor:pointer}.comments-icon{display:inline-flex;position:relative;height:24px;cursor:pointer}.comments-icon svg{outline:none}.comments-icon__info{position:absolute}.comments-icon__info--numbers{top:-7px;right:0;font-size:12px;line-height:15px;background-color:#fff}.comments-icon__info--indicator{top:-1px;right:-1px;width:12px;height:12px;border-radius:50%;border:2px solid #fff;background-color:#fb5858}.comments-content{max-height:284px}.comments-content__wrapper{height:100%}.comments-content__comment,.comments-content__no-comments{padding:15px 15px 15px 32px}.comments-content__comment{border-top:1px solid #d4d9dc}.comments-content__comment-action.cancel{color:#cb1939}.comments-content__comment-action.approve{color:#399e50}.comments-content__comment-action.accredited{color:#0a5aa4}.comments-content__comment-name{line-height:20px;font-weight:600}.comments-content__comment-date{line-height:20px;font-size:12px;margin-left:8px}.comments-content__comment-title{display:flex;justify-content:space-between}.comments-bottom{background-color:#f4f4f4;padding:12px 32px 20px;border-top:1px solid #d4d9dc}.comments-bottom__subtitle{line-height:20px}.comments-bottom__input{margin-bottom:12px}.comments-bottom__input .t-field__field{max-height:90px}.comments .t-dropdown__content-wrapper{width:450px;padding:0;border:1px solid #d4d9dc}.order-info .form-header .t-dropdown__content-wrapper{right:0;left:auto;transform:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
