<template>
  <v-popover
    trigger="hover"
    :offset="offset"
    delay="300"
    :popperOptions="{
      modifiers: {
        preventOverflow: {
          escapeWithReference: isPreventOverflow
        }
      }
    }"
  >
    <template>
      <slot />
    </template>
    <template slot="popover">
      <div
        :style="{ textAlign }"
      >
        <p
          v-if="!isHtml"
          class="tooltip-content"
        >
          {{info}}
        </p>
        <p
          v-else
          v-html="info"
        ></p>
      </div>
      <TLink
        v-if="hasLink"
        v-close-popover
        :is-underlined="false"
        :href="href"
        target="_blank"
        class="tooltip-link"
      >
        <p class="tooltip-link__text">
          {{linkText}}
        </p>
      </TLink>
    </template>
  </v-popover>
</template>

<script>
export default {
  name: 'TTooltip',

  props: {
    info: {
      type: String,
      default: '',
    },
    hasLink: {
      type: Boolean,
      default: false,
    },
    linkText: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    offset: {
      type: String,
      default: '-1px',
    },
    textAlign: {
      type: String,
      default: 'left',
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
    isPreventOverflow: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped lang="scss">
.popover {
  padding: 8px 16px;

  .tooltip-content {
    text-align: center;
    min-width: 100px;
  }

  .tooltip-link__text{
    text-align: center;
    text-decoration-line: underline;
  }
}
</style>
