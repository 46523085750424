<template>
  <div
    class="t-banner"
    :class="{'t-banner__absolute': isAbsolute}"
  >
    <div
      class="t-banner__wrapper"
      :class="{
        'wrapper-red': isWarning,
        'wrapper-blue': isNeutral,
        'wrapper-orange': isAttention,
        'wrapper-gray': isGray,
      }"
    >
      <div class="t-banner__info">
        <TIconSvg
          :name="iconName"
          class="icon"
          :height="heightIcon"
          :width="widthIcon"
          :view-box="viewBox"
          :fill="fill"
          :stroke="stroke"
        />
        <div
          :class="{
            't-banner__text-absolute': isAbsolute,
            't-banner__text': !isAbsolute,
          }"
        >
          {{text}}
        </div>
      </div>
      <button
        v-if="hasCloseIcon"
        type="button"
        class="t-banner__close-button"
        @click="$emit('close')"
      >
        <TIconSvg
          :name="'close'"
          height="16"
          width="16"
          view-box="0 0 20 20"
          fill="#8392A3"
          stroke="#8392A3"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TBanner',

  props: {
    iconName: {
      type: String,
      default: 'danger-triangle',
    },
    fill: {
      type: String,
      default: '#FB5858',
    },
    stroke: {
      type: String,
      default: '#FB5858',
    },
    isAbsolute: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: '',
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isNeutral: {
      type: Boolean,
      default: false,
    },
    isAttention: {
      type: Boolean,
      default: false,
    },
    hasCloseIcon: {
      type: Boolean,
      default: true,
    },
    heightIcon: {
      type: String,
      default: '18',
    },
    widthIcon: {
      type: String,
      default: '18',
    },
    viewBox: {
      type: String,
      default: '0 0 22 22',
    },
    isGray: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';
.t-banner {
  margin-top: 10px;

  &__wrapper {
    border-radius: 8px;
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    width: 100%;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__text {
    font: $t3-regular;
    color: $black;
    padding-left: 10px;
  }

  &__text-absolute {
    font: $t4-regular;
    color: $black;
    padding-left: 10px;
  }

  &__close-button {
    cursor: pointer;
  }

  &__absolute {
    position: absolute;
    top: 50%;
    right: 60%;
    transform: translate(-50%, -50%);
    cursor: default;
    width: 677px;
  }
}

.wrapper-red {
  background-color: $red-ultra-light;
}

.wrapper-blue {
  background-color: $gray-light-2;
}

.wrapper-orange {
  background-color: $orange-ultra-light;
}

.wrapper-gray {
  background-color: $gray-ultra-light-2;
}
</style>
