import moment from 'moment';

export const state = () => ({
  isTableLoading: false,
  query: {},
  biddingData: {},
  ordersListForAuction: [],
  timeFromLoad: null,
  lastLoadedParams: null,
  statusList: [],
  biddingList: [],
  offerStateLabels: [],
  pagination: {},
  productUnitTypes: [],
  auctionOrdersCounters: null,
  biddingCounters: null,
});

export const getters = {
  getTableLoading: state => state.isTableLoading,
  getQuery: state => state.query,
  getStatusList: state => state.statusList,
  getAuctionOrdersList: state => state.ordersListForAuction,
  getTimeFromLoad: state => state.timeFromLoad,
  getLastLoadedParams: state => state.lastLoadedParams,
  getBiddingList: state => state.biddingList,
  getOfferStateLabels: state => state.offerStateLabels,
  getPaginationData: state => state.pagination,
  getProductUnitTypes: state => state.productUnitTypes,
  getAuctionOrdersCounters: state => state.auctionOrdersCounters,
  getBiddingCounters: state => state.biddingCounters,
};

export const mutations = {
  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setQuery(state, payload) {
    state.query = payload;
  },

  setBiddingData(state, payload) {
    state.biddingData = payload;
    state.biddingList = payload.data;
    state.statusList = Object.values(payload.statusLabels);
    state.offerStateLabels = payload.offerStateLabels;
    state.pagination = {
      current_page: payload.meta.current_page,
      from: payload.meta.from,
      last_page: payload.meta.last_page,
      path: payload.meta.path,
      per_page: payload.meta.per_page,
      to: payload.meta.to,
      total: payload.meta.total,
    };
    state.productUnitTypes = payload.productUnitTypes;
  },

  setLastLoadingDate(state) {
    state.timeFromLoad = moment();
  },

  setLastLoadingParams(state, payload) {
    state.lastLoadedParams = payload;
  },

  setAuctionOrdersList(state, payload) {
    state.ordersListForAuction = payload;
  },

  clearOrdersListForAuction(state) {
    state.ordersListForAuction = [];
  },

  setBiddingCounters(state, payload) {
    state.biddingCounters = payload;
  },

  setAuctionOrdersCounters(state, payload) {
    state.auctionOrdersCounters = payload;
  },
};

export const actions = {
  async loadBidding({ commit }, { params, disableLoading }) {
    try {
      if (!disableLoading) {
        commit('setTableLoading', true);
      }
      await this.$axios.get('/auctions', {
        params: {
          ...params,
          clients: params?.clients?.length ? JSON.parse(params.clients).map(({ id }) => Number(id)) : undefined,
        },
      })
        .then(({ data }) => {
          commit('setBiddingData', data);
          commit('setLastLoadingParams', params);
          commit('setLastLoadingDate');
          return data;
        });
    } catch (err) {
      console.error(err);
    } finally {
      commit('setTableLoading', false);
    }
  },

  async loadTrading({ commit }, { params, disableLoading }) {
    try {
      if (!disableLoading) {
        commit('setTableLoading', true);
      }
      await this.$axios.get('/trading-auctions', {
        params: {
          ...params,
          clients: params?.clients?.length ? JSON.parse(params.clients).map(({ id }) => Number(id)) : undefined,
        },
      })
        .then(({ data }) => {
          commit('setBiddingData', data);
          commit('setLastLoadingParams', params);
          commit('setLastLoadingDate');
          return data;
        });
    } catch (err) {
      console.error(err);
    } finally {
      commit('setTableLoading', false);
    }
  },

  saveQuery({ commit }, payload) {
    commit('setQuery', payload);
  },

  saveAuctionOrdersList({ commit }, payload) {
    commit('setAuctionOrdersList', payload);
  },

  async loadAuctionOrdersCounters({ commit }, payload) {
    try {
      const newCountersData = await this.$axios.get('/auctions/counters', { params: payload });
      commit('setAuctionOrdersCounters', newCountersData.data);
    } catch (error) {
      console.log(error);
    }
  },

  async loadBiddingCounters({ commit }, payload) {
    try {
      const newCountersData = await this.$axios.get('trading-auctions/counters', { params: payload });
      commit('setBiddingCounters', newCountersData.data);
    } catch (error) {
      console.log(error);
    }
  },

};
