<template>
  <div
    class="sidepanel"
    :class="{ 'sidepanel--shrunk': isShrunk }"
  >
    <div class="sidepanel__wrapper">
      <div
        class="sidepanel__toggle"
        @click="shrunkMenu"
      >
        <TIconSvg
          class="sidepanel__toggle-icon"
          name="menu/arrow"
          width="24"
          height="24"
          view-box="0 0 24 24"
        />
      </div>
      <div
        v-bar
        class="sidepanel__scroll-wrapper"
        :class="{
          'sidepanel__scroll-wrapper--shrunk': isShrunk,
        }"
      >
        <div class="sidepanel__inner">
          <TLink
            :is-underlined="false"
            :is-nuxt-link="false"
            class="sidepanel__logo"
            href="/"
          >
            <img
              v-if="isShrunk"
              class="sidepanel__logo-img"
              src="~assets/img/logo-mini.svg"
            >
            <img
              v-else
              class="sidepanel__logo-img"
              src="~assets/img/logo.svg"
            >
          </TLink>
          <SidepanelMenu
            class="sidepanel__menu"
            :items="menu"
            :is-shrunk="isShrunk"
            @unshrunk-menu="unshrunkMenu"
          />
        </div>
      </div>
      <!--      <div-->
      <!--        v-if="!isShrunk"-->
      <!--        class="sidepanel__help help"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <TLink-->
      <!--            :is-underlined="false"-->
      <!--            :is-nuxt-link="false"-->
      <!--            :target="'_blank'"-->
      <!--            class="help__phone"-->
      <!--            :href="`tel:${$options.phoneNumber}`"-->
      <!--          >-->
      <!--            {{$options.phoneNumber}}-->
      <!--          </TLink>-->
      <!--          <p class="help__free-call mb-2">-->
      <!--            {{$t('sidepanel.Free call')}}-->
      <!--          </p>-->
      <!--          <TLink-->
      <!--            :is-underlined="false"-->
      <!--            :is-nuxt-link="false"-->
      <!--            :target="'_blank'"-->
      <!--            class="help__mail mt-2"-->
      <!--            :href="`mailto:${$options.emailLink}`"-->
      <!--          >-->
      <!--            {{$options.emailLink}}-->
      <!--          </TLink>-->
      <!--          <span class="help__support">-->
      <!--            {{$t('sidepanel.Technical support')}}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div v-else>-->
      <!--        <TLink-->
      <!--          :is-underlined="false"-->
      <!--          :is-nuxt-link="false"-->
      <!--          :target="'_blank'"-->
      <!--          class="help__phone&#45;&#45;shrunk"-->
      <!--          :href="`tel:${$options.phoneNumber}`"-->
      <!--        >-->
      <!--          <TIconSvg-->
      <!--            class="sidepanel__toggle-icon"-->
      <!--            name="menu/calling"-->
      <!--            width="24"-->
      <!--            height="24"-->
      <!--            stroke="#347af0"-->
      <!--            view-box="0 0 24 24"-->
      <!--          />-->
      <!--        </TLink>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// utils
import { formatPhone } from '~/assets/js/utils';

// mixins
import accessMixin from '~/assets/js/mixins/accessMixin';

// Components
import SidepanelMenu from './components/SidepanelMenu';

// Constants
import { FESCO_SUPPORT_EMAIL, FESCO_SUPPORT_PHONE, MENU_SECTIONS } from '~/assets/js/constants';

export default {
  name: 'Sidepanel',

  components: {
    SidepanelMenu,
  },

  emailLink: FESCO_SUPPORT_EMAIL,
  phoneNumber: FESCO_SUPPORT_PHONE,

  mixins: [accessMixin],

  props: {
    isShownSidebar: {
      type: Boolean,
      default: true,
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      userCompaniesList: 'main/getUserCompaniesList',
      getCurrentUser: 'main/getCurrentUser',
      userCompany: 'main/getUserCompany',
      counter: 'main/getCounter',
      isUserCompanyRequiringAccreditation: 'main/isUserCompanyRequiringAccreditation',
    }),

    isShowDirectories() {
      return this.isViewUser
        || this.isViewDrivers
        || this.isViewCars
        || this.isViewDirectoriesTransporters;
    },

    menu() {
      return [
        {
          text: this.$t('sidepanel.Orders'),
          icon: 'menu/order',
          link: '/applications',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isCounter: true,
          quantity: this.counter.orders,
          isShow: this.isMenuItemVisible('applications') && ((this.isViewOrders && !this.isTransporter)
            || (this.isUserCompanyRequiringAccreditation && !this.isTransporter)),
        },
        {
          text: this.$t('sidepanel.Trips'),
          icon: 'menu/location',
          link: '/trips',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isCounter: !this.isUserCompanyRequiringAccreditation,
          quantity: this.counter?.tripsAll || 0,
          isShow: this.isMenuItemVisible('trips') && this.isViewTrips,
        },
        {
          text: 'Торги',
          icon: 'menu/bidding1',
          link: '/bidding',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          // isCounter: !this.isUserCompanyRequiringAccreditation,
          // quantity: this.tripsList?.length,
          isShow: this.isMenuItemVisible('auctions') && this.isViewBidding,
        },
        {
          text: this.$t('availableTransport.Available transport'),
          icon: 'menu/transport',
          link: '/available-transport',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isShow: this.isMenuItemVisible('road_trains') && this.isViewRoadTrains,
        },
        {
          text: this.$t('sidepanel.Contractors'),
          icon: 'menu/contragent',
          link: '/companies',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isShow: this.isMenuItemVisible('customers') && (this.isViewCustomers || this.isViewTransporter),
        },
        {
          text: this.$t('sidepanel.Fines'),
          icon: 'menu/wallet',
          link: '/fines',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isShow: this.isMenuItemVisible('fines') && this.isViewFines,
        },
        {
          text: this.$t('sidepanel.Company Profile'),
          icon: 'menu/profile',
          link: '/profile',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isShow: this.isMenuItemVisible('profile') && this.isViewProfile,
        },
        {
          text: this.$t('sidepanel.Directories'),
          icon: 'menu/paper',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isShow: this.isShowDirectories,
          children: [
            {
              text: this.$t('sidepanel.Transporters'),
              link: '/transporters',
              isShow: this.isMenuItemVisible('transporters') && this.isViewTransporter,
            },
            {
              text: this.$t('sidepanel.Transport'),
              link: '/transport',
              isShow: this.isMenuItemVisible('cars') && this.isViewCars,
            },
            {
              text: this.$t('sidepanel.Drivers'),
              link: '/drivers',
              isShow: this.isMenuItemVisible('drivers') && this.isViewDrivers,
            },
            {
              text: this.$t('sidepanel.Partners'),
              link: '/partners',
              isShow: false,
            },
            {
              text: this.$t('sidepanel.Contact faces'),
              link: '/contact-faces',
              isShow: false,
            },
            {
              text: this.$t('sidepanel.Containers'),
              link: '/containers',
              isShow: false,
            },
            {
              text: this.$t('sidepanel.Addresses'),
              link: '/addresses',
              isShow: false,
            },
            {
              text: this.$t('sidepanel.Staff'),
              link: '/users',
              isShow: this.isMenuItemVisible('users') && this.isViewUser,
            },
          ].filter(child => child.isShow),
        },
        {
          text: this.$t('Administration'),
          icon: 'setting',
          link: '/administration',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isShow: this.isMenuItemVisible('admin'),
        },
        {
          text: this.$t('sidepanel.Instructions'),
          icon: 'book-new',
          link: '/instructions',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
          isShow: true,
          children: [
            {
              text: this.instructions[0].text,
              link: this.instructions[0].link,
              isShow: this.isMenuItemVisible('transporters'),
              nuxtLink: false,
            },
          ],
        },
      ].filter(v => v.isShow);
    },

    isShrunk() {
      return this.$store.getters['general/IS_MENU_SHRUNK'];
    },
    instructions() {
      return [
        { id: 1, text: this.$t('instructions.Transporter instructions'), link: 'https://alltrucks-test.fesco.com/storage/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F_%D0%9F%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B7%D1%87%D0%B8%D0%BA%D0%B0.pdf' },
      ];
    },
  },

  methods: {
    formatPhone,

    shrunkMenu() {
      this.$cookies.set('isMenuOpened', !this.isShrunk);
      this.$store.dispatch('general/TOGGLE_MENU_SHRUNK', !this.isShrunk);
    },

    unshrunkMenu() {
      this.$cookies.set('isMenuOpened', false);
      this.$store.dispatch('general/TOGGLE_MENU_SHRUNK', false);
    },

    isMenuItemVisible(item) {
      return this.getCurrentUser.roles?.reduce(
        (isVisible, role) => MENU_SECTIONS[role?.name]?.includes(item) || isVisible, false
      );
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';
@import 'assets/scss/mixins';
.sidepanel {
  $self: &;

  color: $gray-ultra-dark;
  height: 100vh;
  width: $full-sidepanel-width;
  min-width: $full-sidepanel-width;
  position: relative;
  transition: width 0.2s;

  .vb-dragger{
    display: none;
  }

  &__logo{
    padding: 30px 95px 23px 32px;
    &-img{
      display: block;
      max-width: 100%;
    }
  }

  .help {
    position: absolute;
    bottom: 26px;
    left: 28px;
    padding-right: 35px;

    &__support{
      font: $t4-medium;
      display: inline-block;
      margin-bottom: 10px;
    }

    &__free-call {
      font: $caption-regular;
    }

    &__phone.t-link{
      font: $t2-regular;
      color: $black;
    }

    &__mail.t-link{
      font: $t4-regular;
      display: inline-block;
    }
  }

  &__toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -12px;
    z-index: 232;
    top: 28px;
    background-color: $white;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    cursor: pointer;
    transition: opacity 0.15s;
  }

  &--shrunk {
    width: $shrunk-sidepanel-width;
    min-width: $shrunk-sidepanel-width;

    #{$self}__logo{
      padding: 30px 19px 23px 20px;
      &-img{
        display: block;
        max-width: 100%;
      }
    }

    .sidepanel-counter{
      @include centerAbsoluteXY;
    }
  }

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 115;

    .help__phone--shrunk{
      position: absolute;
      left: 50%;
      bottom: 32px;
      transform: translateX(-50%);
    }
  }

  &__inner {
    display: flex !important; // For scroll plugin, which adds display: block
    flex-direction: column;
    align-items: stretch;
  }

  &__scroll-wrapper {
    height: 100vh;
    width: $full-sidepanel-width;
    padding: 0 0 200px 0;
    transition: width 0.2s;
    background-color: $white;
    border-right: 1px solid $gray-light-1;

    &--shrunk {
      width: $shrunk-sidepanel-width;
      overflow: initial !important; // For counts, which adds overflow: initial

      .sidepanel-menu{
        width: $shrunk-sidepanel-width;
      }

      .sidepanel__inner{
        overflow: hidden !important; // For counts, which adds overflow: hidden
        width: calc(100% + 15px) !important; // For sidebar, which adds width: calc
      }

      .sidepanel-menu-item--active {
        .sidepanel-menu-item__icon--active{
          stroke: $white
        }
      }

      .sidepanel-menu-item--active,
      .sidepanel-submenu-item--active{
        background-color: $blue-light;
      }
    }

    &--hidden {
      width: 0;
    }
  }

  &__inner {
    display: flex !important; // For scroll plugin, which adds display: block
    flex-direction: column;
    align-items: stretch;
    position: relative;
  }
}
</style>
