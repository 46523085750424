import { v4 as uuid } from 'uuid';
import { ORDER_STATUSES_ICONS, ORDER_CONTAINER_STATUSES_ICONS } from '~/assets/js/constants';

export const state = () => ({
  isOrdersFormLoading: false,
  order: {},
  labels: {},
  clients: [],
  history: [],
  order_types: [],
});

const transformIntoFormData = (
  formData,
  labels,
) => ({
  ...formData,
  status: formData.status && labels.order_statuses.find(status => status.id === formData.status),
  container_status: formData.container_status && labels.container_statuses.find(container => container.id === (formData.container_status)),
  container_type: formData.container_type ? labels.container_types.find(type => type.id === formData.container_type) : { name: '-' },
  container_owner: formData.container_owner ? labels.container_owners.find(owner => owner.id === formData.container_owner) : { name: '-' },
  container_disposition: labels.container_dispositions?.find(disposition => disposition.id === formData.container_disposition),
  type: formData.type && labels.order_types.find(type => type.id === (formData.type)),
  operations: formData.operations.map(operation => ({ ...operation, uuid: uuid() })),
  transportation_type: formData.transportation_type && labels.transportation_types.find(transportation => transportation.id === (formData.transportation_type)),
  customs_type: formData.customs_type && labels.customs_types.find(customs => customs.id === formData.customs_type),
  transporter: formData.trip?.[0]?.transporter || null,
  driver: formData.trip?.[0]?.driver ? { ...formData.trip?.[0]?.driver, name: formData.trip?.[0]?.driver?.profile?.shortName } : null,
  car: formData.trip?.[0]?.car ? { ...formData.trip?.[0]?.car, name: formData.trip?.[0]?.car?.number } : null,
  trailer: formData.trip?.[0]?.trailer ? { ...formData.trip?.[0]?.trailer, name: formData.trip?.[0]?.trailer?.number } : null,
  commentForTransporterDriver: formData.trip?.[0]?.comment || '',
  container: formData.container ? {
    ...formData.container,
    id: formData.container?.id || null,
    number: formData.container?.number || null,
    status: formData.container?.status && labels.container_statuses.find(status => status.id === (formData.container.status)) || null,
  } : null,
});

export const getters = {
  GET_ORDER: state => state.order,
  GET_STATUSES: state => state.labels.order_statuses || [],
  GET_CONTAINER_STATUSES: state => state.labels.container_statuses || [],
  GET_CONTAINER_TYPES(state) {
    return state.labels.container_types.filter(item => (/^\d{2}[A-Z]{2}$/).test(item.name)) || [];
  },
  GET_CONTAINER_OWNERS: state => state.labels.container_owners || [],
  GET_CONTAINER_DISPOSITIONS: state => state.labels.container_dispositions || [],
  GET_OPERATION_TYPES: state => state.labels.operation_types || [],
  GET_OPERATIONS_SUBTYPES: state => state.labels.operation_subtypes || [],
  GET_ORDER_TYPES: state => state.labels.order_types || [],
  GET_TRANSPORTATION_TYPES: state => state.labels.transportation_types || [],
  GET_CUSTOMS_TYPES: state => state.labels.customs_types || [],
  GET_ADDRESS_TYPES: state => state.labels.address_types || [],
  GET_FORM_DATA: state => transformIntoFormData(
    state.order,
    state.labels,
  ),
  GET_ORDER_HISTORY: state => state.history,
  GET_ALLTR_STATUSES: state => state.labels.alltr_statuses || [],
};

export const actions = {
  SAVE_ORDER({ commit, dispatch }, payload) {
    commit('setOrdersFormLoading', true);
    return this.$axios.put(`orders/${payload.id}`, {
      ...payload,
    })
      .then(({ data }) => {
        commit('setOrder', data.order);
      })
      .then(({ data }) => {
        this.$axios.post('cartography', { order_id: payload.id });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        commit('setOrdersFormLoading', false);
      });
  },

  LOAD_ORDER({ commit, dispatch }, payload) {
    commit('setOrdersFormLoading', true);
    return this.$axios.get(`/orders/${payload}`)
      .then(({ data }) => {
        commit('setOrder', data.data);
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        commit('setOrdersFormLoading', false);
      });
  },

  LOAD_LABELS({ commit, rootGetters }) {
    return this.$axios.get('/orders/labels')
      .then(({ data }) => {
        commit('setLabels', data);
      }).catch(e => console.log(e));
  },

  LOAD_ORDER_HISTORY({ commit }, id) {
    return this.$axios.get(`/orders/${id}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);
      })
      .catch(e => console.log(e));
  },

  CLEAR_ORDER_DATA({ commit }) {
    commit('clearOrder');
  },

  DELETE_ORDER(_, payload) {
    return this.$axios.delete(`/orders/${payload.id}`, {
      data: {
        comment: payload.comment,
      },
    });
  },

  LOAD_CUSTOMERS_LIST({ commit }) {
    return this.$axios.get('/orders/customers')
      .then(({ data }) => {
        commit('setClients', data.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  GET_ORDER_TO_COPY({ commit, dispatch }, payload) {
    return Promise.all([
      dispatch('LOAD_ORDER', payload),
      dispatch('LOAD_LABELS'),
    ]);
  },

  LOAD_CONTRACTORS_TARIFFS(_, payload) {
    return this.$axios.get('/orders/get-tariffs', {
      params: payload,
    });
  },

  SAVE_CONTAINER_STATUS(_, payload) {
    return this.$axios.post('/containers/update-status-cantainer ', {
      ...payload,
    });
  },

  SAVE_CONTAINER(_, payload) {
    return this.$axios.post('/containers ', {
      ...payload,
    });
  },

  SAVE_ADDRESS(_, payload) {
    return this.$axios.post('/addresses', {
      ...payload,
    });
  },

  SAVE_CONTACT_PERSON(_, payload) {
    return this.$axios.post('/contact-persons', {
      ...payload,
    });
  },

  SEND_FORM_TRANSPORTER({ commit }, params) {
    const { applicationId, data } = params;
    return this.$axios.put(`/orders/${applicationId}/send-to-transporter`, data);
  },

};

export const mutations = {
  setOrdersFormLoading(state, payload) {
    state.isOrdersFormLoading = payload;
  },

  setOrder(state, payload) {
    state.order = payload;
  },

  clearOrder(state) {
    state.order = {};
  },

  setLabels(state, payload) {
    state.labels = {
      ...payload,
      order_statuses: Object.entries(payload.order_statuses).map(([id, name]) => {
        const currentStatus = Object.values(ORDER_STATUSES_ICONS).find(icon => icon.id === Number(id));

        return {
          name,
          id: Number(id),
          icon: currentStatus?.icon,
          color: currentStatus?.color,
        };
      }),
      container_statuses: Object.entries(payload.container_statuses).map(([id, name]) => {
        const currentStatus = Object.values(ORDER_CONTAINER_STATUSES_ICONS).find(icon => icon.id === Number(id));

        return {
          name,
          id: Number(id),
          icon: currentStatus?.icon,
          color: currentStatus?.color,
        };
      }),
      container_types: Object.entries(payload.container_types).map(([id, container]) => ({ id: Number(id), name: container.container_type, size: container.fitting_size, net: container.net })),
      container_owners: Object.entries(payload.container_owners).map(([id, name]) => ({ id: Number(id), name })),
      container_dispositions: Object.entries(payload.dispositions).map(([id, name]) => ({ id: Number(id), name })),
      operation_types: Object.entries(payload.operation_types).map(([id, name]) => ({ id: Number(id), name })),
      order_types: Object.entries(payload.order_types).map(([id, name]) => ({ id: Number(id), name })),
      transportation_types: Object.entries(payload.transportation_types).map(([id, name]) => ({ id: Number(id), name })),
      customs_types: Object.entries(payload.customs_types).map(([id, name]) => ({ id: Number(id), name })),
      alltr_statuses: Object.entries(payload.alltr_statuses).map(([id, name]) => ({ id: Number(id), name })),
      address_types: Object.values(payload.address_types),
    };
  },

  setHistory(state, payload) {
    state.history = payload;
  },
};
