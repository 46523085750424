<template>
  <div class="t-input-search">
    <TInput
      :value="value"
      :label="label"
      :placeholder="placeholder"
      is-search
      @enter="$emit('search', $event)"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
      <template #actions>
        <div
          v-if="value"
          class="t-input-search__clear"
        >
          <TIconSvg
            name="close"
            :width="24"
            :height="24"
            :view-box="'0 0 24 24'"
            :stroke="stroke"
            @click="iconClick"
          />
        </div>
        <TButton
          class="t-input-search__search-btn"
          icon="search"
          icon-view-box="0 0 24 24"
          icon-height="24"
          icon-width="24"
          transparent
          @click="$emit('search', $event)"
        />
      </template>
    </TInput>
  </div>
</template>

<script>
import colors from '@/assets/scss/_variables.scss';

export default {
  name: 'TInputSearch',

  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  computed: {
    stroke() {
      return colors.GRAY_DARK_1;
    },
  },

  methods: {
    iconClick() {
      this.$emit('input', '');
      this.$emit('icon-click');
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-input-search {
  display: flex;
  width: 100%;

  &__clear {
    height: 24px;
    cursor: pointer;
  }

  .t-field__icon {
    right: 10px;
    cursor: pointer;
  }

  &__search-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-right: 1px;
  }

  .t-field__label.t-field__label-input{
    padding-right: 80px;
  }

   .t-field--active .t-field__label-input{
    padding: 0 6px;
  }
}
</style>
