<template>
  <div
    ref="header"
    class="header"
  >
    <TLink
      v-if="getCurrentBreadcrumbs"
      :is-underlined="false"
      class="header__go-back-link"
      @click.native="goBack"
    >
      <TIconSvg
        class="header__arrow-back"
        name="chevron"
        view-box="0 0 18 18"
        height="18"
        width="18"
      />
      {{getCurrentBreadcrumbs.text}}
    </TLink>
    <div
      class="header__info header-info"
      :class="{
        'header__info--open': isMenuShown
      }"
    >
      <TBanner
        v-show="isBannerShow"
        is-warning
        text="Установите безопасный персональный пароль, используя ссылку в окне авторизации"
        @close="closeBanner"
      />
      <TBanner
        v-show="fineEditPage && !hasPaymentDetails"
        is-warning
        :has-close-icon="false"
        :text="$t('fines.There are no payment details for this payee')"
      />
      <div class="header-info__container">
        <div class="header-info__user">
          <div class="header__name">
            <div
              ref="nameContainerRef"
              class="header__name-text"
              :class="{'header__name-text--double-surname': hasDoubleSurname}"
            >
              {{shortUserName || userName}}
            </div>
          </div>
          <div class="header__position">
            {{userPosition}} {{userCompanyName}}
          </div>
        </div>
        <TIconSvg
          class="header__arrow"
          :class="{'header__arrow--up': isMenuShown}"
          name="arrow-down"
          view-box="0 0 24 24"
          :stroke="$options.colors.BLUE_LIGHT"
          height="16"
          width="16"
          @click="isMenuShown=!isMenuShown"
        />
      </div>
      <HeaderMenu
        v-show="isMenuShown"
        class="header__menu"
      />
    </div>
  </div>
</template>

<script>
// Utils
import { formatPhone } from '@/assets/js/utils';

// Mixins
import { mapGetters, mapMutations } from 'vuex';
import accessMixin from '~/assets/js/mixins/accessMixin';
import routerMixin from '~/assets/js/mixins/routerMixin';

import breadcrumbs from '~/assets/js/breadcrumbs';

// Components
import HeaderMenu from './components/HeaderMenu';

// SCSS
import colors from '~/assets/scss/_variables.scss';
import TBanner from '~/components/Common/TBanner';

export default {
  name: 'MainHeader',

  colors,

  components: {
    TBanner,
    HeaderMenu,
  },

  mixins: [accessMixin, routerMixin],

  data: () => ({
    isMenuShown: false,
    isBannerShow: false,
  }),

  computed: {
    ...mapGetters({
      getBannerState: 'main/getIsSimplePassword',
      hasPaymentDetails: 'fines/getPaymentDetails',
    }),
    //
    isSimplePassword: {
      get() {
        return this.isBannerShow;
      },
      set(value) {
        this.isBannerShow = value;
      },
    },

    userName() {
      return (this.currentUser
        && this.currentUser.profile
        && (this.currentUser.profile.fullName.trim()
          ? this.currentUser.profile.fullName
          : formatPhone(this.currentUser.phone))) || 'Имя отсутствует';
    },

    shortUserName() {
      return this.currentUser?.profile?.shortName
        && this.currentUser.profile
        && this.currentUser.profile.shortName;
    },

    hasDoubleSurname() {
      return this.currentUser
        && this.currentUser.profile
        && this.currentUser.profile.surname
        && this.currentUser.profile.surname.includes('-');
    },

    userPosition() {
      if (this.isAdmin) {
        return this.$t('Administrator');
      }
      return this.currentUser?.active_company?.roles?.[0].label || '';
    },

    userCompanyName() {
      if (this.isAdmin) {
        return '';
      }

      return this.userCompany?.name;
    },

    getCurrentBreadcrumbs() {
      return breadcrumbs.find(crumb => crumb.routeName === this.$route.name);
    },

    fineEditPage() {
      return this.$route.name === 'fines-index-edit-id';
    },
  },

  created() {
    if (this.$route.name !== 'applications') {
      return;
    }

    if (process.client && this.getBannerState && !(sessionStorage.getItem('weak_password_window') === 'true')) {
      this.openBanner();
    }
  },

  mounted() {
    document.addEventListener('click', this.toggleDropdown);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.toggleDropdown);
  },

  methods: {

    toggleDropdown({ target }) {
      const checkOf = element => element || '';
      const header = checkOf(this.$refs.header);
      const hasTarget = element => element !== '' && element.contains(target);

      if (!hasTarget(header)) {
        this.isMenuShown = false;
      }
    },
    closeBanner() {
      if (process.client) {
        this.isBannerShow = false;
        sessionStorage.setItem('weak_password_window', 'true');
      }
    },
    openBanner() {
      this.isBannerShow = true;
    },
    goBack() {
      if (this.$route.name === 'fines-index-edit-id' || this.$route.name === 'transport-index-info-id' || this.$route.name === 'bidding-index-info-id') {
        this.$router.go(-1);
      } else if (this.$route.name === 'fines-index-info-id' || this.$route.name === 'transport-index-edit-id' || this.$route.name === 'bidding-index-edit-id') {
        this.$router.go(-2);
      } else {
        this.$router.push(this.getCurrentBreadcrumbs.link);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 53px 18px 63px;
    max-height: 80px;

  &__go-back-link{
    display: flex;
    align-items: center;
  }

  &__arrow-back{
    margin-right: 6px;
  }

  &--closed {
    margin-bottom: 16px;
    min-height: 40px;
  }

  &-info {
    padding-bottom: 3px;
    position: relative;
    user-select: none;
    cursor: pointer;
    margin-left: auto;

    &__container{
        display: flex;
    }

    &__user{
      margin-right: 10px;
      text-align: right;
    }
  }

  &__position {
    color: $gray-dark-2;
    font-size: 10px;
    line-height: 1.4;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__name-text {
    text-overflow: ellipsis;
    overflow: hidden;

    &--double-surname {
      white-space: initial;
      word-break: break-word;
      text-overflow: unset;
    }
  }

  &__arrow {
    position: relative;
    cursor: pointer;
    transform: rotate(0);
    transition: transform 0.3s;

    &--up {
      transform: rotate(180deg);
    }
  }
}
</style>
