import moment from 'moment';

export const state = () => ({
  transporterStaff: null,
  locations: [],
  platforms: [],
  isTableLoading: false,
  isTablePopupLoading: false,
  isTransporterStaffLoading: false,
  roadTrains: [],
  pagination: {},
  sortDateBy: null,
  backendQuery: null,
  currentForwarderInfo: {
    platforms: [],
    transporterStaff: null,
  },
  accessibleTransport: [],
  filters: {},
  currentCompany: {},
});

export const actions = {
  async getRoadTrains({ commit }, params) {
    try {
      commit('setTableLoading', true);

      const { data: { data, meta } } = await this.$axios.get('/road-trains?sortDate=desc', { params });

      commit('setRoadTrains', data);
      commit('setPagination', meta);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setTableLoading', false);
    }
  },

  async getQueryLabels({ commit }, params) {
    const { data: { data: query } } = await this.$axios.get('/road-trains/get-query-labels', { params });

    commit('setBackendQuery', query);
  },

  async getRoadTrainsDetails(_, params) {
    try {
      return await this.$axios.get('/road-trains/details', { params });
    } catch (error) {
      console.log(error);
    }
  },

  async loadTransporterStaff({ commit, state, rootGetters }, transporter_id) {
    const currentUserCompanyId = rootGetters['main/getCurrentUser'].active_company_id;

    try {
      commit('setTransporterStaffLoading', true);

      const {
        data: { data: { locations, platforms, ...staff } },
      } = await this.$axios.get('/transporters/staff', {
        params: {
          transporter_id,
        },
      });

      commit('setTransporterStaff', {
        ...staff,
        transporterId: transporter_id,
        currentUserCompanyId,
      });
      commit('setLocations', locations);
      commit('setPlatforms', {
        platforms,
        transporterId: transporter_id,
        currentUserCompanyId,
      });
    } catch (error) {
      console.log(error);
    } finally {
      commit('setTransporterStaffLoading', false);
    }
  },

  async postAvailableTransport(_, payload) {
    try {
      await this.$axios.post('/road-trains', payload);
    } catch (error) {
      console.log(error);
    }
  },

  async loadAccessibleTransport({ commit }, { companyId, date, locationId, platformSort, platformTypes, drivers, cars }) {
    try {
      commit('setTablePopupLoading', true);
      if (companyId) {
        const { data: { data: accessibleTransport } } = await this.$axios.get(`/transporters/${companyId}/accessible-transport`, {
          params: {
            date,
            location: locationId,
            platformSort,
            platformTypes,
            drivers,
            cars,
          },
        });
        commit('setAccessibleTransport', accessibleTransport);
      } else {
        const { data: { data: accessibleTransport } } = await this.$axios.get('/transporters/accessible-transport', {
          params: {
            date,
            location: locationId,
            platformSort,
            drivers,
            platformTypes,
            cars,
          },
        });
        commit('setAccessibleTransport', accessibleTransport);
      }
    } catch (error) {
      console.log(error);
    } finally {
      commit('setTablePopupLoading', false);
    }
  },

  setCurrentForwarderInfo({ commit, state }) {
    commit('setCurrentForwarderInfo', state.currentForwarderInfo);
  },
};

export const mutations = {
  setTransporterStaff(state, { drivers, trailers, cars, currentUserCompanyId, transporterId }) {
    const transporterStaff = {
      drivers: drivers.map(driver => ({ ...driver, name: driver.profile.fullName })),
      cars: cars.map(car => ({
        ...car,
        driverId: car.users?.[0]?.id || null,
        trailerId: car.trailers?.[0]?.trailer_id || car.trailers?.[0]?.id || null,
        name: car.number,
      }
      )),
      trailers: trailers.map(trailer => ({
        ...trailer,
        name: trailer.number,
      }
      )),
    };

    if (currentUserCompanyId === transporterId) {
      state.currentForwarderInfo.transporterStaff = transporterStaff;
    }

    state.transporterStaff = transporterStaff;
  },

  setAccessibleTransport(state, payload) {
    state.accessibleTransport = payload;
  },

  setLocations(state, payload) {
    state.locations = payload.map(({ id, name }) => ({
      id,
      name,
    }));
  },

  setPlatforms(state, { platforms, currentUserCompanyId, transporterId }) {
    if (currentUserCompanyId === transporterId) {
      state.currentForwarderInfo.platforms = platforms;
    }

    state.platforms = platforms;
  },

  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setTablePopupLoading(state, payload) {
    state.isTablePopupLoading = payload;
  },

  setTransporterStaffLoading(state, payload) {
    state.isTransporterStaffLoading = payload;
  },

  setRoadTrains(state, payload) {
    state.roadTrains = payload.map(train => ({
      ...train,
      date: moment(train.availability_date).format('DD.MM.YYYY'),
      platforms: Object.fromEntries(train.platforms.map(platform => [platform.id, platform])),
    }));
  },

  setPagination(state, payload) {
    state.pagination = payload;
  },

  setSortDateBy(state, payload) {
    state.sortDateBy = payload;
  },

  setBackendQuery(state, payload) {
    state.backendQuery = {
      ...payload,
      drivers: payload.drivers.map(({ id, profile }) => ({ id, name: profile.shortName })),
      cars: payload.cars.map(({ id, number }) => ({ id, name: number })),
    };
  },

  setFilters(state, filters) {
    state.filters = filters;
  },

  setCurrentCompany(state, currentCompany) {
    state.currentCompany = currentCompany;
  },

  setCurrentForwarderInfo(state, { platforms, transporterStaff }) {
    state.transporterStaff = transporterStaff;
    state.platforms = platforms;
  },
};
