<template>
  <div :class="{'scroll_wrapper': !isComments}">
    <div class="t-table">
      <div>
        <div class="t-table__panel t-table__panel-left">
          <div
            class="t-table__blur t-table__blur-left"
            :class="{'t-table__blur--visible': canScrollLeft }"
          />
          <TIconSvg
            v-if="canScrollLeft"
            name="arrow-scroll-left"
            class="t-table__panel-button"
            width="56"
            height="56"
            view-box="0 0 56 56"
            fill="none"
          />
        </div>
        <div class="t-table__panel t-table__panel-right">
          <div
            class="t-table__blur t-table__blur-right"
            :class="{'t-table__blur--visible': canScrollRight }"
          />
          <TIconSvg
            v-if="canScrollRight"
            name="arrow-scroll-right"
            class="t-table__panel-button"
            width="56"
            height="56"
            view-box="0 0 56 56"
            fill="none"
          />
        </div>
      </div>
      <vue-custom-scrollbar
        ref="scrollbar"
        class="t-table__scroll-area"
        :class="classScroll"
        tagname="div"
        :settings="settingsScroll"
        @ps-scroll-x="onScrollX"
        @ps-x-reach-start="canScrollLeft = false"
        @ps-x-reach-end="canScrollRight = false"
      >
        <table class="t-table__table">
          <thead class="t-table__thead">
            <tr class="t-table__th-tr">
              <slot
                name="header"
                :headers="headers"
              />
            </tr>
          </thead>
          <tbody
            v-if="!isLoading"
            class="t-table__tbody"
          >
            <template v-if="body && body.length > 0">
              <template v-for="(item, index) in tableData">
                <template v-if="insertTr">
                  <tr
                    :key="item.id || item.vueKey"
                    class="t-table__tb-tr"
                    :class="{ 't-table__tb-tr--flex': bodyRowFlex}"
                    @click="$emit('click', index)"
                  >
                    <slot
                      name="body"
                      :item="item"
                      :index="index"
                    />
                  </tr>
                </template>
                <template v-else>
                  <slot
                    name="body"
                    :item="item"
                    :index="index"
                  />
                </template>
                <slot
                  name="additional-body"
                  :item="item"
                  :index="index"
                  :headers="headers"
                />
              </template>
            </template>
          </tbody>
          <tfoot class="t-table__tfoot">
            <slot name="footer" />
          </tfoot>
        </table>
      </vue-custom-scrollbar>
      <div
        v-if="isLoading"
        class="t-table__preloader-wrapper"
      >
        <TPreloader
          class="t-table__preloader"
          :color="'#5E6979'"
          size="40"
        />
      </div>
      <div
        v-if="isShownEmpty && (!body || (body && body.length === 0)) && !isLoading"
        class="t-table__empty"
      >
        <slot name="empty" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TTable',

  components: {
    vueCustomScrollbar: () => import('vue-custom-scrollbar'),
  },

  props: {
    headers: {
      type: Array,
      default: () => [],
    },

    isComments: {
      type: Boolean,
    },

    body: {
      type: Array,
      default: () => [],
    },

    isShownEmpty: {
      type: Boolean,
      default: false,
    },

    isScrollable: {
      type: Boolean,
      default: false,
    },

    isScrollableX: {
      type: Boolean,
      default: false,
    },

    isScrollableY: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    bodyRowFlex: {
      type: Boolean,
      default: false,
    },

    isOpenDropdown: {
      type: Boolean,
      default: false,
    },

    insertTr: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      canScrollLeft: false,
      canScrollRight: false,
    };
  },

  computed: {
    tableData() {
      return this.body.map((item, index) => ({
        ...this.headers[index],
        headers: this.headers,
        item,
      }));
    },

    settingsScroll() {
      return {
        suppressScrollY: (!this.isScrollableY || this.isOpenDropdown) && !this.isScrollable,
        suppressScrollX: (!this.isScrollableX || this.isOpenDropdown) && !this.isScrollable,
        scrollXMarginOffset: 0,
      };
    },

    classScroll() {
      return {
        't-table__scroll-area--visible': this.isOpenDropdown,
      };
    },
  },

  mounted() {
    setTimeout(() => {
      const scrollbar = this.$refs.scrollbar.$el;
      if (scrollbar.scrollWidth > scrollbar.offsetWidth) {
        this.canScrollRight = true;
      }
    }, 1000);
  },

  methods: {
    onScrollX: _.throttle(function f(e) {
      const { scrollLeft, offsetWidth, scrollWidth } = e.target;

      if (scrollLeft > 0) {
        this.canScrollLeft = true;
      }

      if ((scrollLeft + offsetWidth) < scrollWidth) {
        this.canScrollRight = true;
      }
    }, 100),
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-table {
  position: relative;

  &__panel {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    z-index: 300;
    pointer-events: none;

    &-left {
      left: 0;
    }

    &-right {
      right: 0;
    }
  }

  &__panel-button {
    position: absolute;
    top: 200px;
    cursor: pointer;
    display: none;

    &:hover {
      filter: saturate(70%);
    }
  }

  &__blur {
    opacity: 0;
    height: 100%;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    transition: 0.3s opacity;
    display: flex;
    align-items: center;

    &-left {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    &--visible {
      opacity: 1;
    }
  }

  &__tb-tr {
    cursor: pointer;

    &--flex {
      display: flex;
    }

    td {
      word-wrap: break-word;
    }
  }

  &__scroll-area {
    &--visible {
      overflow: unset !important;
    }
    .ps__rail-x {
      display: none !important;
    }
  }

  &__empty {
    position: relative;
  }

  &__preloader {
    left: 50%;
    transform: translateX(-50%);

    &-wrapper {
      width: 100%;
      padding: 16px;
    }
  }
}

.scroll_wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  overflow: overlay;
  height: calc(100vh - 300px);
  scrollbar-color: $gray-light-2 rgba(0, 50, 160, 0.1);
}

.ps {
  touch-action: auto;
  overflow: visible !important;
}

.scroll_wrapper::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.scroll_wrapper:hover::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.scroll_wrapper::-webkit-scrollbar-track {
  background: $gray-light-2;
  width: 24px;
  height: auto;
  opacity: 0.4;
  border-radius: 6px;
}
.scroll_wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 50, 160, 0.16);
  border-radius: 6px;
}
</style>
