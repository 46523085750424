export const state = () => ({
  settingsData: [],
  cartographySettings: {},
});

export const getters = {
  getSettingsData: state => state.settingsData,
  getCartographySettings: state => state.cartographySettings,
};

export const mutations = {
  setSettingsList(state, payload) {
    state.settingsData = payload;
  },

  setCartographySettings(state, payload) {
    state.cartographySettings = payload;
  },
};

export const actions = {
  async getSettings({ commit }) {
    try {
      const { data } = await this.$axios.get('/settings/list');
      commit('setSettingsList', data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async loadCartographySettings({ commit }) {
    try {
      const { data } = await this.$axios.get('/cartography/settings');
      commit('setCartographySettings', data);
    } catch (error) {
      console.log(error)
    }
  },
};
