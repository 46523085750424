import { RELOAD_TRIGGER, RUSSIA_ISO_CODE } from '~/assets/js/constants';

function formattedUser(userInfo) {
  return {
    id: userInfo.id,
    phone: userInfo.phone,
    ...userInfo.profile,
    companyName: userInfo.company ? userInfo.company.name : '',
    companies: userInfo.companies,
    company: userInfo.company,
    permissions: userInfo.permissions
      ? userInfo.permissions.map(v => ({ ...v }))
      : [],
    inn: userInfo.company ? userInfo.company.inn : '',
    openSetting: false,
    roles: userInfo.roles,
    country_id: userInfo.country_id || RUSSIA_ISO_CODE,
    is_non_resident: userInfo.is_non_resident,
    fesco_divisions: userInfo.fesco_divisions,
    contractFescoDivisions: userInfo.contractFescoDivisions,
    is_blocked: userInfo.is_blocked,
  };
}

function formattedDataUser(data) {
  return {
    dataUsers: data.data.map(userInfo => formattedUser(userInfo)),
    originalDataUser: data.data,
    paginationOption: {
      ...data.meta,
      ...data.links,
    },
  };
}

export const state = () => ({
  isFormChecked: true,
  isFormChanged: false,
  isTableLoading: false,
  labels: {},
});

export const getters = {
  isTableLoading: state => state.isTableLoading,
  getLabels: state => state.labels,
};

export const actions = {
  getUsers({ commit }, payload) {
    commit('setTableLoading', true);

    return this.$axios.get('/users', { params: { ...payload } })
      .then(({ data }) => formattedDataUser(data))
      .catch(error => console.log(error))
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  getUser(state, id) {
    return this.$axios.get(`users/${id}`)
      .then(({ data }) => formattedUser(data.user));
  },

  setUser(state, payload) {
    return this.$axios.post('/users', { ...payload })
      .then(({ data }) => data);
  },

  updateUser(state, payload) {
    return this.$axios.put(`/users/${payload.id}`, { ...payload.data })
      .then(({ data }) => data);
  },

  blockUser(state, payload) {
    return this.$axios.put(`/users/blocking/${payload.id}`)
      .then(({ data }) => data);
  },

  unblockUser(state, payload) {
    return this.$axios.put(`/users/unBlocking/${payload.id}`)
      .then(({ data }) => data);
  },

  getRoles() {
    return this.$axios.get('/roles')
      .then(({ data }) => data.data.filter(role => role.id === 1 || role.id === 4 || role.id === 9));
  },

  getCompanies(store, search) {
    return this.$axios.get('/companies', { params: { search } })
      .then(({ data }) => data.data);
  },

  deleteUser(state, payload) {
    return this.$axios.delete(`/users/${payload.id}`)
      .then(({ data }) => data);
  },

  async getLabels({ commit }) {
    try {
      const { data } = await this.$axios.get('/users/labels');
      commit('setLabels', data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  authorizateByUser(state, id) {
    return this.$axios.post(`/login/admin-user/${id}`)
      .then(({ data }) => {
        this.$axios.setAuthHeader(data.token);
        this.$cookies.set('token', data.token, { maxAge: 60 * 60 * 24, path: '/' });
        localStorage.setItem(RELOAD_TRIGGER, new Date().valueOf());
        this.$router.push('/');
      }).catch(error => console.log(error));
  },

  getRouterOptions(state, route) {
    return {
      params: route.params,
      pageEdit: route.fullPath.includes('edit') && route.params.id !== undefined,
      pageCreate: route.fullPath.includes('create'),
      pageInfo: route.fullPath.includes('info'),
    };
  },

  checkForm({ commit }) {
    commit('CHECK_FORM');
  },

  changeFormStatus({ commit }) {
    commit('CHANGE_FORM_STATUS');
  },
};

export const mutations = {
  CHECK_FORM(state) {
    state.isFormChecked = !state.isFormChecked;
  },

  CHANGE_FORM_STATUS(state) {
    state.isFormChanged = !state.isFormChanged;
  },

  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setLabels(state, payload) {
    state.labels = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
