<template>
  <div
    v-tooltip="disabled ? {
      content: tooltipHint,
      trigger: 'hover',
      delay: { show: 100, hide: 200 },
      autoHide: true,
    } : null"
    class="t-field t-multiselect"
    :class="{
      't-field--no-label': !hasLabel,
      't-multiselect--loading': isLoading || loading,
      't-field--error': hasErrors,
      't-field--success': !hasErrors && hasSuccessMessages,
      't-field--ellipsis': isEllipsis,
      't-multiselect--opened': isSelectOpened,
      't-multiselect--active': isOpened,
      't-multiselect--offset-left': value && value.icon,
    }"
  >
    <div
      ref="container"
      class="t-field__field-wrapper"
      :class="{'multiselect__is-list-hidden': isListHidden}"
    >
      <Component
        :is="isDataAccessible ? 'Multiselect' : 'TInput'"
        ref="multiselect"
        :class="sizeableClasses"
        :value="value"
        :options="allowSelectAll ? groupOptions : items"
        :placeholder="(isOpened || !hasLabel) && placeholderText ? placeholderText : ' '"
        :select-label="selectLabel"
        :selected-label="selectedLabel"
        :deselect-label="deselectLabel"
        :show-labels="showLabels"
        :track-by="isStringItems ? undefined : itemValue"
        :label="isStringItems || !isDataAccessible ? undefined : itemText"
        :disabled="disabled"
        :multiple="multiple"
        :internal-search="isInternalSearch"
        :close-on-select="closeOnSelect"
        :clear-on-select="clearOnSelect"
        :group-select="allowSelectAll"
        :group-label="allowSelectAll ? 'label' : ''"
        :group-values="allowSelectAll ? 'values' : ''"
        :loading="isSelectLoading"
        :searchable="searchable || route"
        :allow-empty="allowEmpty"
        :hide-selected="hideSelected"
        :reset-after="resetAfter"
        :taggable="taggable"
        :limit="limit"
        :preselect-first="preselectFirst"
        :preserve-search="preserveSearch"
        :max-height="maxHeigth"
        @search-change="handleRequest"
        @input="isSearchStop = false, $emit('input', $event)"
        @change="isSearchStop = false, $emit('change', $event)"
        @remove="clearValue"
        @select="$emit('select', $event)"
        @close="isOpened = false, $emit('close', $event)"
        @open="open"
      >
        <template slot="singleLabel" slot-scope="props">
          <slot name="singleLabel">
            {{value[itemText] || value}}
          </slot>
        </template>
        <template slot="noResult">
          <slot
            v-if="$slots.noResult || noResultText"
            name="noResult"
          >
            {{!route ? noResultText : searchStatusText}}
          </slot>
        </template>
        <template #clear>
          <TIconSvg
            v-if="value && value.icon"
            :name="value.icon"
            view-box="0 0 24 24"
            height="24"
            width="24"
            fill="none"
            :stroke="value.color"
            class="multiselect__icon-left"
          />
          <slot name="start-adornment" />
        </template>
        <slot slot="noOptions">
          {{noOptionsText}}
        </slot>
        <slot slot="caret">
          <div class="multiselect__select">
            <TIconSvg
              v-show="showArrow"
              name="arrow-down"
              view-box="0 0 24 24"
              height="24"
              width="24"
              fill="none"
              :stroke="colorsIconSelect"
            />
          </div>
        </slot>
        <slot slot="clear">
          <TIconSvg
            v-if="!hasErrors && tagNames.length"
            class="multiselect__clear"
            name="close"
            view-box="0 0 24 24"
            height="24"
            width="24"
            fill="none"
            :stroke="$options.GRAY_DARK_1"
            @click="clearValue"
          />
        </slot>
<!--        <slot slot="clear">-->
<!--          <TIconSvg-->
<!--            v-if="clearTransporter"-->
<!--            class="multiselect__clear"-->
<!--            name="close"-->
<!--            view-box="0 0 24 24"-->
<!--            height="24"-->
<!--            width="24"-->
<!--            fill="none"-->
<!--            :stroke="$options.GRAY_DARK_1"-->
<!--            @click="clearValue"-->
<!--          />-->
<!--        </slot>-->
        <slot slot="limit">
          <div class="multiselect__limit">
            {{limitText}}
          </div>
        </slot>
        <div
          v-if="allowSelectAll && value.length === items.length"
          slot="selection"
        >
          {{allSelectedOptionsText}}
        </div>
        <template
          v-if="!isOpened"
          #selection
        >
          <span
            v-for="(tagName, index) in visibleTagNames"
            :key="tagName.vueKey"
            class="multiselect__tag"
            :class="{ 'multiselect__tag-disabled' : items[index] && items[index].$isDisabled }"
          >
            <span>
              {{tagName}}
            </span>
            <i
              class="multiselect__tag-icon"
              :class="{ 'multiselect__tag-icon-disabled' : items[index] && items[index].$isDisabled }"
              tabindex="1"
              @click="unselectTag(index)"
            />
          </span>
          <div
            v-if="tagNames.length > visibleTagNames.length"
            class="multiselect__tag-plus"
          >
            +{{tagNames.length - visibleTagNames.length}}
          </div>
          <TIconSvg
            v-if="!disabled && ((searchRoles && value) || (!hasErrors && tagNames.length) || (value && clearTransporter))"
            class="multiselect__clear"
            name="close"
            view-box="0 0 24 24"
            height="24"
            width="24"
            fill="none"
            tabindex="1"
            :stroke="$options.GRAY_DARK_1"
            @click="clearValue"
          />
        </template>
        <template
          v-if="multiple"
          slot="beforeList"
        >
          <div
            v-if="value && value.length"
            class="multiselect__tags-wrap--before"
          >
            <span
              v-for="(tagName, index) in tagNames"
              :key="tagName.vueKey"
              class="multiselect__tag"
              :class="{ 'multiselect__tag-disabled' : items[index] && items[index].$isDisabled }"
            >
              <span>
                {{tagName}}
              </span>
              <i
                class="multiselect__tag-icon"
                :class="{ 'multiselect__tag-icon-disabled' : items[index] && items[index].$isDisabled }"
                @click="unselectTag(index)"
              />
            </span>
          </div>
        </template>
        <template slot="afterList">
          <slot name="afterList" />
        </template>
        <template
          slot="option"
          slot-scope="props"
        >
          <TIconSvg
            v-if="value && props.option && props.option.icon"
            :name="props.option.icon"
            view-box="0 0 24 24"
            height="24"
            width="24"
            fill="none"
            :stroke="props.option.color"
            class="option__icon-left"
          />
          <span class="option__title">{{props.option[itemText] || props.option}}</span>
          <TIconSvg
            class="ml-2 option__icon"
            name="checkbox-checked"
            :stroke="$options.BLUE_LIGHT"
            view-box="0 0 24 24"
            width="22"
            height="14"
          />
        </template>
      </Component>
      <span
        v-if="hasLabel"
        class="t-field__label"
        :class="{
          't-field__label--error': hasErrors,
          't-field__label--success': !hasErrors && hasSuccessMessages,
          required,
          ...fontSizeableClasses,
        }"
      >
        {{label}}
      </span>
      <TIconSvg
        v-if="hasErrors"
        name="danger-triangle"
        view-box="0 0 24 24"
        width="24"
        height="24"
        fill="none"
        :stroke="$options.RED_LIGHT"
        class="mr-2 t-multiselect__icon-error"
      />
      <TTooltip
        v-if="viewTootlip"
        info="Контейнер был добавлен водителем."
        :has-link="tooltipHasLink"
        :href="`/orders/edit/${id}`"
        link-text="Перейти к подтверждению"
        class="mr-2 icon-right"
      >
        <slot>
          <TIconSvg
            name="danger-circle"
            fill="#FE991F"
            stroke="#FE991F"
            width="20"
            height="20"
            view-box="0 0 20 20"
            class="tooltip-target"
          />
        </slot>
      </TTooltip>
    </div>
    <div
      v-if="hasErrors"
      class="t-field__hint t-field__hint--error j-error"
    >
      {{errorMessages[0]}}
    </div>
    <div
      v-if="!hasErrors && hasSuccessMessages"
      class="t-field__hint t-field__hint--success-message"
    >
      {{Array.isArray(successMessages) ? successMessages[0] : successMessages}}
    </div>
    <div
      v-if="hint && !hasErrors && !hasSuccessMessages"
      class="t-field__hint"
    >
      {{hint}}
    </div>
    <div
      v-if="multiple"
      ref="multiselect-hidden-tags"
      class="multiselect__hidden-tags"
      aria-hidden="true"
    >
      <span
        v-for="tagName of tagNames"
        :key="tagName.vueKey"
        class="multiselect__tag"
      >
        <span>{{tagName}}</span>
        <i class="multiselect__tag-icon" />
      </span>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {
  isObject,
  isEmpty,
  isString,
  isArray,
} from 'chober';

// Mixins
import sizes from '@/assets/js/mixins/sizes';

// Constants
import colors from '@/assets/scss/_variables.scss';
// libs
import { v4 as uuid } from 'uuid';
import accessMixin from '~/assets/js/mixins/accessMixin';
import dadata from '~/assets/js/dadata';

// Components
import TTooltip from '~/components/Common/TTooltip';

const REQUEST_TIMEOUT = 1000;

export default {
  name: 'TMultiselect',

  components: {
    Multiselect,
    TTooltip,
  },

  mixins: [sizes, accessMixin],

  BLUE_LIGHT: colors.BLUE_LIGHT,
  GRAY_DARK_1: colors.GRAY_DARK_1,
  RED_LIGHT: colors.RED_LIGHT,

  props: {
    value: {
      type: [String, Number, Array, Object],
      default: '',
    },

    items: {
      type: Array,
      default: () => [],
    },

    showArrow: {
      type: Boolean,
      default: true,
    },

    route: {
      type: String,
      default: null,
    },

    showLabels: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isListHidden: {
      type: Boolean,
      default: false,
    },

    taggable: {
      type: Boolean,
      default: false,
    },

    preselectFirst: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    hideSelected: {
      type: Boolean,
      default: false,
    },

    resetAfter: {
      type: Boolean,
      default: false,
    },

    clearOnSelect: {
      type: Boolean,
      default: true,
    },

    thresholdLimit: {
      type: Number,
      default: 3,
    },

    hint: {
      type: String,
      default: null,
    },

    noResult: {
      type: String,
      default: '_default',
    },

    noOptions: {
      type: String,
      default: '_default',
    },

    placeholder: {
      type: String,
      default: '_default',
    },

    itemText: {
      type: String,
      default: 'name',
    },

    itemValue: {
      type: String,
      default: 'id',
    },

    allowSelectAll: {
      type: Boolean,
      default: false,
    },

    selectLabel: {
      type: String,
      default: '',
    },

    selectedLabel: {
      type: String,
      default: '',
    },

    deselectLabel: {
      type: String,
      default: '',
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    internalSearch: {
      type: Boolean,
      default: false,
    },

    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    },

    routeResponseProp: {
      type: String,
      default: '',
    },

    queryParam: {
      type: String,
      default: 'search',
    },

    additionalParams: {
      type: Array,
      default: () => [],
    },

    limit: {
      type: Number,
      default: undefined,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    successMessages: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    isDadata: {
      type: Boolean,
      default: false,
    },

    daDataOptions: {
      type: Object,
      default: () => ({}),
    },

    daDataType: {
      type: String,
      default: 'address',
    },

    additionalSearch: {
      type: String,
      default: '',
    },

    isInputAfterDadataFail: {
      type: Boolean,
      default: true,
    },

    isEmitInputInDadataSearch: {
      type: Boolean,
      default: false,
    },

    isSafeEmitInputInDadataSearch: {
      type: Boolean,
      default: false,
    },

    preserveSearch: {
      type: Boolean,
      default: false,
    },

    allSelectedOptionsText: {
      type: String,
      default: 'Все',
    },

    maxHeigth: {
      type: Number,
      default: 240,
    },

    rowIndex: {
      type: Number,
      default: 0,
    },

    required: {
      type: Boolean,
      default: false,
    },

    isEllipsis: {
      type: Boolean,
      default: false,
    },

    tooltipHint: {
      type: [String, Boolean],
      default: null,
    },

    searchRoles: {
      type: Boolean,
      default: false,
    },

    containerUnconfirmed: {
      type: Boolean,
      default: false,
    },

    tooltipHasLink: {
      type: Boolean,
      default: false,
    },

    id: {
      type: Number,
      default: null,
    },

    noTopBorder: {
      type: Boolean,
      default: false,
    },

    noBottomBorder: {
      type: Boolean,
      default: false,
    },

    noLeftBorder: {
      type: Boolean,
      default: false,
    },

    noRightBorder: {
      type: Boolean,
      default: false,
    },

    prefill: {
      type: String,
      default: '',
    },

    allowSelectText: {
      type: Boolean,
      default: true,
    },

    clearTransporter: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isLoading: false,
    searchStatusText: '',
    abort: null,
    requestTimeout: null,
    isDataAccessible: true,
    isOpened: false,
    visibleTagNames: [],
    isSearchStop: false,
    isInternalSearchStop: true,
  }),

  computed: {
    isSelectLoading() {
      return this.isLoading || this.loading;
    },

    colorsIconSelect() {
      return this.disabled
        ? colors.GRAY_DARK_1
        : colors.BLUE_LIGHT;
    },

    isSelectOpened() {
      if (Array.isArray(this.value)) {
        return this.isOpened || this.value.length;
      }

      return this.isOpened || this.value;
    },

    hasLabel() {
      return Boolean(this.label);
    },

    hasErrors() {
      return !isEmpty(this.errorMessages);
    },

    hasSuccessMessages() {
      return !isEmpty(this.successMessages);
    },

    isObjectItems() {
      return this.items.length !== 0 && isObject(this.items[0]);
    },

    isObjectValues() {
      if (!isArray(this.value)) return false;
      return this.value.length !== 0 && isObject(this.value[0]);
    },

    isStringItems() {
      return this.items.length !== 0 && isString(this.items[0]);
    },

    groupOptions() {
      return [{
        label: this.$t('All'),
        values: this.items,
      }];
    },

    placeholderText() {
      if (this.placeholder === '_default') {
        return this.$t('Select from the list');
      }

      return this.placeholder;
    },

    noOptionsText() {
      if (this.noOptions === '_default') {
        return this.$t('The list is empty');
      }

      return this.noOptions;
    },

    noResultText() {
      if (this.noResult === '_default') {
        return this.$t('The search has not given any results');
      }

      return this.noResult;
    },

    limitText() {
      if (typeof this.limit === 'number' && this.value) {
        const count = this.value.length - this.limit;
        return `+${count}`;
      }

      return '';
    },

    tagNames() {
      if (!isArray(this.value)) return [];
      if (this.isObjectItems || this.isObjectValues) {
        return this.value.map(tag => tag[this.itemText] || {});
      }

      return this.value;
    },

    viewTootlip() {
      return this.containerUnconfirmed && (this.isAdmin || this.isDispatcher);
    },

    isAllowSelectText() {
      return this.allowSelectText
        && this.searchable
        && !this.multiple
        && !this.taggable
        && this.value;
    },

    isInternalSearch() {
      return this.isInternalSearchStop ? false : this.internalSearch;
    },
  },

  mounted() {
    if (this.prefill) {
      this.$refs.multiselect?.activate();
      this.$refs.multiselect.search = this.prefill;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.isDataAccessible = true;
        }

        this.$nextTick(this.calcTagsWidth);
      },
    },
  },

  beforeDestroy() {
    if (this.isDataAccessible && this.$refs.multiselect) {
      this.$refs.multiselect.deactivate();
    }
  },

  methods: {
    open() {
      this.isOpened = true;

      if (this.isAllowSelectText && !Array.isArray(this.value)) {
        this.isSearchStop = true;
        this.$refs.multiselect.search = this.value?.[this.itemText] ?? this.value;

        this.$nextTick(() => {
          this.$refs.multiselect.$refs.search.select();
        });
      }
    },

    unselectTag(index) {
      if (this.items[index] && this.items[index].$isDisabled) {
        return;
      }

      if (this.multiple) {
        const localValue = this.value.slice();

        localValue.splice(index, 1);
        this.$emit('input', localValue);
      }
    },

    clearValue() {
      if (this.multiple) {
        this.$emit('input', this.items?.filter(item => item?.$isDisabled));
      } else {
        this.$emit('input', null);
      }
      this.$emit('remove');
    },

    async handleRequest(input) {
      this.$emit('search-change', input);

      if (this.isSearchStop) {
        this.searchStatusText = this.noOptions;
        this.isSearchStop = false;
        this.isInternalSearchStop = true;
        return;
      }

      this.isInternalSearchStop = false;

      if (
        (!this.route && !this.isDadata)
        || !input
        || input.length <= this.thresholdLimit
      ) {
        this.searchStatusText = this.noOptions;
        if (this.requestTimeout) {
          clearTimeout(this.requestTimeout);
          this.isLoading = false;
        }
        return;
      }

      this.isLoading = true;
      let items = [];

      try {
        // console.log(this);
        items = await this[this.isDadata ? 'loadDadata' : 'loadData'](input);
        // console.log(items)
      } catch (error) {
        this.$emit('change-options-error', error);
      }

      this.isLoading = false;
      this.searchStatusText = this.noResultText;

      if (this.isDadata && !this.isSafeEmitInputInDadataSearch) {
        this.$emit(
          'change-options',
          this.isEmitInputInDadataSearch
            ? { suggestions: [...items.suggestions, { name: input, id: uuid(), custom: true }] }
            : items,
        );
      } else {
        this.$emit(
          'change-options',
          (this.isEmitInputInDadataSearch || this.isSafeEmitInputInDadataSearch)
            ? [items, input]
            : items,
          this.rowIndex,
        );
      }
    },

    formatResponseData(routeResponseProps, data) {
      const localVariables = routeResponseProps.slice();
      const option = localVariables.shift();

      if (localVariables.length > 0) {
        return this.formatResponseData(localVariables, data[option]);
      }

      // console.log(data[option])

      return data[option];
    },

    loadDadata(input) {
      return new Promise((resolve, reject) => {
        if (this.requestTimeout) {
          clearTimeout(this.requestTimeout);
        }

        if (!input) return Promise.resolve({
          suggestions: [],
        });

        this.requestTimeout = setTimeout(() => {
          const query = this.additionalSearch ? `${this.additionalSearch}, ${input}` : input;

          dadata(query, this.daDataType, this.daDataOptions, this.$i18n.locale)
            .then(result => {
              resolve(result);
            })
            .catch(error => {
              if (navigator.onLine) {
                this.isDataAccessible = !this.isInputAfterDadataFail;
              }

              this.$emit('dadata-error', error);
              this.$emit('input', input);
              resolve({
                suggestions: [],
              });
            });
        }, REQUEST_TIMEOUT);
      });
    },

    loadData(input) {
      return new Promise((resolve, reject) => {
        if (this.requestTimeout) {
          clearTimeout(this.requestTimeout);
        }

        const { CancelToken } = this.$axios;

        this.requestTimeout = setTimeout(() => {
          if (typeof this.abort === 'function') {
            this.abort();
          }

          const additionalParams = this.additionalParams.reduce((acc, cur) => ({
            ...acc,
            [cur.param]: cur.query,
          }), {});

          this.$axios.get(`${this.route}`, {
            params: {
              [this.queryParam]: input,
              ...additionalParams,
            },
            cancelToken: new CancelToken(abort => {
              this.abort = abort;
            }),
          }).then(({ data }) => {
            if (this.routeResponseProp) {
              const routeResponseProps = this.routeResponseProp.split('.');
              // console.log(routeResponseProps)

              resolve(this.formatResponseData(routeResponseProps, data));
            } else {
              resolve(data);
            }
          }).catch(reject)
            .finally(this.abort = null);
        }, REQUEST_TIMEOUT);
      });
    },

    calcTagsWidth() {
      if (!this.multiple || this.$isServer) return;

      this.visibleTagNames = [];

      const { container } = this.$refs;

      if (!container) return;

      const containerWidth = container.getBoundingClientRect().width;

      // container width - horizontal padding - "plus icon" - "clear icon" - inner paddings
      let availableSpace = containerWidth - 16 - 40 - 30 - 36 - 10;

      const invisibleTagsContainer = this.$refs['multiselect-hidden-tags'];

      invisibleTagsContainer.querySelectorAll('.multiselect__tag').forEach((elem, index) => {
        const { width } = elem.getBoundingClientRect();
        availableSpace -= width;
        availableSpace -= 10;

        if (
          availableSpace > 0
          && (this.limit === undefined || this.visibleTagNames.length < this.limit)
        ) {
          this.visibleTagNames.push(this.tagNames[index]);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.multiselect {
  $self: &;

  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: $default-control-height;
  text-align: left;
  color: #35495e;

  &__is-list-hidden {
    #{$self}__content-wrapper {
      display: none;
    }
  }

  &--active {
    z-index: 5;

    #{$self}__limit,
    #{$self}__tags-wrap {
      display: none;
    }
  }

  &__limit {
    border-radius: 20px;
    font-size: 12px;
    line-height: 1;
    padding: 1px 4px;
    background-color: $light-yellow;
    margin-top: 2px;
  }

  &__icon-left {
    position: absolute;
    top: 50%;
    transform: translateY(-54%);
    margin-left: 16px;
    z-index: 2;
  }

  &__tags-wrap {
    display: inline;

    &--before {
      padding-bottom: 4px;
      padding-left: 4px;
      padding-top: 4px;
      min-height: 28px;
      max-height: 140px;
      overflow-y: scroll;
      position: sticky;
      top: 0;
      background-color: #FFF;
      z-index: 201;
    }

    & #{$self}__tag {
      margin-bottom: 0;
    }
  }

  &__limit {
    padding: 5px 8px;
    margin-top: 0;
    border-radius: 0;
    background: $blue-ultra-light;
    color: $black;
  }

  &__tag {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 5px 26px 5px 8px;
    margin: 0 8px 2px 0;
    line-height: 1;
    font-size: 12px;
    background: $blue-ultra-light;
    color: $black;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;

    &-disabled {
      background: $light-grey;
    }
  }

  &__tag-icon {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    transform :translateY(-50%);
    font-weight: 700;
    font-style: initial;
    text-align: center;
    transition: all 0.2s ease;
    border-radius: 3px;

    &:after {
      content: '✕';
      color: $gray-dark-1;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:focus:after,
    &:hover:after {
      color: $steel-grey;
    }

    &-disabled {
      &:after,
      &:focus:after,
      &:hover:after {
        cursor: default;
        color: $gray-dark-1;
      }
    }
  }

  &__tag-plus {
    width: 36px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: $t4-regular;
    line-height: 1;
    background: $blue-ultra-light;
    margin-right: 8px;
    margin-bottom: 2px;
  }

  &__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: flex;
    vertical-align: top;
  }

  &__clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    z-index: 1;
    cursor: pointer;
  }

  &__tags {
    height: 100%;
    min-width: 90px;
    display: flex;
    padding: 0 40px 0 16px;
    border: 1px solid $gray-light-2;
    background: #fff;
    font-size: 16px;
    flex-direction: row;
    align-items: center;
  }

  &__tags-bl0 {
    border-left: none;
  }

  &__select {
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    width: 40px;
    height: 100%;
    right: 14px;
    top: 1px;
    margin: 0;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease;
    z-index: 3;
  }

  &__input,
  &__single {
    position: relative;
    display: inline-block;
    min-height: 16px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0;
    width: 50%;
    transition: border 0.1s ease;
    box-sizing: border-box;
    vertical-align: top;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //mask-image: linear-gradient(to right,black 10%,transparent 98%);
  }

  &__option {
    display: flex;
    align-items: center;
    padding: 11px 16px;
    min-height: 20px;
    line-height: 16px;
    font: $t3-regular;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    color: $black;
    position: relative;
    cursor: pointer;
    white-space: normal;

    &:after {
      top: 0;
      right: 0;
      position: absolute;
      line-height: 32px;
      padding-right: 12px;
      padding-left: 20px;
      font-size: 13px;
    }

    .option__icon {
      display: none;
    }

    .option__icon-left {
      flex-shrink: 0;
      margin-right: 6px;
    }

    &--highlight {
      background: $gray-ultra-light-2;
      color: $blue-light;
      outline: none;

      &:after {
        content: attr(data-select);
        background: #41b883;
        color: white;
      }
    }

    &--selected {
      &:after {
        content: attr(data-selected);
        color: silver;
      }

      .option__icon {
        display: block;
      }
    }

    &--selected &--highlight {
      background: #ff6a6a;
      color: #fff;

      &:after {
        background: #ff6a6a;
        content: attr(data-deselect);
        color: #fff;
      }
    }

    &--disabled {
      background: #ededed !important;
      color: #a6a6a6 !important;
      cursor: text;
      pointer-events: none;
    }

    &--group {
      background: #ededed;
      color: #35495e;
    }

    &--group &--highlight {
      background: #35495e;
      color: #fff;

      &:after {
        background: #35495e;
      }
    }

    &--disabled &--highlight {
      background: #dedede;
    }

    &--group-selected &--highlight {
      background: #ff6a6a;
      color: #fff;

      &:after {
        background: #ff6a6a;
        content: attr(data-deselect);
        color: #fff;
      }
    }
  }

  &__spinner {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 34px;
    height: 28px;
    background: #fff;
    z-index: 2;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      border-color: $blue-light transparent transparent;
      border-style: solid;
      border-width: 2px;
      box-shadow: 0 0 0 1px transparent;
    }

    &:before {
      animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
      animation-iteration-count: infinite;
    }

    &:after {
      animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
      animation-iteration-count: infinite;
    }
  }

  &--desabled &__current,
  &--desabled &__select {
    background: #ededed;
    color: #a6a6a6;
  }

  &__loading-enter-active,
  &__loading-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }

  &__loading-enter,
  &__loading-leave-active {
    opacity: 0;
  }

  &,
  &__input,
  &__single {
    font-family: inherit;
    touch-action: manipulation;
  }

  & * {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }

  &--disabled {
    background: #ededed;
    pointer-events: none;
    //opacity: 0.6;

    .multiselect__single {
      color: #556B87;
    }
  }

  &--active:not(&--above) &__current,
  &--active:not(&--above) &__input,
  &--active:not(&--above) &__tags {
    border-radius: 0;
  }

  &__input,
  &__single {
    position: relative;
    display: inline-block;
    border: none;
    border-radius: 5px;
    background: #fff;
    width: calc(100%);
    transition: border 0.1s ease;
    box-sizing: border-box;
    vertical-align: top;
  }

  &__current {
    line-height: 16px;
    min-height: 32px;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    padding: 8px 30px 0 12px;
    white-space: nowrap;
    margin: 0;
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    cursor: pointer;
  }

  &__placeholder {
    font: $t2-regular;
    color: $gray-dark-2;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid $gray-light-1;
    border-top: none;
    z-index: 900;
    -webkit-overflow-scrolling: touch;
  }

  &__content-wrapper::-webkit-scrollbar {
    position: relative;
    z-index: 999;
    height: 6px;
    width: 6px;
  }

  &__content-wrapper::-webkit-scrollbar-track {
    background: $gray-light-2;
    width: auto;
    height: auto;
    opacity: 0.4;
    border-radius: 6px;
  }
  &__content-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 50, 160, 0.16);
    border-radius: 6px;
  }

  &__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
  }

  &--above &__content-wrapper {
    bottom: 100%;
    border-radius: 2px 2px 0 0;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
  }

  &--active &__select {
    transform: rotateZ(180deg);
  }

  &--above &--active &__current,
  &--above &--active &__input,
  &--above &--active &__tags {
    border-radius: 0;
  }

  &__input {
    &::placeholder {
      color: #35495e;
    }
  }

  &__tag ~ &__input,
  &__tag ~ &__single {
    width: auto;
  }

  &__input,
  &__single {
    &:hover {
      border-color: #cfcfcf;
    }

    &:focus {
      border: 0px;
    }
  }

  &--active .multiselect__tags {
    position: relative;
    border: 1px solid $blue-light;
  }

  &__content::-webkit-scrollbar {
    display: none;
  }

  &__element {
    display: block;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.15s ease;
  }

  &-enter,
  &-leave-active {
    opacity: 0;
  }

  fieldset[disabled] & {
    pointer-events: none;
  }

  &__single {
    white-space: nowrap;
    overflow: hidden;
  }

  &.t-size--x-small {
    #{$self}__select {
      right: 6px;
    }

    #{$self}__tags {
      padding: 0px 35px 0 12px;
    }

    #{$self}__placeholder {
      font: $t3-regular;
    }
  }

  &__hidden-tags {
    height: 1;
    position: absolute;
    visibility: hidden;
  }
}

.t-multiselect.t-field--error .multiselect__tags {
  position: relative;
  z-index: 2;
  border: 1px solid $red-light;
}

.t-multiselect {
  &--active {
    z-index: 7;
  }

  &--loading {
    .multiselect__select {
      z-index: 2;
    }
  }

  &--offset-left {
    .multiselect__tags {
      padding: 0 40px 0 46px;
    }
  }

  &__icon-error {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    z-index: 5;
  }

  .t-field__field-wrapper > .t-field {
    padding: 0 !important;
  }

  &.t-field {
    padding-top: 0;
  }

  .t-field__label {
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    right: auto;
    color: $gray-dark-2;
    overflow: visible;
    transition-property: left, right, top, transform, font-size;
    transition-duration: 0.3s;
    z-index: 2;

    &.required {
      &::after {
        position: absolute;
        content: '*';
        right: -10px;
        top: 0;
        color: $orange-light;
        font-size: 12px;
      }
    }
  }

  &--opened {
    .t-field__label {
      font: $t4-regular;
      padding: 0 6px;
      top: 0px;
      left: 10px;
      transform: translateY(-50%);
      background-color: $white;
      z-index: 6;

      &.required {
        padding-right: 15px;

        &::after {
          right: 6px;
        }
      }
    }

    .multiselect__clear {
      z-index: 4;
    }
  }
}

.t-field--ellipsis{
  .multiselect__input,
  .multiselect__single {
    overflow: hidden;
    white-space: nowrap;
    margin-right: 12px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      width: 43px;
    }
  }
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
</style>
