<template>
  <div class="t-popup">
    <div
      v-if="hasOverlay"
      class="t-popup__overlay"
      @click="overlayClose ? $emit('close') : ''"
    />
    <div
      class="t-popup__wrapper"
      @click.stop
    >
      <div
        :style="{ width }"
        class="t-popup__content"
        :class="{
          't-popup__content--center': !isSidePopup,
          't-popup__content--side' : isSidePopup,
          't-popup__content--offset-bottom': isHiddenBottom,
        }"
      >
        <div
          class="t-popup__header"
          :class="{
            't-popup__header--side' : isSidePopup
          }"
        >
          <slot name="header" />
          <button
            v-if="hasCloseButton"
            type="button"
            class="t-popup__close-button"
            :class="{
              't-popup__close-button--center': !isSidePopup,
              't-popup__close-button--side' : isSidePopup
            }"
            @click="$emit('close')"
          >
            <TIconSvg
              name="close"
              :stroke="iconColor"
              height="24"
              width="24"
              view-box="0 0 24 24"
            />
          </button>
        </div>
        <div class="t-popup__text">
          <slot />
        </div>
        <div
          v-if="!isHiddenBottom"
          class="t-popup__bottom"
          :class="{
            't-popup__bottom--side' : isSidePopup
          }"
          :style="variables"
        >
          <slot name="bottom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/assets/scss/_variables.scss';

export default {
  name: 'TPopup',

  props: {
    hasOverlay: {
      type: Boolean,
      default: true,
    },

    overlayClose: {
      type: Boolean,
      default: false,
    },

    hasCloseButton: {
      type: Boolean,
      default: true,
    },

    width: {
      type: String,
      default: '640px',
    },

    preventScrolling: {
      type: Boolean,
      default: true,
    },

    isSidePopup: {
      type: Boolean,
      default: false,
    },

    isHiddenBottom: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconColor() {
      return colors.BLUE_LIGHT;
    },

    variables() {
      return {
        '--width-bottom': this.width,
      };
    },
  },

  mounted() {
    if (this.preventScrolling) {
      document.body.style.overflow = 'hidden';
    }
    this.closeOnEscapePress = event => {
      if (event.code === 'Escape') {
        this.$emit('close');
      }
    };
    document.addEventListener('keydown', this.closeOnEscapePress);
  },

  beforeDestroy() {
    if (this.preventScrolling) {
      document.body.style.overflow = 'visible';
    }
    document.removeEventListener('keydown', this.closeOnEscapePress);
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

.t-popup {
  position: fixed;
  @include fullScreen;
  overflow: hidden;
  z-index: 5000;

  &__overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 30, 66, 0.2);
  }

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__bottom {
    position: sticky;
    bottom: -1px;
    background-color: $white;
    z-index: 5;
    padding-top: 4px;
    padding-bottom: 48px;

    &--side {
      --offset-popup: 112px;

      position: fixed;
      width: calc(var(--width-bottom) - var(--offset-popup));

      padding-bottom: 96px;
    }
  }

  &__header {
    position: sticky;
    top: -1px;
    background-color: $white;
    z-index: 5;
    padding-bottom: 16px;
    padding-top: 64px;

    &--side {
      padding-top: 90px;
    }

    .t-heading {
      margin-bottom: 4px;
    }
  }

  &__content {
    background-color: $white;
    overflow-x: hidden;
    overflow-y: auto;

    &--center {
      @include centerAbsoluteXY;
      box-shadow: $shadow-black;
      max-height: 90vh;
    }

    &--side {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  & &__close-button {
    position: absolute;
    top: 24px;
    right: -24px;
    cursor: pointer;

    &--side {
      right: 32px;
      top: 48px;
    }
  }

  @media (min-width: $media-xs) {
    padding: 0;
    overflow: hidden;

    &__content {
      padding: 0 64px !important;
      width: 495px;

      &--side {
        padding: 0 48px 0 64px !important;
      }

      &--offset-bottom {
        padding-bottom: 24px !important;
      }
    }
  }
}
</style>
