// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.c-toast{position:fixed;left:50%;top:40px;transform:translateX(-50%);z-index:9999;min-height:44px;border-radius:8px;padding:14px 18px;background-color:#eff3fa;transition:opacity .3s ease;display:flex;align-items:center}.c-toast--warning{background-color:#fcf0e3}.c-toast--error{background-color:#ffebec}.c-toast--static{position:static;transform:none;z-index:auto}.c-toast__close-button{font-size:0;cursor:pointer;transform:none;z-index:auto}.c-toast__header{font-size:16px;font-weight:500;padding-right:30px}.c-toast__body{font:400 12px/14px \"Graphik LCG\";padding:0 10px}.toast-enter,.toast-leave-active{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
