<template>
  <TFlex
    class="t-radio-wrapper"
    row
  >
    <TCol :xs="radioWidth">
      <label
        :class="{
          't-radio--checked': value === propertyValue,
          't-radio--disabled': disabled,
          't-radio--error': hasError,
        }"
        class="t-radio"
      >
        <input
          :id="propertyValue"
          v-model="innerValue"
          :disabled="disabled"
          :value="propertyValue"
          type="radio"
          class="t-radio__input"
          @click="handleInput"
        >
      </label>
    </TCol>
    <TCol
      :xs="labelWidth"
      class="t-radio__text-container"
    >
      <label
        :for="propertyValue"
        class="t-radio__text"
        :class="{'t-radio__text--disabled': disabled}"
      >
        {{text}}
      </label>
    </TCol>
    <div
      v-if="hasErrors"
      class="pl-2 t-field__hint t-field__hint--error j-error"
    >
      {{errorMessages[0]}}
    </div>
  </TFlex>
</template>

<script>
import { isEmpty } from 'chober';

export default {
  name: 'TRadio',

  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    propertyValue: {
      type: [Number, String],
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: String,
      default: '8',
    },
    radioWidth: {
      type: String,
      default: '4',
    },
  },

  data: () => ({
    innerValue: 0,
  }),

  computed: {
    hasErrors() {
      return !this.isEmpty(this.errorMessages);
    },
  },

  watch: {
    value(value) {
      this.innerValue = value;
    },

    innerValue(value) {
      this.$emit('input', value);
    },
  },

  created() {
    this.innerValue = this.value;
  },

  methods: {
    isEmpty,

    handleInput() {
      this.$emit('input', this.propertyValue);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-radio {
  $self: &;
  $size: 16px;
  $border-size: 1px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: $size;
  height: $size;
  border: 1px solid $gray-light-1;
  border-radius: 50%;
  transition: border-color 0.2s ease;
  cursor: pointer;
  position: relative;
  background-color: $white;

  &:hover,
  &--checked {
    border-color: $gray-light-1;
  }

  &:hover {
    border-color: $dusty-grey;
  }

  &--disabled {
    cursor: default;
    background-color: $gray-ultra-light-1;

    &:hover {
      border-color: $gray-light-1;
    }

    &#{$self}--checked::before {
      background-color: $gray-dark-1;
      width: 6px;
      height: 6px;
      top: 4px;
      left: 4px;
    }
  }

  &-wrapper {
    .t-col {
      padding: 0 5px;
    }
  }

  &__text--disabled {
    color: $gray-dark-2;
  }

  &--checked {
    $radio-dot-padding: 3px;
    $radio-dot-size: $size - $border-size * 2 - $radio-dot-padding * 2;

    &::before {
      content: '';
      position: absolute;
      top: $radio-dot-padding;
      left: $radio-dot-padding;
      width: $radio-dot-size;
      height: $radio-dot-size;
      border-radius: 50%;
      background-color: $blue-light;
    }
  }

  &__input {
    display: none;
  }
}
</style>
