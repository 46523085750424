<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :rules="validationRules"
    class="t-field"
    :class="{
      't-field--no-label': !hasLabel,
      't-field--loading': loading,
      't-field--focused-loading': canEditLoading && loading,
    }"
  >
    <span
      v-if="hasLabel"
      class="t-field__label"
      :class="{
        't-field__label--error': hasErrors || errors.length,
        't-field__label--success': !hasErrors && !errors.length && hasSuccessMessages,
      }"
    >
      {{label}}
    </span>
    <div class="t-field__field-wrapper t-field__field--multiline">
      <textarea
        :id="id"
        ref="inputComponentRef"
        v-model="localValue"
        class="t-field__field"
        :class="{
          't-field__field--error': hasErrors || errors.length,
          't-field__field--success': !hasErrors && !errors.length && hasSuccessMessages,
          't-field__field--auto-expanded': isAutoExpanded,
        }"
        :rows="rows"
        :placeholder="placeholder || undefined"
        :name="name"
        :maxlength="maxLength"
        :disabled="isDisabled"
        :readonly="readOnly"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
        @input="handleInput"
        @change="$emit('change', $event.target.value)"
        @keydown.enter.prevent="$emit('enter', $event.target.value)"
      />
      <div class="t-field__actions">
        <div class="t-field__actions-icon">
          <TIconSvg
            v-if="hasErrors"
            :name="'danger-triangle'"
            :width="24"
            :height="24"
            :view-box="'0 0 24 24'"
            fill="none"
            :stroke="$options.RED_LIGHT"
          />
        </div>
        <slot name="actions" />
      </div>
    </div>
    <div
      v-if="hasErrors || errors.length"
      class="t-field__hint t-field__hint--error j-error"
    >
      {{errors.length ? errors[0] : ''}}
      {{errorMessages[0]}}
    </div>
    <div
      v-if="!hasErrors && !errors.length && hasSuccessMessages"
      class="t-field__hint t-field__hint--success-message"
    >
      {{Array.isArray(successMessages) ? successMessages[0] : successMessages}}
    </div>
    <div
      v-if="hint && !hasErrors && !errors.length && !hasSuccessMessages"
      class="t-field__hint"
    >
      {{hint}}
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import { isEmpty } from 'chober';

// Constants
import colors from '@/assets/scss/_variables.scss';

export default {
  name: 'TTextarea',

  components: {
    ValidationProvider,
  },

  RED_LIGHT: colors.RED_LIGHT,

  props: {
    id: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    value: {
      type: [String, Number],
      default: null,
    },

    name: {
      type: String,
      default: '',
    },

    hint: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: 'text',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    canEditLoading: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: [String, Number],
      default: -1, // unlimited
    },

    rows: {
      type: [Number, String],
      default: 1,
    },

    validationRules: {
      type: [String, Object],
      default: null,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    successMessages: {
      type: [Array, String],
      default: '',
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    isAutoExpanded: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    localValue: '',
  }),

  computed: {
    hasLabel() {
      return Boolean(this.label);
    },

    hasErrors() {
      return !isEmpty(this.errorMessages);
    },

    hasSuccessMessages() {
      return !isEmpty(this.successMessages);
    },

    isDisabled() {
      return this.disabled || (this.loading && !this.canEditLoading);
    },
  },

  watch: {
    value(value) {
      this.localValue = value;
    },
  },

  mounted() {
    this.localValue = this.value;

    this.$nextTick(() => {
      if (this.isAutoExpanded) {
        this.setAutoExpandedTextareaHeight(this.$refs.inputComponentRef);
      }
    });
  },

  methods: {
    handleInput(event) {
      if (this.isAutoExpanded) {
        this.setAutoExpandedTextareaHeight(event.target);
      }

      this.$emit('input', event.target.value);
    },

    setAutoExpandedTextareaHeight(textarea) {
      const field = textarea;

      if (field) {
        const fieldComputedStyles = window.getComputedStyle(field);

        field.style.height = 'inherit';

        const heightNeeded = parseInt(fieldComputedStyles.getPropertyValue('border-top-width'), 10)
          + field.scrollHeight
          + parseInt(fieldComputedStyles.getPropertyValue('border-bottom-width'), 10);

        field.style.height = `${heightNeeded}px`;
      }
    },
  },
};
</script>
<style lang="scss">
@import '~/assets/scss/_variables';
@import '~/assets/scss/_mixins';

  .t-field__label.t-field__label-input{
    @include centerAbsoluteY;
    left: 16px;
    transition: all .3s;
  }

  .t-field--active .t-field__label-input{
    background-color: $white;
    padding: 0 6px;
    left: 10px;
    top: 0;
    left: 10px;
    right: auto;
  }

  .t-field__actions{
    @include centerAbsoluteY;
    right: 15px;
    display: flex;
    align-items: center;
    z-index: 3;
  }

  .t-field__actions-icon{
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    height: 44px;
  }
</style>
