var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t-banner",class:{'t-banner__absolute': _vm.isAbsolute}},[_c('div',{staticClass:"t-banner__wrapper",class:{
      'wrapper-red': _vm.isWarning,
      'wrapper-blue': _vm.isNeutral,
      'wrapper-orange': _vm.isAttention,
      'wrapper-gray': _vm.isGray,
    }},[_c('div',{staticClass:"t-banner__info"},[_c('TIconSvg',{staticClass:"icon",attrs:{"name":_vm.iconName,"height":_vm.heightIcon,"width":_vm.widthIcon,"view-box":_vm.viewBox,"fill":_vm.fill,"stroke":_vm.stroke}}),_vm._v(" "),_c('div',{class:{
          't-banner__text-absolute': _vm.isAbsolute,
          't-banner__text': !_vm.isAbsolute,
        }},[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")])],1),_vm._v(" "),(_vm.hasCloseIcon)?_c('button',{staticClass:"t-banner__close-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('TIconSvg',{attrs:{"name":'close',"height":"16","width":"16","view-box":"0 0 20 20","fill":"#8392A3","stroke":"#8392A3"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }