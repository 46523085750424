<template>
  <div class="t-statusselect__wrapper">
    <div class="t-statusselect">
      <TDropdown
        v-model="isDropdownOpen"
        is-external-control
        close-on-select
      >
        <template #title>
          <div
            class="t-statusselect__label"
            :class="{
              'active': isDropdownOpen,
              'disabled': disabled,
              'readonly': readonly,
              'with-icon': iconName
            }"
            @click.stop="toggleDropdown()"
          >
            <div
              v-if="iconName && !disabled"
              class="t-statusselect__label-icon-wrapper"
              :class="colorClass"
            >
              <slot name="icon">
                <TIconSvg
                  :name="iconName"
                  class="icon"
                  :height="heightIcon"
                  :width="widthIcon"
                  :view-box="viewBox"
                  fill="#fff"
                  stroke="#fff"
                />
              </slot>
            </div>
            <div class="t-statusselect__label-content">
              {{value}}
              <TIconSvg
                v-if="!readonly"
                class="t-statusselect__label-icon"
                :class="{'open': isDropdownOpen}"
                name="chevron-down"
                height="16"
                width="16"
                view-box="0 0 22 22"
                fill="#fff"
                stroke="#fff"
              />
            </div>
          </div>
        </template>
        <template #content>
          <div class="t-statusselect__dropdown">
            <div
              v-for="(status, index) in listToDisplay"
              :key="index"
              @click="handleSelect(status.key || status.id || status.value || status)"
            >
              {{status.name || status}}
            </div>
          </div>
        </template>
      </TDropdown>
    </div>
  </div>
</template>

<script>
import TDropdown from './TDropdown';

export default {
  name: 'TStatusSelect',

  components: {
    TDropdown,
  },

  props: {
    value: {
      type: [String, Number],
      required: true,
    },

    list: {
      type: Array,
      default: () => [],
      validator: array => array.every(item => typeof item !== 'object') || array.every(item => (item.key || item.id) && item.name),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    iconName: {
      type: String,
      default: '',
    },

    colorClass: {
      type: String,
      default: '',
    },

    viewBox: {
      type: String,
      default: '0 0 22 22',
    },

    heightIcon: {
      type: [String, Number],
      default: 16,
    },

    widthIcon: {
      type: [String, Number],
      default: 16,
    },
  },

  data: () => ({
    isDropdownOpen: false,
  }),

  computed: {
    listToDisplay() {
      return this.list.filter(status => status !== this.value || status.name !== this.value);
    },
  },

  methods: {
    toggleDropdown() {
      if (!this.disabled && !this.readonly) {
        this.isDropdownOpen = !this.isDropdownOpen;
      }
    },

    handleSelect(value) {
      this.$emit('select', value);
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/_variables";

.t-statusselect__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.t-statusselect {
  cursor: pointer;
  min-width: 120px;
  font: $t4-uppercase-regular;
  z-index: 7;

  &__label {
    $self: &;

    color: $white;
    top: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &-content {
      background-color: $gray-dark-2;
      margin-left: 32px;
      padding: 0 16px 0 8px;
      border-radius: 0px 8px 8px 0px;
    }

    &.with-icon {
      #{$self}-content{
        padding-right: 10px;
      }
    }

    &.readonly {
      cursor: default;

      #{$self}-content{
        padding-right: 16px;
      }
    }

    &.disabled {
      color: $gray-dark-2;
      background-color: $gray-ultra-light-1;
      cursor: default;
    }

    &-icon-wrapper {
      align-items: center;
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      padding: 4px 8px;
      background-color: $gray-ultra-dark;
      overflow: hidden;
      border-radius: 8px 0px 0px 8px;

      &.success {
        background-color: $cyan-light;
      }

      &.danger {
        background-color: $red-light;
      }

      &.warning {
        background-color: $orange-light;
      }

      &.regular {
        background-color: $blue-light;
      }

      &.regular-dark {
        background-color: $blue-dark;
      }

      &.error {
        background-color: $red-light;
      }

      &.info {
        background-color: $gray-ultra-dark;
      }
    }

    &-icon {
      position: relative;
      top: 4px;
      margin-left: 8px;
      transition: .2s;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .t-dropdown__content-wrapper {
    width: 100%;
    left: 0;
    top: calc(100% + 5px);
    z-index: 7;
  }

  &__dropdown {
    color: $black;
    z-index: 1;
    width: 100%;
    border-radius: 2px;
    padding: 10px 0px 0px;

    & > div {
      padding: 4px 12px;

      &:hover {
        background-color: $white-grey;
      }
    }
  }
}
</style>
