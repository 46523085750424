import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _972555b2 = () => interopDefault(import('../pages/administration/index.vue' /* webpackChunkName: "pages/administration/index" */))
const _3e66fb2c = () => interopDefault(import('../pages/applications/index.vue' /* webpackChunkName: "pages/applications/index" */))
const _b5b33e86 = () => interopDefault(import('../pages/applications/index/edit.vue' /* webpackChunkName: "pages/applications/index/edit" */))
const _efc3f030 = () => interopDefault(import('../pages/applications/index/edit/_id.vue' /* webpackChunkName: "pages/applications/index/edit/_id" */))
const _50446561 = () => interopDefault(import('../pages/applications/index/info.vue' /* webpackChunkName: "pages/applications/index/info" */))
const _94bdcae8 = () => interopDefault(import('../pages/applications/index/info/_id.vue' /* webpackChunkName: "pages/applications/index/info/_id" */))
const _3128c11e = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _408b3b92 = () => interopDefault(import('../pages/available-transport/index.vue' /* webpackChunkName: "pages/available-transport/index" */))
const _77a2b260 = () => interopDefault(import('../pages/bi/index.vue' /* webpackChunkName: "pages/bi/index" */))
const _166e9968 = () => interopDefault(import('../pages/bidding/index.vue' /* webpackChunkName: "pages/bidding/index" */))
const _72d3d39a = () => interopDefault(import('../pages/bidding/index/create.vue' /* webpackChunkName: "pages/bidding/index/create" */))
const _84d76ffe = () => interopDefault(import('../pages/bidding/index/edit.vue' /* webpackChunkName: "pages/bidding/index/edit" */))
const _5cfead2c = () => interopDefault(import('../pages/bidding/index/edit/_id.vue' /* webpackChunkName: "pages/bidding/index/edit/_id" */))
const _68b24ca5 = () => interopDefault(import('../pages/bidding/index/info.vue' /* webpackChunkName: "pages/bidding/index/info" */))
const _eafc8060 = () => interopDefault(import('../pages/bidding/index/info/_id.vue' /* webpackChunkName: "pages/bidding/index/info/_id" */))
const _08bb9888 = () => interopDefault(import('../pages/bidding/index/price-requests/create.vue' /* webpackChunkName: "pages/bidding/index/price-requests/create" */))
const _68ec33ca = () => interopDefault(import('../pages/bidding/index/price-requests/edit.vue' /* webpackChunkName: "pages/bidding/index/price-requests/edit" */))
const _7f798c75 = () => interopDefault(import('../pages/bidding/index/price-requests/edit/_id.vue' /* webpackChunkName: "pages/bidding/index/price-requests/edit/_id" */))
const _140a386e = () => interopDefault(import('../pages/bidding/index/price-requests/info.vue' /* webpackChunkName: "pages/bidding/index/price-requests/info" */))
const _a606c1ce = () => interopDefault(import('../pages/bidding/index/price-requests/info/_id.vue' /* webpackChunkName: "pages/bidding/index/price-requests/info/_id" */))
const _2fad6348 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _1cda7053 = () => interopDefault(import('../pages/companies/index/create.vue' /* webpackChunkName: "pages/companies/index/create" */))
const _3768aa21 = () => interopDefault(import('../pages/companies/index/edit.vue' /* webpackChunkName: "pages/companies/index/edit" */))
const _3394ff4c = () => interopDefault(import('../pages/companies/index/edit/_id.vue' /* webpackChunkName: "pages/companies/index/edit/_id" */))
const _628f1ea0 = () => interopDefault(import('../pages/companies/index/edit/_customerId/_clientId.vue' /* webpackChunkName: "pages/companies/index/edit/_customerId/_clientId" */))
const _3af2a276 = () => interopDefault(import('../pages/companies/index/info.vue' /* webpackChunkName: "pages/companies/index/info" */))
const _611811f0 = () => interopDefault(import('../pages/companies/index/info/_id.vue' /* webpackChunkName: "pages/companies/index/info/_id" */))
const _11585044 = () => interopDefault(import('../pages/companies/index/info/_customerId/_clientId.vue' /* webpackChunkName: "pages/companies/index/info/_customerId/_clientId" */))
const _75eda0ab = () => interopDefault(import('../pages/company-contract/index.vue' /* webpackChunkName: "pages/company-contract/index" */))
const _070f401f = () => interopDefault(import('../pages/company-details/index.vue' /* webpackChunkName: "pages/company-details/index" */))
const _dc9286d6 = () => interopDefault(import('../pages/company-documents/index.vue' /* webpackChunkName: "pages/company-documents/index" */))
const _7af5287e = () => interopDefault(import('../pages/company-driver/index.vue' /* webpackChunkName: "pages/company-driver/index" */))
const _317247c6 = () => interopDefault(import('../pages/company-transport/index.vue' /* webpackChunkName: "pages/company-transport/index" */))
const _4cfca963 = () => interopDefault(import('../pages/correspondence/index.vue' /* webpackChunkName: "pages/correspondence/index" */))
const _809fb450 = () => interopDefault(import('../pages/correspondence/index/create.vue' /* webpackChunkName: "pages/correspondence/index/create" */))
const _96700634 = () => interopDefault(import('../pages/correspondence/index/edit.vue' /* webpackChunkName: "pages/correspondence/index/edit" */))
const _77d36791 = () => interopDefault(import('../pages/correspondence/index/edit/_id.vue' /* webpackChunkName: "pages/correspondence/index/edit/_id" */))
const _af918190 = () => interopDefault(import('../pages/drivers/index.vue' /* webpackChunkName: "pages/drivers/index" */))
const _a6a3733a = () => interopDefault(import('../pages/drivers/index/create.vue' /* webpackChunkName: "pages/drivers/index/create" */))
const _094a779e = () => interopDefault(import('../pages/drivers/index/edit.vue' /* webpackChunkName: "pages/drivers/index/edit" */))
const _77ac23bc = () => interopDefault(import('../pages/drivers/index/edit/_id/_companyId.vue' /* webpackChunkName: "pages/drivers/index/edit/_id/_companyId" */))
const _b30e6e56 = () => interopDefault(import('../pages/drivers/index/info.vue' /* webpackChunkName: "pages/drivers/index/info" */))
const _35b7c298 = () => interopDefault(import('../pages/drivers/index/info/_id/_companyId.vue' /* webpackChunkName: "pages/drivers/index/info/_id/_companyId" */))
const _e213e234 = () => interopDefault(import('../pages/fines/index.vue' /* webpackChunkName: "pages/fines/index" */))
const _011edd7a = () => interopDefault(import('../pages/fines/index/edit.vue' /* webpackChunkName: "pages/fines/index/edit" */))
const _5930856e = () => interopDefault(import('../pages/fines/index/edit/_id.vue' /* webpackChunkName: "pages/fines/index/edit/_id" */))
const _aae2d432 = () => interopDefault(import('../pages/fines/index/info.vue' /* webpackChunkName: "pages/fines/index/info" */))
const _f298cfdc = () => interopDefault(import('../pages/fines/index/info/_id.vue' /* webpackChunkName: "pages/fines/index/info/_id" */))
const _545c7da4 = () => interopDefault(import('../pages/instructions/index.vue' /* webpackChunkName: "pages/instructions/index" */))
const _54d6321d = () => interopDefault(import('../pages/no-access/index.vue' /* webpackChunkName: "pages/no-access/index" */))
const _cf0dee24 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _70c37826 = () => interopDefault(import('../pages/orders/index/create.vue' /* webpackChunkName: "pages/orders/index/create" */))
const _40609c3b = () => interopDefault(import('../pages/orders/index/edit.vue' /* webpackChunkName: "pages/orders/index/edit" */))
const _3cb25466 = () => interopDefault(import('../pages/orders/index/edit/_id.vue' /* webpackChunkName: "pages/orders/index/edit/_id" */))
const _2902be42 = () => interopDefault(import('../pages/orders/index/info.vue' /* webpackChunkName: "pages/orders/index/info" */))
const _6a35670a = () => interopDefault(import('../pages/orders/index/info/_id.vue' /* webpackChunkName: "pages/orders/index/info/_id" */))
const _5fcf6ed4 = () => interopDefault(import('../pages/orders/index/info/_id/getTrip.vue' /* webpackChunkName: "pages/orders/index/info/_id/getTrip" */))
const _93f19f8c = () => interopDefault(import('../pages/orders/index/info/_id/trip.vue' /* webpackChunkName: "pages/orders/index/info/_id/trip" */))
const _d383d902 = () => interopDefault(import('../pages/orders/index/info/_id/viewTrip.vue' /* webpackChunkName: "pages/orders/index/info/_id/viewTrip" */))
const _565403d4 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _235899b6 = () => interopDefault(import('../pages/public-orders/index.vue' /* webpackChunkName: "pages/public-orders/index" */))
const _774b19d1 = () => interopDefault(import('../pages/references/index.vue' /* webpackChunkName: "pages/references/index" */))
const _4572d92c = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _3fbeaf16 = () => interopDefault(import('../pages/transport/index.vue' /* webpackChunkName: "pages/transport/index" */))
const _d1fe3e76 = () => interopDefault(import('../pages/transport/index/create.vue' /* webpackChunkName: "pages/transport/index/create" */))
const _3616e1da = () => interopDefault(import('../pages/transport/index/edit.vue' /* webpackChunkName: "pages/transport/index/edit" */))
const _843dc984 = () => interopDefault(import('../pages/transport/index/edit/_id.vue' /* webpackChunkName: "pages/transport/index/edit/_id" */))
const _dfdad892 = () => interopDefault(import('../pages/transport/index/info.vue' /* webpackChunkName: "pages/transport/index/info" */))
const _2937a43c = () => interopDefault(import('../pages/transport/index/info/_id.vue' /* webpackChunkName: "pages/transport/index/info/_id" */))
const _47255b26 = () => interopDefault(import('../pages/transporters/index.vue' /* webpackChunkName: "pages/transporters/index" */))
const _55761cb2 = () => interopDefault(import('../pages/transporters/index/info.vue' /* webpackChunkName: "pages/transporters/index/info" */))
const _735613d2 = () => interopDefault(import('../pages/transporters/index/info/_id.vue' /* webpackChunkName: "pages/transporters/index/info/_id" */))
const _c09cf09e = () => interopDefault(import('../pages/transporters-groups/index.vue' /* webpackChunkName: "pages/transporters-groups/index" */))
const _15d4ef9b = () => interopDefault(import('../pages/trips/index.vue' /* webpackChunkName: "pages/trips/index" */))
const _ff694b6e = () => interopDefault(import('../pages/trips/index/trip.vue' /* webpackChunkName: "pages/trips/index/trip" */))
const _24594918 = () => interopDefault(import('../pages/trips/index/trip/_id.vue' /* webpackChunkName: "pages/trips/index/trip/_id" */))
const _327b0a12 = () => interopDefault(import('../pages/trips/index/trip/_id/index.vue' /* webpackChunkName: "pages/trips/index/trip/_id/index" */))
const _0552ed75 = () => interopDefault(import('../pages/trips/index/trip/_id/edit.vue' /* webpackChunkName: "pages/trips/index/trip/_id/edit" */))
const _ef3e540a = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _b2355796 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _a169ea74 = () => interopDefault(import('../pages/users/index/create.vue' /* webpackChunkName: "pages/users/index/create" */))
const _6bd97558 = () => interopDefault(import('../pages/users/index/edit.vue' /* webpackChunkName: "pages/users/index/edit" */))
const _276e6e02 = () => interopDefault(import('../pages/users/index/edit/_id.vue' /* webpackChunkName: "pages/users/index/edit/_id" */))
const _753149f8 = () => interopDefault(import('../pages/users/index/info.vue' /* webpackChunkName: "pages/users/index/info" */))
const _19cbdba3 = () => interopDefault(import('../pages/users/index/info/_id.vue' /* webpackChunkName: "pages/users/index/info/_id" */))
const _131246ca = () => interopDefault(import('../pages/companies/branches.vue' /* webpackChunkName: "pages/companies/branches" */))
const _1bc0687b = () => interopDefault(import('../pages/companies/forwarders.vue' /* webpackChunkName: "pages/companies/forwarders" */))
const _9c26f9ec = () => interopDefault(import('../pages/orders/trips.vue' /* webpackChunkName: "pages/orders/trips" */))
const _4d5df206 = () => interopDefault(import('../pages/reports/realizations/index.vue' /* webpackChunkName: "pages/reports/realizations/index" */))
const _52cb3c7b = () => interopDefault(import('../pages/orders/trip/edit/_id.vue' /* webpackChunkName: "pages/orders/trip/edit/_id" */))
const _f40ddd08 = () => interopDefault(import('../pages/trips/edit/_id.vue' /* webpackChunkName: "pages/trips/edit/_id" */))
const _3ee4dcc4 = () => interopDefault(import('../pages/public-orders/_id.vue' /* webpackChunkName: "pages/public-orders/_id" */))
const _e600be08 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/administration",
    component: _972555b2,
    name: "administration"
  }, {
    path: "/applications",
    component: _3e66fb2c,
    name: "applications",
    children: [{
      path: "edit",
      component: _b5b33e86,
      name: "applications-index-edit",
      children: [{
        path: ":id?",
        component: _efc3f030,
        name: "applications-index-edit-id"
      }]
    }, {
      path: "info",
      component: _50446561,
      name: "applications-index-info",
      children: [{
        path: ":id?",
        component: _94bdcae8,
        name: "applications-index-info-id"
      }]
    }]
  }, {
    path: "/auth",
    component: _3128c11e,
    name: "auth"
  }, {
    path: "/available-transport",
    component: _408b3b92,
    name: "available-transport"
  }, {
    path: "/bi",
    component: _77a2b260,
    name: "bi"
  }, {
    path: "/bidding",
    component: _166e9968,
    name: "bidding",
    children: [{
      path: "create",
      component: _72d3d39a,
      name: "bidding-index-create"
    }, {
      path: "edit",
      component: _84d76ffe,
      name: "bidding-index-edit",
      children: [{
        path: ":id?",
        component: _5cfead2c,
        name: "bidding-index-edit-id"
      }]
    }, {
      path: "info",
      component: _68b24ca5,
      name: "bidding-index-info",
      children: [{
        path: ":id?",
        component: _eafc8060,
        name: "bidding-index-info-id"
      }]
    }, {
      path: "price-requests/create",
      component: _08bb9888,
      name: "bidding-index-price-requests-create"
    }, {
      path: "price-requests/edit",
      component: _68ec33ca,
      name: "bidding-index-price-requests-edit",
      children: [{
        path: ":id?",
        component: _7f798c75,
        name: "bidding-index-price-requests-edit-id"
      }]
    }, {
      path: "price-requests/info",
      component: _140a386e,
      name: "bidding-index-price-requests-info",
      children: [{
        path: ":id?",
        component: _a606c1ce,
        name: "bidding-index-price-requests-info-id"
      }]
    }]
  }, {
    path: "/companies",
    component: _2fad6348,
    name: "companies",
    children: [{
      path: "create",
      component: _1cda7053,
      name: "companies-index-create"
    }, {
      path: "edit",
      component: _3768aa21,
      name: "companies-index-edit",
      children: [{
        path: ":id?",
        component: _3394ff4c,
        name: "companies-index-edit-id"
      }, {
        path: ":customerId?/:clientId?",
        component: _628f1ea0,
        name: "companies-index-edit-customerId-clientId"
      }]
    }, {
      path: "info",
      component: _3af2a276,
      name: "companies-index-info",
      children: [{
        path: ":id?",
        component: _611811f0,
        name: "companies-index-info-id"
      }, {
        path: ":customerId?/:clientId?",
        component: _11585044,
        name: "companies-index-info-customerId-clientId"
      }]
    }]
  }, {
    path: "/company-contract",
    component: _75eda0ab,
    name: "company-contract"
  }, {
    path: "/company-details",
    component: _070f401f,
    name: "company-details"
  }, {
    path: "/company-documents",
    component: _dc9286d6,
    name: "company-documents"
  }, {
    path: "/company-driver",
    component: _7af5287e,
    name: "company-driver"
  }, {
    path: "/company-transport",
    component: _317247c6,
    name: "company-transport"
  }, {
    path: "/correspondence",
    component: _4cfca963,
    name: "correspondence",
    children: [{
      path: "create",
      component: _809fb450,
      name: "correspondence-index-create"
    }, {
      path: "edit",
      component: _96700634,
      name: "correspondence-index-edit",
      children: [{
        path: ":id?",
        component: _77d36791,
        name: "correspondence-index-edit-id"
      }]
    }]
  }, {
    path: "/drivers",
    component: _af918190,
    name: "drivers",
    children: [{
      path: "create",
      component: _a6a3733a,
      name: "drivers-index-create"
    }, {
      path: "edit",
      component: _094a779e,
      name: "drivers-index-edit",
      children: [{
        path: ":id?/:companyId?",
        component: _77ac23bc,
        name: "drivers-index-edit-id-companyId"
      }]
    }, {
      path: "info",
      component: _b30e6e56,
      name: "drivers-index-info",
      children: [{
        path: ":id?/:companyId?",
        component: _35b7c298,
        name: "drivers-index-info-id-companyId"
      }]
    }]
  }, {
    path: "/fines",
    component: _e213e234,
    name: "fines",
    children: [{
      path: "edit",
      component: _011edd7a,
      name: "fines-index-edit",
      children: [{
        path: ":id?",
        component: _5930856e,
        name: "fines-index-edit-id"
      }]
    }, {
      path: "info",
      component: _aae2d432,
      name: "fines-index-info",
      children: [{
        path: ":id?",
        component: _f298cfdc,
        name: "fines-index-info-id"
      }]
    }]
  }, {
    path: "/instructions",
    component: _545c7da4,
    name: "instructions"
  }, {
    path: "/no-access",
    component: _54d6321d,
    name: "no-access"
  }, {
    path: "/orders",
    component: _cf0dee24,
    name: "orders",
    children: [{
      path: "create",
      component: _70c37826,
      name: "orders-index-create"
    }, {
      path: "edit",
      component: _40609c3b,
      name: "orders-index-edit",
      children: [{
        path: ":id?",
        component: _3cb25466,
        name: "orders-index-edit-id"
      }]
    }, {
      path: "info",
      component: _2902be42,
      name: "orders-index-info",
      children: [{
        path: ":id?",
        component: _6a35670a,
        name: "orders-index-info-id",
        children: [{
          path: "getTrip",
          component: _5fcf6ed4,
          name: "orders-index-info-id-getTrip"
        }, {
          path: "trip",
          component: _93f19f8c,
          name: "orders-index-info-id-trip"
        }, {
          path: "viewTrip",
          component: _d383d902,
          name: "orders-index-info-id-viewTrip"
        }]
      }]
    }]
  }, {
    path: "/profile",
    component: _565403d4,
    name: "profile"
  }, {
    path: "/public-orders",
    component: _235899b6,
    name: "public-orders"
  }, {
    path: "/references",
    component: _774b19d1,
    name: "references"
  }, {
    path: "/settings",
    component: _4572d92c,
    name: "settings"
  }, {
    path: "/transport",
    component: _3fbeaf16,
    name: "transport",
    children: [{
      path: "create",
      component: _d1fe3e76,
      name: "transport-index-create"
    }, {
      path: "edit",
      component: _3616e1da,
      name: "transport-index-edit",
      children: [{
        path: ":id?",
        component: _843dc984,
        name: "transport-index-edit-id"
      }]
    }, {
      path: "info",
      component: _dfdad892,
      name: "transport-index-info",
      children: [{
        path: ":id?",
        component: _2937a43c,
        name: "transport-index-info-id"
      }]
    }]
  }, {
    path: "/transporters",
    component: _47255b26,
    name: "transporters",
    children: [{
      path: "info",
      component: _55761cb2,
      name: "transporters-index-info",
      children: [{
        path: ":id?",
        component: _735613d2,
        name: "transporters-index-info-id"
      }]
    }]
  }, {
    path: "/transporters-groups",
    component: _c09cf09e,
    name: "transporters-groups"
  }, {
    path: "/trips",
    component: _15d4ef9b,
    name: "trips",
    children: [{
      path: "trip",
      component: _ff694b6e,
      name: "trips-index-trip",
      children: [{
        path: ":id?",
        component: _24594918,
        children: [{
          path: "",
          component: _327b0a12,
          name: "trips-index-trip-id"
        }, {
          path: "edit",
          component: _0552ed75,
          name: "trips-index-trip-id-edit"
        }]
      }]
    }]
  }, {
    path: "/unsubscribe",
    component: _ef3e540a,
    name: "unsubscribe"
  }, {
    path: "/users",
    component: _b2355796,
    name: "users",
    children: [{
      path: "create",
      component: _a169ea74,
      name: "users-index-create"
    }, {
      path: "edit",
      component: _6bd97558,
      name: "users-index-edit",
      children: [{
        path: ":id?",
        component: _276e6e02,
        name: "users-index-edit-id"
      }]
    }, {
      path: "info",
      component: _753149f8,
      name: "users-index-info",
      children: [{
        path: ":id?",
        component: _19cbdba3,
        name: "users-index-info-id"
      }]
    }]
  }, {
    path: "/companies/branches",
    component: _131246ca,
    name: "companies-branches"
  }, {
    path: "/companies/forwarders",
    component: _1bc0687b,
    name: "companies-forwarders"
  }, {
    path: "/orders/trips",
    component: _9c26f9ec,
    name: "orders-trips"
  }, {
    path: "/reports/realizations",
    component: _4d5df206,
    name: "reports-realizations"
  }, {
    path: "/orders/trip/edit/:id?",
    component: _52cb3c7b,
    name: "orders-trip-edit-id"
  }, {
    path: "/trips/edit/:id?",
    component: _f40ddd08,
    name: "trips-edit-id"
  }, {
    path: "/public-orders/:id?",
    component: _3ee4dcc4,
    name: "public-orders-id"
  }, {
    path: "/",
    component: _e600be08,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
