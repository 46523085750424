<template>
  <div
    v-tooltip="disabled ? {
      content: tooltipHint,
      trigger: 'hover',
      delay: { show: 100, hide: 200 },
      autoHide: true,
    } : null"
    class="t-button"
    :class="{
      't-button--without-text': hasText($slots.default),
      't-button--disabled': disabled,
      't-button--outlined': outlined,
      't-button--transparent': transparent,
      't-button--block': block,
      't-button--has-icons': hasBothIcons,
      't-button--secondary-action': secondaryAction,
      't-button--active-copy': copyActive,
      ...sizeableClasses
    }"
  >
    <Component
      :is="currentComponent"
      :type="isButton ? type : undefined"
      :to="isNuxtLink ? href : null"
      :href="href"
      :target="target"
      :disabled="(isButton ? disabled : undefined) || loading"
      class="t-button--inner-block"
      @click="$emit('click', $event)"
      @mouseenter="$emit('mouseenter', $event)"
      @mouseleave="$emit('mouseleave', $event)"
    >
      <TIconSvg
        v-if="icon !== '' && !loading"
        class="t-button__icon t-button__icon--prepend"
        :class="{
          't-button__icon--without-text': hasText($slots.default)
        }"
        :name="icon"
        :width="iconWidth"
        :height="iconHeight"
        :view-box="iconViewBox"
        :fill="iconFill"
        :stroke="disabled ? '#dbe0ea' : iconStroke"
      />
      <TSpinner
        v-if="icon !== '' && loading"
        class="t-button__icon t-button__icon--prepend"
        :size="iconWidth"
        :icon-view-box="iconViewBox"
        :icon-fill="iconFill"
        :icon-stroke="iconStroke"
      />
      <slot />
      <slot name="after">
        <TIconSvg
          v-if="iconAppend !== '' && !loading"
          class="t-button__icon t-button__icon--append"
          :class="{
            't-button__icon--without-text': hasText($slots.default)
          }"
          :name="iconAppend"
          :width="iconWidth"
          :height="iconHeight"
          :view-box="iconViewBox"
          :fill="iconFill"
          :stroke="iconStroke"
        />
      </slot>
      <TSpinner
        v-if="isAppendSpinnerActive"
        class="t-button__icon t-button__icon--append"
        :icon-fill="iconFill"
        :icon-stroke="!disabled ? iconStroke : $options.colors.GRAY_ULTRA_DARK"
      />
    </Component>
  </div>
</template>

<script>
import sizesMixin from '~/assets/js/mixins/sizes';
import colors from '~/assets/scss/_variables.scss';

export default {
  name: 'TButton',

  colors,

  mixins: [sizesMixin],

  props: {
    icon: {
      type: String,
      default: '',
    },

    iconAppend: {
      type: String,
      default: '',
    },

    iconWidth: {
      type: [Number, String],
      default: 24,
    },

    iconHeight: {
      type: [Number, String],
      default: 24,
    },

    iconViewBox: {
      type: String,
      default: '0 0 24 24',
    },

    iconFill: {
      type: String,
      default: colors.WHITE,
    },

    iconStroke: {
      type: String,
      default: colors.BLUE_LIGHT,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    transparent: {
      type: Boolean,
      default: false,
    },

    block: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'button',
    },

    href: {
      type: String,
      default: '',
    },

    target: {
      type: String,
      default: '_self',
    },

    isNuxtLink: {
      type: Boolean,
      default: true,
    },

    secondaryAction: {
      type: Boolean,
      default: false,
    },

    tooltipHint: {
      type: String,
      default: '',
    },

    sidebar: {
      type: Boolean,
      default: false,
    },

    copyActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isButton() {
      return this.href === '';
    },

    tooltipConfig() {
      if (!this.disabled) return null;

      return {
        content: this.tooltipHint,
        trigger: 'hover',
        delay: { show: 100, hide: 200 },
        autoHide: true,
      };
    },

    currentComponent() {
      if (this.isButton) {
        return 'button';
      }

      if (this.isNuxtLink) {
        return 'nuxt-link';
      }

      return 'a';
    },

    // preloaderColor() {
    //   if (this.transparent) { return ''; }
    //   if (this.transparent) {
    //     return '';
    //   }

    //   switch (this.color) {
    //     case 'red':
    //       return 'white';
    //     default:
    //       return 'white';
    //   }
    // },

    hasBothIcons() {
      return this.icon !== '' && this.iconAppend !== '';
    },

    hasAppendSlot() {
      return Boolean(this.$slots.append && this.$slots.append[0].text.length);
    },

    isAppendSpinnerActive() {
      return this.loading;
      // return this.loading && (this.hasAppendSlot || this.iconAppend !== '')
      //   && !this.hasBothIcons;
    },

    getIconSize() {
      if (this.xSmall || this.small) {
        return 15;
      }
      return 18;
    },

    getIconViewBox() {
      if (this.xSmall || this.small) {
        return '0 0 15 15';
      }
      return '0 0 18 18';
    },

  },

  methods: {
    hasText(slot) {
      return slot === undefined || slot[0].text.trim() === '';
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

@mixin pseudoClasses {
  &:hover:not(.t-button--disabled) {
    @content;
  }
}

.t-button {
  $self: &;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 2px;
  color: $white;
  outline: none;
  background-color: $blue-light;
  font: $t3-regular;
  transition: all 0.15s ease;
  transition-property: background-color, color;
  cursor: pointer;
  border: 1.5px solid transparent;
  min-width: 100px;

  &--inner-block {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0 24px;
  }

  button, a {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &.t-size--x-small {
    &#{$self}--without-text {
      @include squareBox($input-size-xsmall);
    }
  }

  &.t-size--small {
    &#{$self}--without-text {
      @include squareBox($input-size-small);
    }
  }

  &.t-size--large {
    font: $t2-regular;
    min-width: 220px;

     &#{$self}--without-text {
       @include squareBox($input-size-large);
    }

    #{$self}--inner-block {
      padding: 0 32px;
    }
  }

  &--has-icons {
    justify-content: center;
  }

  &#{$self}--without-text {
    justify-content: center;
    min-width: unset;
    @include squareBox($input-size-default);

    #{$self}--inner-block {
      padding: 0;
    }
  }

  &:hover:not(.t-button--disabled) {
    background-color: $blue-dark;
  }

  &:active:not(.t-button--disabled) {
    background-color: $black;
  }

  &#{$self}--disabled {
    background-color: $gray-light-2;
    cursor: default;

    button {
      cursor: default;
    }
  }

  &--secondary-action {
    color: $blue-light;
    border: 1px solid $blue-light;
    background-color: $white !important;

    &.t-button--disabled {
      color: #556B87;
      border-color: $gray-light-2;
      background-color: $white;
    }

    &:hover:not(.t-button--disabled) {
      color: $blue-dark;
      border: 1px solid $blue-dark;
      background-color: $white;
    }
  }

  &--outlined {
    background-color: $white;
    border-color: $blue-light;
    color: $blue-light;

    &:hover:not(.t-button--disabled) {
      background-color: $white;
      border-color: $blue-dark;
      color: $blue-dark;
    }

    &:active:not(.t-button--disabled) {
      border-color: $blue-dark;
      color: $blue-dark;
      background-color: $blue-ultra-light;
    }

    &#{$self}--disabled {
      color: $gray-light-2;
      border-color: $gray-light-2;
      background-color: $white;
    }
  }

  &--transparent {
    height: max-content;
    min-width: max-content;
    background: none;
    color: $blue-light;

    @include pseudoClasses {
      background: none;
    }

    &:hover:not(.t-button--disabled) {
      color: darken($blue-light, 15%);
    }

    &:active:not(.t-button--disabled) {
      color: rgba($blue-light, 0.7);
      background-color: transparent;
    }

    &.t-button--disabled {
      cursor: default;
      color: $gray-light-1;
    }

    &.t-size--large {
      font: $t2-regular;
      padding: 0;
      min-width: max-content;
    }

    &.t-size--small {
      font: $t3-regular;
    }

    &.t-size--x-small {
      font: $t4-regular;
    }

    &#{$self}--disabled {
      color: $gray-light-2;
      background: none;
    }

    #{$self}--inner-block {
      padding: 0 !important;
    }
  }

  &--active-copy {
    background-color: #347AF0 !important;
    color: white !important;
  }

  &--block {
    width: 100%;
  }

  &__icon {
    max-height: 24px;

    &--prepend{
      margin-right: 8px;
    }

    &--append {
      margin-left: 14px;
    }

    &--without-text {
      margin: 0;
      max-width: 24px;
    }
  }
}
</style>
