// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.t-popup{position:fixed;width:100%;min-height:100vh;overflow:hidden;z-index:5000}.t-popup__overlay{z-index:-1;background:rgba(0,30,66,.2)}.t-popup__overlay,.t-popup__wrapper{position:fixed;top:0;right:0;bottom:0;left:0}.t-popup__bottom{position:sticky;bottom:-1px;background-color:#fff;z-index:5;padding-top:4px;padding-bottom:48px}.t-popup__bottom--side{--offset-popup:112px;position:fixed;width:calc(var(--width-bottom) - var(--offset-popup));padding-bottom:96px}.t-popup__header{position:sticky;top:-1px;background-color:#fff;z-index:5;padding-bottom:16px;padding-top:64px}.t-popup__header--side{padding-top:90px}.t-popup__header .t-heading{margin-bottom:4px}.t-popup__content{background-color:#fff;overflow-x:hidden;overflow-y:auto}.t-popup__content--center{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);box-shadow:0 2px 10px rgba(0,0,0,.24);max-height:90vh}.t-popup__content--side{position:absolute;right:0;bottom:0;top:0}.t-popup .t-popup__close-button{position:absolute;top:24px;right:-24px;cursor:pointer}.t-popup .t-popup__close-button--side{right:32px;top:48px}@media(min-width:560px){.t-popup{padding:0;overflow:hidden}.t-popup__content{padding:0 64px!important;width:495px}.t-popup__content--side{padding:0 48px 0 64px!important}.t-popup__content--offset-bottom{padding-bottom:24px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
