export const state = () => ({
  isRegisterControlLoading: false,
  isSendRegisterDataSuccessful: false,
  isVerifyRegisterControlLoading: false,
  role: '',
});

export const getters = {
  GET_CONTROL_LOADING: state => state.isRegisterControlLoading,
  GET_SEND_REGISTER_DATA_SUCCESSFUL: state => state.isSendRegisterDataSuccessful,
  GET_VR_CONTROL_LOADING: state => state.isVerifyRegisterControlLoading,
  GET_REGISTERED_ROLE: state => state.role,
};

export const actions = {
  REGISTER({ commit }, payload) {
    commit('setRole', payload.role);
    commit('setControlLoading', true);
    return this.$axios.post('/register', { ...payload })
      .then(data => {
        commit('setControlLoading', false);
        commit('setSendRegisterDataSuccessful', true);
        return data;
      })
      .finally(() => {
        commit('setControlLoading', false);
      });
  },

  VERIFY_REGISTER({ commit }, payload) {
    commit('setVRControlLoading', true);
    return this.$axios.post('/verification', { ...payload })
      .then(({ data }) => {
        commit('setVRControlLoading', false);
        return data;
      })
      .finally(() => {
        commit('setVRControlLoading', false);
      });
  },
};

export const mutations = {
  setControlLoading(state, payload) {
    state.isRegisterControlLoading = payload;
  },

  setSendRegisterDataSuccessful(state, payload) {
    state.isSendRegisterDataSuccessful = payload;
  },

  setVRControlLoading(state, payload) {
    state.isVerifyRegisterControlLoading = payload;
  },

  setRole(state, payload) {
    state.role = payload;
  },
};
