<template>
  <div class="help">
<!--    {{$t('auth.If you have any questions, please contact support')}}-->
<!--    <span class="mb-2">{{$options.phoneNumber}}</span>-->
<!--    <TLink-->
<!--      :is-nuxt-link="false"-->
<!--      :is-underlined="false"-->
<!--      :route="`mailto:${$options.emailLink}`"-->
<!--    >-->
<!--      {{$options.emailLink}}-->
<!--    </TLink>-->
  </div>
</template>

<script>
import { FESCO_SUPPORT_EMAIL, FESCO_SUPPORT_PHONE } from '~/assets/js/constants';

export default {
  name: 'HelpCenter',

  emailLink: FESCO_SUPPORT_EMAIL,
  phoneNumber: FESCO_SUPPORT_PHONE,
};
</script>

<style lang='scss'>
@import '~/assets/scss/_variables';

.help {
  display: flex;
  flex-direction: column;
  font: 400 10px / 160% $font-family;
  color: $gray-dark-2;
  mix-blend-mode: normal;

  a.t-link{
    font: 400 10px / 160% $font-family;
  }
}
</style>
