<template>
  <label
    class="t-switch"
    :class="{
      't-switch--checked': value,
      't-switch--disabled': disabled,
      't-switch--error': hasError,
    }"
  >
    <input
      :id="id"
      ref="input"
      v-model="value"
      type="checkbox"
      class="t-switch__input"
      :disabled="disabled"
      @input="$emit('input', $event.target.checked)"
    >
  </label>
</template>

<script>
export default {
  name: 'TSwitch',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.$refs.input.checked = this.value;
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

  .t-switch {
    height: 20px;
    width: 32px;
    position: relative;
    margin-bottom: 0;
    display: block;
    cursor: pointer;

    input {
      margin-top: 0;
      padding: 0;
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    &:before {
      background-color: $gray-dark-2;
      border-radius: 12px;
      content: '';
      height: 20px;
      top: 0px;
      position: absolute;
      transition: all 0.2s ease-in-out;
      width: 32px;
      left: 0px;
    }

    &:after {
      @include centerAbsoluteY;
      content: '';
      background-color: $white;
      border-radius: 12px;
      height: 16px;
      left: 2px;
      position: absolute;
      transition: all 0.2s ease-in-out;
      width: 16px;
    }

     &:hover{
        &::before {
          background-color: $gray-ultra-dark;
      }
    }

    &--checked {
      &:before {
        background-color: $blue-light;
      }

      &:after {
        left: 14px;
      }

      &:hover{
        &:before {
          background-color: $blue-dark;
        }
      }
    }

    &--disabled {
      &:before {
        background-color: $blue-ultra-light;
      }
    }
  }
</style>
