// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.header{justify-content:space-between;padding:24px 53px 18px 63px;max-height:80px}.header,.header__go-back-link{display:flex;align-items:center}.header__arrow-back{margin-right:6px}.header--closed{margin-bottom:16px;min-height:40px}.header-info{padding-bottom:3px;position:relative;-webkit-user-select:none;-moz-user-select:none;user-select:none;cursor:pointer;margin-left:auto}.header-info__container{display:flex}.header-info__user{margin-right:10px;text-align:right}.header__position{color:#a7b5ce;font-size:10px;line-height:1.4}.header__name-text,.header__position{text-overflow:ellipsis;overflow:hidden}.header__name-text--double-surname{white-space:normal;word-break:break-word;text-overflow:unset}.header__arrow{position:relative;cursor:pointer;transform:rotate(0);transition:transform .3s}.header__arrow--up{transform:rotate(180deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
