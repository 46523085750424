<template>
  <!--  <ul-->
  <!--    v-if="!noLiSurround"-->
  <!--    class="t-pagination"-->
  <!--  >-->
  <!--    <li-->
  <!--      v-if="firstLastButton"-->
  <!--      :class="[pageClass, firstPageSelected() ? disabledClass : '']"-->
  <!--    >-->
  <!--      <a-->
  <!--        :class="pageLinkClass"-->
  <!--        :tabindex="firstPageSelected() ? -1 : 0"-->
  <!--        @click="selectFirstPage()"-->
  <!--        @keyup.enter="selectFirstPage()"-->
  <!--        v-html="firstButtonText"-->
  <!--      />-->
  <!--    </li>-->
  <!--    <li-->
  <!--      v-if="!(firstPageSelected() && hidePrevNext)"-->
  <!--      :class="[prevClass, firstPageSelected() ? disabledClass : '']"-->
  <!--    >-->
  <!--      <a-->
  <!--        :class="prevLinkClass"-->
  <!--        :tabindex="firstPageSelected() ? -1 : 0"-->
  <!--        @click="prevPage()"-->
  <!--        @keyup.enter="prevPage()"-->
  <!--      >-->
  <!--        <slot name="prev">-->
  <!--          <TIconSvg-->
  <!--            name="arrow-left-new"-->
  <!--            width="24"-->
  <!--            height="24"-->
  <!--            view-box="0 0 24 24"-->
  <!--            stroke="currentColor"-->
  <!--          />-->
  <!--        </slot>-->
  <!--      </a>-->
  <!--    </li>-->
  <!--    <li-->
  <!--      v-for="page in pages"-->
  <!--      :key="page.vueKey"-->
  <!--      :class="[-->
  <!--        pageClass,-->
  <!--        page.selected ? activeClass : '',-->
  <!--        page.disabled ? disabledClass : '',-->
  <!--        page.breakView ? breakViewClass: ''-->
  <!--      ]"-->
  <!--    >-->
  <!--      <a-->
  <!--        v-if="page.breakView"-->
  <!--        :class="[pageLinkClass, breakViewLinkClass]"-->
  <!--        tabindex="0"-->
  <!--      >-->
  <!--        <slot name="breakViewContent">-->
  <!--          {{breakViewText}}-->
  <!--        </slot>-->
  <!--      </a>-->
  <!--      <a-->
  <!--        v-else-if="page.disabled"-->
  <!--        :class="pageLinkClass"-->
  <!--        tabindex="0"-->
  <!--      >-->
  <!--        {{page.content}}-->
  <!--      </a>-->
  <!--      <a-->
  <!--        v-else-->
  <!--        :class="pageLinkClass"-->
  <!--        tabindex="0"-->
  <!--        @click="handlePageSelected(page.index + 1)"-->
  <!--        @keyup.enter="handlePageSelected(page.index + 1)"-->
  <!--      >-->
  <!--        {{page.content}}-->
  <!--      </a>-->
  <!--    </li>-->
  <!--    <li-->
  <!--      v-if="!(lastPageSelected() && hidePrevNext)"-->
  <!--      :class="[nextClass, lastPageSelected() ? disabledClass : '']"-->
  <!--    >-->
  <!--      <a-->
  <!--        :class="nextLinkClass"-->
  <!--        :tabindex="lastPageSelected() ? -1 : 0"-->
  <!--        @click="nextPage()"-->
  <!--        @keyup.enter="nextPage()"-->
  <!--      >-->
  <!--        <slot name="next">-->
  <!--          <TIconSvg-->
  <!--            name="arrow-right-new"-->
  <!--            width="24"-->
  <!--            height="24"-->
  <!--            view-box="0 0 24 24"-->
  <!--            stroke="currentColor"-->
  <!--          />-->
  <!--        </slot>-->
  <!--      </a>-->
  <!--    </li>-->
  <!--    <li-->
  <!--      v-if="firstLastButton"-->
  <!--      :class="[pageClass, lastPageSelected() ? disabledClass : '']"-->
  <!--    >-->
  <!--      <a-->
  <!--        :class="pageLinkClass"-->
  <!--        :tabindex="lastPageSelected() ? -1 : 0"-->
  <!--        @click="selectLastPage()"-->
  <!--        @keyup.enter="selectLastPage()"-->
  <!--        v-html="lastButtonText"-->
  <!--      />-->
  <!--    </li>-->
  <!--  </ul>-->
  <!--  <div-->
  <!--    v-else-->
  <!--    class="t-pagination"-->
  <!--  >-->
  <!--    <a-->
  <!--      v-if="firstLastButton"-->
  <!--      :class="[pageLinkClass, firstPageSelected() ? disabledClass : '']"-->
  <!--      tabindex="0"-->
  <!--      @click="selectFirstPage()"-->
  <!--      @keyup.enter="selectFirstPage()"-->
  <!--      v-html="firstButtonText"-->
  <!--    />-->
  <!--    <a-->
  <!--      v-if="!(firstPageSelected() && hidePrevNext)"-->
  <!--      :class="[prevLinkClass, firstPageSelected() ? disabledClass : '']"-->
  <!--      tabindex="0"-->
  <!--      @click="prevPage()"-->
  <!--      @keyup.enter="prevPage()"-->
  <!--    >-->
  <!--      <slot name="prev">-->
  <!--        <TIconSvg-->
  <!--          name="arrow-left-new"-->
  <!--          width="24"-->
  <!--          height="24"-->
  <!--          view-box="0 0 24 24"-->
  <!--          stroke="currentColor"-->
  <!--        />-->
  <!--      </slot>-->
  <!--    </a>-->
  <!--    <template v-for="page in pages">-->
  <!--      <a-->
  <!--        v-if="page.breakView"-->
  <!--        :key="page.vueKey"-->
  <!--        :class="[pageLinkClass, breakViewLinkClass, page.disabled ? disabledClass : '']"-->
  <!--        tabindex="0"-->
  <!--      >-->
  <!--        <slot name="breakViewContent">-->
  <!--          {{breakViewText}}-->
  <!--        </slot>-->
  <!--      </a>-->
  <!--      <a-->
  <!--        v-else-if="page.disabled"-->
  <!--        :key="page.vueKey"-->
  <!--        :class="[pageLinkClass, page.selected ? activeClass : '', disabledClass]"-->
  <!--        tabindex="0"-->
  <!--      >-->
  <!--        {{page.content}}-->
  <!--      </a>-->
  <!--      <a-->
  <!--        v-else-->
  <!--        :key="page.vueKey"-->
  <!--        :class="[pageLinkClass, page.selected ? activeClass : '']"-->
  <!--        tabindex="0"-->
  <!--        @click="handlePageSelected(page.index + 1)"-->
  <!--        @keyup.enter="handlePageSelected(page.index + 1)"-->
  <!--      >-->
  <!--        {{page.content}}-->
  <!--      </a>-->
  <!--    </template>-->
  <!--    <a-->
  <!--      v-if="!(lastPageSelected() && hidePrevNext)"-->
  <!--      :class="[nextLinkClass, lastPageSelected() ? disabledClass : '']"-->
  <!--      tabindex="0"-->
  <!--      @click="nextPage()"-->
  <!--      @keyup.enter="nextPage()"-->
  <!--    >-->
  <!--      <slot name="next">-->
  <!--        <TIconSvg-->
  <!--          name="arrow-right-new"-->
  <!--          width="24"-->
  <!--          height="24"-->
  <!--          view-box="0 0 24 24"-->
  <!--          stroke="currentColor"-->
  <!--        />-->
  <!--      </slot>-->
  <!--    </a>-->
  <!--    <a-->
  <!--      v-if="firstLastButton"-->
  <!--      :class="[pageLinkClass, lastPageSelected() ? disabledClass : '']"-->
  <!--      tabindex="0"-->
  <!--      @click="selectLastPage()"-->
  <!--      @keyup.enter="selectLastPage()"-->
  <!--      v-html="lastButtonText"-->
  <!--    />-->
  <div
    class="t-pagination-container"
    :class="{ 't-pagination-container_full': fullWidth }"
  >
    <div>
      <ul
        v-if="!noLiSurround"
        class="t-pagination"
      >
        <li
          v-if="firstLastButton"
          :class="[pageClass, firstPageSelected() ? disabledClass : '']"
        >
          <a
            :class="pageLinkClass"
            :tabindex="firstPageSelected() ? -1 : 0"
            @click="selectFirstPage()"
            @keyup.enter="selectFirstPage()"
            v-html="firstButtonText"
          />
        </li>
        <li
          v-if="!(firstPageSelected() && hidePrevNext)"
          :class="[prevClass, firstPageSelected() ? disabledClass : '']"
        >
          <a
            :class="prevLinkClass"
            :tabindex="firstPageSelected() ? -1 : 0"
            @click="prevPage()"
            @keyup.enter="prevPage()"
          >
            <slot name="prev">
              <TIconSvg
                name="arrow-left-new"
                width="24"
                height="24"
                view-box="0 0 24 24"
                stroke="currentColor"
              />
            </slot>
          </a>
        </li>
        <li
          v-for="page in pages"
          :key="page.vueKey"
          :class="[
            pageClass,
            page.selected ? activeClass : '',
            page.disabled ? disabledClass : '',
            page.breakView ? breakViewClass: ''
          ]"
        >
          <a
            v-if="page.breakView"
            :class="[pageLinkClass, breakViewLinkClass]"
            tabindex="0"
          >
            <slot name="breakViewContent">
              {{breakViewText}}
            </slot>
          </a>
          <a
            v-else-if="page.disabled"
            :class="pageLinkClass"
            tabindex="0"
          >
            {{page.content}}
          </a>
          <a
            v-else
            :class="pageLinkClass"
            tabindex="0"
            @click="handlePageSelected(page.index + 1)"
            @keyup.enter="handlePageSelected(page.index + 1)"
          >
            {{page.content}}
          </a>
        </li>
        <li
          v-if="!(lastPageSelected() && hidePrevNext)"
          :class="[nextClass, lastPageSelected() ? disabledClass : '']"
        >
          <a
            :class="nextLinkClass"
            :tabindex="lastPageSelected() ? -1 : 0"
            @click="nextPage()"
            @keyup.enter="nextPage()"
          >
            <slot name="next">
              <TIconSvg
                name="arrow-right-new"
                width="24"
                height="24"
                view-box="0 0 24 24"
                stroke="currentColor"
              />
            </slot>
          </a>
        </li>
        <li
          v-if="firstLastButton"
          :class="[pageClass, lastPageSelected() ? disabledClass : '']"
        >
          <a
            :class="pageLinkClass"
            :tabindex="lastPageSelected() ? -1 : 0"
            @click="selectLastPage()"
            @keyup.enter="selectLastPage()"
            v-html="lastButtonText"
          />
        </li>
      </ul>
      <div
        v-else
        class="t-pagination"
      >
        <a
          v-if="firstLastButton"
          :class="[pageLinkClass, firstPageSelected() ? disabledClass : '']"
          tabindex="0"
          @click="selectFirstPage()"
          @keyup.enter="selectFirstPage()"
          v-html="firstButtonText"
        />
        <a
          v-if="!(firstPageSelected() && hidePrevNext)"
          :class="[prevLinkClass, firstPageSelected() ? disabledClass : '']"
          tabindex="0"
          @click="prevPage()"
          @keyup.enter="prevPage()"
        >
          <slot name="prev">
            <TIconSvg
              name="arrow-left-new"
              width="24"
              height="24"
              view-box="0 0 24 24"
              stroke="currentColor"
            />
          </slot>
        </a>
        <template v-for="page in pages">
          <a
            v-if="page.breakView"
            :key="page.vueKey"
            :class="[pageLinkClass, breakViewLinkClass, page.disabled ? disabledClass : '']"
            tabindex="0"
          >
            <slot name="breakViewContent">
              {{breakViewText}}
            </slot>
          </a>
          <a
            v-else-if="page.disabled"
            :key="page.vueKey"
            :class="[pageLinkClass, page.selected ? activeClass : '', disabledClass]"
            tabindex="0"
          >
            {{page.content}}
          </a>
          <a
            v-else
            :key="page.vueKey"
            :class="[pageLinkClass, page.selected ? activeClass : '']"
            tabindex="0"
            @click="handlePageSelected(page.index + 1)"
            @keyup.enter="handlePageSelected(page.index + 1)"
          >
            {{page.content}}
          </a>
        </template>
        <a
          v-if="!(lastPageSelected() && hidePrevNext)"
          :class="[nextLinkClass, lastPageSelected() ? disabledClass : '']"
          tabindex="0"
          @click="nextPage()"
          @keyup.enter="nextPage()"
        >
          <slot name="next">
            <TIconSvg
              name="arrow-right-new"
              width="24"
              height="24"
              view-box="0 0 24 24"
              stroke="currentColor"
            />
          </slot>
        </a>
        <a
          v-if="firstLastButton"
          :class="[pageLinkClass, lastPageSelected() ? disabledClass : '']"
          tabindex="0"
          @click="selectLastPage()"
          @keyup.enter="selectLastPage()"
          v-html="lastButtonText"
        />
      </div>
    </div>
    <div v-if="showLimit">
      <TMultiselect
        :items="selectItems"
        item-value="value"
        item-text="text"
        :value="limit"
        @input="limitHandler"
      />
    </div>
  </div>
</template>

<script>
import routerMixin from '~/assets/js/mixins/routerMixin';

export default {
  name: 'TPagination',

  mixins: [routerMixin],

  props: {
    value: {
      type: Number,
      default: null,
    },

    totalItems: {
      type: Number,
      required: true,
    },

    perPageItems: {
      type: Number,
      required: true,
    },

    forcePage: {
      type: Number,
      default: null,
    },

    pageRange: {
      type: Number,
      default: 3,
    },

    marginPages: {
      type: Number,
      default: 1,
    },

    breakViewText: {
      type: String,
      default: '…',
    },

    containerClass: {
      type: String,
      default: 't-pagination',
    },

    pageClass: {
      type: String,
      default: 'page-item',
    },

    pageLinkClass: {
      type: String,
      default: 'page-link-item',
    },

    prevClass: {
      type: String,
      default: 'prev-item',
    },

    prevLinkClass: {
      type: String,
      default: 'prev-link-item',
    },

    nextClass: {
      type: String,
      default: 'next-item',
    },

    nextLinkClass: {
      type: String,
      default: 'next-link-item',
    },

    breakViewClass: {
      type: String,
      default: 'break-view',
    },

    breakViewLinkClass: {
      type: String,
      default: 'break-view-link',
    },

    activeClass: {
      type: String,
      default: 'active',
    },

    disabledClass: {
      type: String,
      default: 'disabled',
    },

    noLiSurround: {
      type: Boolean,
      default: false,
    },

    firstLastButton: {
      type: Boolean,
      default: false,
    },

    firstButtonText: {
      type: String,
      default: 'First',
    },

    lastButtonText: {
      type: String,
      default: 'Last',
    },

    hidePrevNext: {
      type: Boolean,
      default: false,
    },

    lastPage: {
      type: Number,
      default: 0,
    },

    limit: {
      type: Object,
      default: () => ({ value: 10, text: '10' }),
    },
    showLimit: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: 1,
      selectItems: [
        { value: 25, text: '25' },
        { value: 100, text: '100' },
        { value: 250, text: '250' },
        { value: 500, text: '500' },
      ],
    };
  },

  computed: {
    selected() {
      return this.value || this.innerValue;
    },

    pageCount() {
      return this.lastPage ? this.lastPage : Math.ceil(this.totalItems / this.perPageItems);
      // return Math.ceil(this.totalItems / this.perPageItems);
    },

    pages() {
      const items = {};

      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          const page = {
            index,
            content: index + 1,
            selected: index === (this.selected - 1),
          };

          items[index] = page;
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2);
        const setPageItem = index => {
          const page = {
            index,
            content: index + 1,
            selected: index === (this.selected - 1),
          };

          items[index] = page;
        };

        const setBreakView = index => {
          const breakView = {
            disabled: true,
            breakView: true,
          };
          items[index] = breakView;
        };

        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i);
        }

        let selectedRangeLow = 0;
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - 1 - halfPageRange;
        }
        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1;
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
        }
        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
          setPageItem(i);
        }

        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1);
        }

        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1);
        }

        for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
          setPageItem(i);
        }
      }
      return items;
    },
  },

  beforeUpdate() {
    if (this.forcePage === undefined) {
      return;
    }

    if (this.forcePage !== this.selected) {
      this.innerValue = this.forcePage;
    }
  },

  methods: {
    handlePageSelected(selected) {
      if (this.selected === selected) {
        return;
      }

      this.setQuery({ ...this.getQuery(), page: selected });
      this.innerValue = selected;
      this.$emit('input', selected);
    },

    prevPage() {
      if (this.selected <= 1) {
        return;
      }

      this.handlePageSelected(this.selected - 1);
    },

    nextPage() {
      if (this.selected >= this.pageCount) {
        return;
      }

      this.handlePageSelected(this.selected + 1);
    },

    firstPageSelected() {
      return this.selected === 1;
    },

    lastPageSelected() {
      return (this.selected === this.pageCount) || (this.pageCount === 0);
    },

    selectFirstPage() {
      if (this.selected <= 1) {
        return;
      }

      this.handlePageSelected(1);
    },

    selectLastPage() {
      if (this.selected >= this.pageCount) {
        return;
      }

      this.handlePageSelected(this.pageCount);
    },

    limitHandler(value) {
      this.$emit('limit', value.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "assets/scss/_variables";

$self: &;

.t-pagination-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 50px;
  &_full {
    padding-right: 0;
  }
}

.t-pagination {
  display: flex;
  gap: 8px;

  margin: 0;
  padding: 0;
  .next-link-item,
  .prev-link-item,
  .page-link-item {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 44px;
    height: 44px;

    cursor: pointer;
    user-select: none;
    border-bottom: 3px solid transparent;
  }

  .break-view {
    min-width: 0;
    cursor: default;

    .page-link-item {
      cursor: default;
    }
  }

  .active {

    .page-link-item {
      color: $accent-color;
      border-bottom: 3px solid $accent-color;
    }
  }

  .disabled {
    cursor: default;

    .next-link-item,
    .prev-link-item {
      color: $secondary-color;

      cursor: default;
      pointer-events: none;
    }
  }
}
</style>
