<template>
  <div class="t-filters">
    <p
      class="t-filters__action"
      @click="isShown = !isShown"
    >
      <span class="t-filters__action-text">
        {{isShown ? shownText : hiddenText}}
      </span>

      <TIconSvg
        class="t-filters__arrow"
        :class="{'t-filters__arrow--shown': isShown}"
        name="arrow-down"
        :stroke="$options.colors.BLUE_LIGHT"
        view-box="0 0 24 24"
        height="18"
        width="18"
      />
    </p>

    <Transition name="fade">
      <div
        v-if="isShown"
        class="t-filters__content"
      >
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script>
// Colors
import colors from '@variables';

export default {
  name: 'TFilters',

  props: {
    shownText: {
      type: String,
      default() {
        return this.$t('Turn');
      },
    },

    hiddenText: {
      type: String,
      default() {
        return this.$t('Expand');
      },
    },
  },

  colors,

  data() {
    return {
      isShown: false,
    };
  },

  colors,
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-filters {
  &__action {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &-text {
      margin-right: 4px;
      font: $t3-regular;
      color: $blue-light;
    }
  }

  &__arrow--shown {
    transform: rotate(180deg);
  }

  &__content {
    margin-top: 25px;
  }
}
</style>
