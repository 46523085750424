<template>
  <div
    class="t-button-group"
    :class="{'t-button-group--transparent': transparent}"
  >
    <div class="t-button-group__content">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="t-button-group__buttons-wrap"
      >
        <TButton
          v-if="!option.isLink"
          :class="{'t-button-group__button--active': getIsTabActive(option)}"
          :loading="loading"
          class="t-button-group__button"
          small
          outlined
          @click="$emit('input', option.value)"
        >
          {{ option.text }}
          <template
            v-if="option.isCounter"
            #after
          >
            <TBadge
              :value="option.counter"
              :color="getBadgeColor(option)"
            />
          </template>
        </TButton>
        <TLink
          v-else
          :is-nuxt-link="isNuxtLink"
          :is-underlined="false"
          :route="option.link"
          :class="{ 't-button-group__button--active': option.isActive }"
          class="t-button t-button-group__button link"
          transparent
        >
          {{option.text}}
        </TLink>
        <span
          v-tooltip="tooltip ? `${tooltip} ${option.text}` : ''"
          class="t-button-group__hint"
        >
          {{option.hint}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import { isEqual } from 'chober';
import colors from '@/assets/scss/_variables.scss';
import SidepanelCounter from '../Features/Sidepanel/components/SidepanelMenu/components/SidepanelCounter.vue';
export default {
  name: 'TButtonGroup',
  components: { SidepanelCounter },
  props: {
    options: {
      type: Array,
      required: true,
    },

    isNuxtLink: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: () => '',
    },

    value: {
      type: [String, Number, Boolean, Array],
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    transparent: {
      type: Boolean,
      default: false,
    },
  },

  colors,

  methods: {
    isEqual,

    getIsTabActive(option) {
      return option.value === this.value
      || isEqual(option.value, this.value)
      || (Array.isArray(option.value) && option.value.includes(this.value[0]))
    },

    getBadgeColor(option) {
      return this.getIsTabActive(option)
        ? this.$options.colors.BLUE_LIGHT
        : this.$options.colors.GRAY_ULTRA_LIGHT_2;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-button-group {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .t-button#{&}__button {
    display: flex;
    font: $t4-regular;
    border-radius: 0;
    width: 100%;
    color: $primary-color;
    border: 2px solid $gray-ultra-light-2;
    transition: background-color 0.2s, color 0.2s;
    background-color: $gray-ultra-light-2;

    .t-badge {
      transition: border-color 0.2s, color 0.2s;
      background-color: transparent;
      margin-left: 8px;
    }

    &.link {
      padding: 0;
    }

    &:hover {
    }

    &--active {
      color: $accent-color;
      background-color: $white;

      .t-badge {
        color: $accent-color;
        border-color: $accent-color;
      }
    }
  }

  &__buttons-wrap {
    position: relative;

    &:first-child {
      .t-button.t-button-group__button {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    }

    &:last-child {
      .t-button.t-button-group__button {
        border-right-width: 1px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  &__hint {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--transparent {
    .t-button-group__buttons-wrap {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .t-button {
      height: 37px;
      min-width: max-content;
    }

    .t-button.t-button-group__button {
      padding: 0;
      background-color: transparent;
      border: none;
      border-radius: 0 !important;
      border-bottom: 1px solid transparent;
      color: $gray-ultra-dark;
    }

    .t-button.t-button-group__button--active {
      font: $t4-medium;
      color: $primary-color;
      background-color: transparent;
      border-bottom: 1px solid $primary-color;
    }

    .t-button.t-button-group__button--active .t-badge {
      color: $primary-color;
      border-color: $primary-color;
    }
  }
}
</style>
