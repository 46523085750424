<template>
  <ul class="sidepanel-menu">
    <SidepanelMenuItem
      v-for="item in filteredItems"
      :key="item.link"
      :is-shrunk="isShrunk"
      :is-counter="item.isCounter"
      :quantity="item.quantity"
      v-bind="item"
      @open-children="$emit('open-children')"
      @unshrunk-menu="$emit('unshrunk-menu')"
    />
  </ul>
</template>

<script>
// Components
import SidepanelMenuItem from './components/SidepanelMenuItem';

export default {
  name: 'SidepanelMenu',

  components: {
    SidepanelMenuItem,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    slug: {
      type: String,
      default: '',
    },

    isShrunk: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    filteredItems() {
      return this.items;
    },
  },

  methods: {
    checkSlugs(items) {
      return items.filter(item => (item.slugs ? item.slugs.includes(this.slug) : true));
    },
  },
};
</script>

<style scoped>
.sidepanel-menu {
  padding: 15px 0;
  padding-top: 0;
}
</style>
