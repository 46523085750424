<template>
  <component
    :is="isNuxtLink ? 'nuxt-link' : 'a'"
    :to="isNuxtLink ? route : null"
    :href="route"
    :target="target"
    class="t-link"
    :class="{
      't-link--underlined': isUnderlined,
      't-link--disabled': isDisabled,
      't-link--has-icon': hasIcon,
      't-link--multiline': isMultiline,
      ...fontSizeableClasses }"
    @click="$emit('click', $event)"
  >
    <TIconSvg
      v-if="hasIcon"
      :name="iconName"
      :fill="iconFill"
      :stroke="iconStroke"
      :width="iconWidth"
      :height="iconHeight"
      :view-box="iconViewBox"
      class="t-link__icon"
    />
    <slot />
  </component>
</template>

<script>
import sizesMixin from '~/assets/js/mixins/sizes';

export default {
  name: 'TLink',

  mixins: [sizesMixin],

  props: {
    isNuxtLink: {
      type: Boolean,
      default: true,
    },

    route: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isUnderlined: {
      type: Boolean,
      default: true,
    },

    isMultiline: {
      type: Boolean,
      default: false,
    },

    hasIcon: {
      type: Boolean,
      default: false,
    },

    iconName: {
      type: String,
      default: 'chevron-left',
    },

    iconWidth: {
      type: [String, Number],
      default: 18,
    },

    iconHeight: {
      type: [String, Number],
      default: 18,
    },

    iconViewBox: {
      type: String,
      default: '0 0 18 18',
    },

    iconFill: {
      type: String,
      default: 'currentColor',
    },

    iconStroke: {
      type: String,
      default: 'currentColor',
    },

    target: {
      type: String,
      default: '_self',
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-link {
  position: relative;
  font: $t4-regular;
  color: $blue-light;
  outline: none;
  cursor: pointer;
  transition: color 0.3s, opacity 0.3s;

  &:hover:not(.t-link--underlined):not(.t-link--disabled) {
    color: darken($blue-light, 15%);
  }

  &:active:not(.t-link--disabled) {
    color: rgba($blue-light, 0.7);
  }

  &.t-font--default {
    font: $t4-regular;
  }

  &.t-font--small {
    font: $t3-regular;
  }

  &--disabled {
    cursor: default;
    color: $gray-light-1;
  }

  &--multiline {
    white-space: normal;
  }

  &--underlined {
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      background-color: $blue-light;
      transition: background-color 0.3s;
    }

    &:hover::after {
      background-color: transparent;
    }

    &.t-link--multiline {
      &::after {
        display: none;
      }

      text-decoration: underline;
      text-decoration-color: $blue-light;
      transition: text-decoration-color 0.3s;

      &:hover {
        text-decoration-color: transparent;
      }
    }
  }

  &--has-icon {
    display: inline-flex;
    align-items: center;
  }

  &__icon {
    margin-right: 6px;
  }
}
</style>
