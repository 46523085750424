export const state = () => ({
  carTractor: 42,
});

export const getters = {
  carTractor: state => state.carTractor,
};

export const mutations = {
  setСarTractor(state, payload) {
    state.carTractor = payload;
  },
};

export const actions = {
};
