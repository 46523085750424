import { parsePreviewFileLinks, formatPhone } from '~/assets/js/utils';

const getTypes = (typesArr, arrKey, hideKey = '', carType = null) => typesArr.reduce(
  (acc, cur) => [
    ...acc,
    ...(hideKey && cur[hideKey] ? [] : [cur]),
    ...(cur[arrKey] ? getTypes(cur[arrKey], arrKey) : []),
  ],
  []
);

export const state = () => ({
  trailerLabels: [],
  transportLabels: [],
  marksLabels: [],
  isTransportFormLoading: false,
  formData: {},
  allLabels: {},
  tempMediaModelName: '',
  tempMediaModelId: null,
  media: {},
  history: [],
  ownerInfo: {},
  carOwnershipTypes: [],
  isOwnTransport: false,
  ownerConfirmationStatus: null,
  stsContains: false,
});

export const getters = {
  GET_TRAILER_LABELS: state => state.trailerLabels,
  GET_TRANSPORT_TYPES: state => Object.entries(state.transportLabels).map(([key, value]) => value),
  GET_CAR_MARKS: state => state.marksLabels,
  GET_FORM_DATA: state => state.formData,
  GET_MEDIA: state => state.media,
  GET_ALL_LABELS: state => state.allLabels,
  getTempMediaModelName: state => state.tempMediaModelName,
  getTempMediaModelId: state => state.tempMediaModelId,
  getHistory: state => state.history,
  getOwnerInfo: state => state.ownerInfo,
  getOwnerConfirmationStatus: state => state.ownerConfirmationStatus,
  getCarOwnershipTypes: state => Object.values(state.carOwnershipTypes),
  isOwnTransport: state => state.isOwnTransport,
  isStsContains: state => state.stsContains,
  getPlatformTypes: state => Object.entries(state.platformTypes).map(([key, value]) => value),
};

export const actions = {
  LOAD_TRAILER_LABELS({ commit }) {
    return this.$axios.get('/cars/labels')
      .then(({ data }) => {
        commit('setTransportLabels', data);
        return data;
      }).catch(e => {
        console.log(e);
      });
  },
  SAVE_CAR({ commit, dispatch }, payload) {
    const url = payload.id ? 'cars/update' : 'cars/create';
    commit('setTransportFormLoading', true);
    return this.$axios.post(url, {
      ...payload,
      // trailer_types: payload.trailer_types.map(type => type.id),
      userIds: payload.userIds
        .filter(Boolean)
        .map(user => user.id),
    })
      .then(({ data }) => {
        if (!payload.fromCompany) {
          dispatch('transport/transport-table/LOAD_TRANSPORT_LIST', {}, { root: true });
        }
        return data.success;
      }).finally(() => {
        commit('setTransportFormLoading', false);
      });
  },
  LOAD_FORM_DATA({ commit, dispatch }, payload) {
    commit('setTransportFormLoading', true);
    return this.$axios.get(`/cars/show/${payload}`)
      .then(({ data }) => {
        commit('setFormData', data);
        commit('setMediaData', data.media);
        commit('setIsOwnTransport', data.car);
      }).finally(() => {
        commit('setTransportFormLoading', false);
      });
  },
  async getTemporaryMediaData({ commit }) {
    await this.$axios.get('/media/temp').then(({ data }) => {
      commit('setTempMedia', data);
    });
  },

  async changeStatus({ dispatch }, payload) {
    await this.$axios.post(`/cars/${payload.modelId}/status`, payload)
      .catch(err => {
        console.log(err);
      });
  },

  LOAD_CAR_HISTORY({ commit }, id) {
    return this.$axios.get(`/cars/${id}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);
      }).catch(err => {
        console.log(err);
      });
  },

  LOAD_AUTOCOD_DATA({ commit, dispatch }, payload) {
    if (this.cancelSource) {
      this.cancelSource.cancel('Request cancelled');
    }
    this.cancelSource = this.$axios.CancelToken.source();

    commit('setTransportFormLoading', true);
    return this.$axios.get('/cars/autocod-data', {
      params: payload,
      cancelToken: this.cancelSource && this.cancelSource.token,
    })
      .then(({ data }) => data)
      .catch(err => console.log(err))
      .finally(() => {
        commit('setTransportFormLoading', false);
      });
  },

  async LOAD_OWNER_DATA({ commit }, { number, companyId }) {
    try {
      const { data } = await this.$axios.get('/cars/owner-data', {
        params: {
          number,
          company_id: companyId,
        },
      });

      const { data: ownerData } = data;
      const { owner_confirmation_status: ownerConfirmationStatus } = ownerData;
      const { owner } = ownerData;
      const currentOwner = owner?.owner ?? {};

      commit('setOwnerData', currentOwner);
      commit('setOwnerConfirmationStatus', ownerConfirmationStatus);
    } catch (error) {
      console.log(error);
    }
  },

  CLEAR_OWNER_INFO({ commit }) {
    commit('clearOwnerInfo');
  },

  SET_STS_MEDIA({ state, commit }, payload) {
    commit('setSTSMedia', payload);
  },

  REMOVE_STS_MEDIA({ state, commit }, payload) {
    const listSts = state.STSMedia;
    const fileIndex = listSts.findIndex(({ id }) => id === payload.id);

    if (fileIndex !== -1) {
      listSts.splice(fileIndex, 1);
    }
    commit('setSTSMedia', listSts);
  },
};

export const mutations = {
  setTransportFormLoading(state, payload) {
    state.isTransportFormLoading = payload;
  },
  setTransportLabels(state, payload) {
    state.allLabels = payload;
    state.trailerLabels = getTypes(payload.carTrailerTypes, 'child_types', 'is_virtual');
    state.transportLabels = payload.carTypes;
    state.marksLabels = payload.marks;
    state.carOwnershipTypes = payload.carOwnershipTypes;
    state.platformTypes = payload.platformTypes;
  },
  setFormData(state, { car, media }) {
    state.formData = {
      ...car,
      sts: parsePreviewFileLinks(media.STS_docs),
      pts: parsePreviewFileLinks(media.PTS_docs),
      lease: parsePreviewFileLinks(media.LEASE_docs),
      additional_docs: parsePreviewFileLinks(media.additional_docs),
      types: car.trailer_types,
      users: car.users,
      userIds: car.users.map(user => ({
        id: user.id,
        name: `${user.profile.fullName}, ${formatPhone(user.phone)}`,
      })),
      dd_id: car.dd_id,
    };

    state.ownerInfo = car.owner;
    state.ownerConfirmationStatus = car.owner_confirmation_status;
  },

  setIsOwnTransport(state, { company_id }) {
    state.isOwnTransport = this.getters['main/getCurrentUser'].active_company_id === company_id;
  },

  setMediaData(state, payload) {
    state.media = payload;
  },
  setTempMedia(state, payload) {
    state.tempMediaModelName = payload.tempMediaModelName;
    state.tempMediaModelId = payload.tempMedia.id;
  },

  setHistory(state, payload) {
    state.history = payload;
  },

  setOwnerData(state, payload) {
    state.ownerInfo = payload;
  },

  setOwnerConfirmationStatus(state, payload) {
    state.ownerConfirmationStatus = payload;
  },

  clearOwnerInfo(state) {
    state.ownerInfo = {};
    state.ownerConfirmationStatus = null;
  },

  removeFileFromCollection(state, { collection, fileId }) {
    if (!['sts', 'pts', 'lease', 'additional_docs'].includes(collection)) {
      console.error(`No such collection "${collection}"`);
      return;
    }
    const fileIndex = state.formData[collection].findIndex(media => media.file.id === fileId);
    if (fileIndex !== -1) {
      state.formData[collection].splice(fileIndex, 1);
    }
  },

  setSTSMedia(state, payload) {
    state.stsContains = payload?.length > 0;
  },
};
