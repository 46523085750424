var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabled ? {
    content: _vm.tooltipHint,
    trigger: 'hover',
    delay: { show: 100, hide: 200 },
    autoHide: true,
  } : null),expression:"disabled ? {\n    content: tooltipHint,\n    trigger: 'hover',\n    delay: { show: 100, hide: 200 },\n    autoHide: true,\n  } : null"}],staticClass:"t-button",class:Object.assign({}, {'t-button--without-text': _vm.hasText(_vm.$slots.default),
    't-button--disabled': _vm.disabled,
    't-button--outlined': _vm.outlined,
    't-button--transparent': _vm.transparent,
    't-button--block': _vm.block,
    't-button--has-icons': _vm.hasBothIcons,
    't-button--secondary-action': _vm.secondaryAction,
    't-button--active-copy': _vm.copyActive},
    _vm.sizeableClasses)},[_c(_vm.currentComponent,{tag:"Component",staticClass:"t-button--inner-block",attrs:{"type":_vm.isButton ? _vm.type : undefined,"to":_vm.isNuxtLink ? _vm.href : null,"href":_vm.href,"target":_vm.target,"disabled":(_vm.isButton ? _vm.disabled : undefined) || _vm.loading},on:{"click":function($event){return _vm.$emit('click', $event)},"mouseenter":function($event){return _vm.$emit('mouseenter', $event)},"mouseleave":function($event){return _vm.$emit('mouseleave', $event)}}},[(_vm.icon !== '' && !_vm.loading)?_c('TIconSvg',{staticClass:"t-button__icon t-button__icon--prepend",class:{
        't-button__icon--without-text': _vm.hasText(_vm.$slots.default)
      },attrs:{"name":_vm.icon,"width":_vm.iconWidth,"height":_vm.iconHeight,"view-box":_vm.iconViewBox,"fill":_vm.iconFill,"stroke":_vm.disabled ? '#dbe0ea' : _vm.iconStroke}}):_vm._e(),_vm._v(" "),(_vm.icon !== '' && _vm.loading)?_c('TSpinner',{staticClass:"t-button__icon t-button__icon--prepend",attrs:{"size":_vm.iconWidth,"icon-view-box":_vm.iconViewBox,"icon-fill":_vm.iconFill,"icon-stroke":_vm.iconStroke}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),_vm._t("after",[(_vm.iconAppend !== '' && !_vm.loading)?_c('TIconSvg',{staticClass:"t-button__icon t-button__icon--append",class:{
          't-button__icon--without-text': _vm.hasText(_vm.$slots.default)
        },attrs:{"name":_vm.iconAppend,"width":_vm.iconWidth,"height":_vm.iconHeight,"view-box":_vm.iconViewBox,"fill":_vm.iconFill,"stroke":_vm.iconStroke}}):_vm._e()]),_vm._v(" "),(_vm.isAppendSpinnerActive)?_c('TSpinner',{staticClass:"t-button__icon t-button__icon--append",attrs:{"icon-fill":_vm.iconFill,"icon-stroke":!_vm.disabled ? _vm.iconStroke : _vm.$options.colors.GRAY_ULTRA_DARK}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }