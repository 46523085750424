var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.children || !_vm.children.length)?_c('nuxt-link',{attrs:{"to":_vm.link,"exact":_vm.link === '/',"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
var href = ref.href;
return [_c('li',{staticClass:"sidepanel-menu-item",class:{ 'sidepanel-menu-item--active': isActive
      || href.includes('users')
      && _vm.$route.name
      && _vm.$route.name.includes('users')}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:({
        content: _vm.isShrunk ? _vm.text : '',
        classes: 'sidepanel-menu-item__tooltip',
      }),expression:"{\n        content: isShrunk ? text : '',\n        classes: 'sidepanel-menu-item__tooltip',\n      }",modifiers:{"top-end":true}}],staticClass:"sidepanel-menu-item__inner",class:{'sidepanel-menu-item__inner--shrunk': _vm.isShrunk},attrs:{"href":href},on:{"click":navigate}},[_c('TIconSvg',{staticClass:"sidepanel-menu-item__icon",class:{
          'sidepanel-menu-item__icon--active': isActive,
          'sidepanel-menu-item__icon--shrunk': !_vm.isShrunk
        },attrs:{"name":_vm.icon,"width":_vm.iconWidth,"height":_vm.iconHeight,"view-box":_vm.iconViewBox,"stroke":_vm.iconStroke}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShrunk),expression:"!isShrunk"}],staticClass:"sidepanel-menu-item__title"},[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"sidepanel-menu-item__counter"},[(_vm.isCounter)?_c('SidepanelCounter',{attrs:{"count":_vm.quantity}}):_vm._e()],1)],1)])]}}],null,false,2650176084)}):(_vm.children.length)?_c('li',{staticClass:"sidepanel-menu-item",class:{ 'sidepanel-submenu-item--active': _vm.isActive }},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:({
      content: _vm.isShrunk ? _vm.text : '',
      classes: 'sidepanel-menu-item__tooltip',
    }),expression:"{\n      content: isShrunk ? text : '',\n      classes: 'sidepanel-menu-item__tooltip',\n    }",modifiers:{"top-end":true}}],staticClass:"sidepanel-menu-item__inner",class:{ 'sidepanel-menu-item__inner--shrunk': _vm.isShrunk },on:{"click":_vm.toggleSubmenu}},[_c('TIconSvg',{staticClass:"sidepanel-menu-item__icon",class:{
        'sidepanel-menu-item__icon--active': _vm.isActive,
        'sidepanel-menu-item__icon--shrunk': !_vm.isShrunk
      },attrs:{"name":_vm.icon,"width":_vm.iconWidth,"height":_vm.iconHeight,"view-box":_vm.iconViewBox,"stroke":_vm.iconStroke}}),_vm._v(" "),_c('transition',{attrs:{"name":"g-slide-down"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShrunk),expression:"!isShrunk"}],staticClass:"sidepanel-menu-item__title",class:{ 'sidepanel-submenu-item--active': _vm.hasActiveChildren}},[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")])])],1),_vm._v(" "),_c('transition',{attrs:{"name":"g-slide-down"}},[_c('SidepanelSubmenu',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShrunk
        && (_vm.isSubmenuOpen || _vm.hasActiveChildren)),expression:"!isShrunk\n        && (isSubmenuOpen || hasActiveChildren)"}],staticClass:"sidepanel-menu-item__submenu",attrs:{"items":_vm.children}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }