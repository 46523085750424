import _ from 'lodash';

export const state = () => ({
  applicationList: [],
  selectedApplications: [],
  isTableLoading: false,
  labels: {
    container_statuses: [],
    container_owners: [],
    container_types: [],
    customs_types: [],
    dispositions: [],
    operation_types: [],
    order_statuses: [],
    order_types: [],
    transportation_types: [],
    trip_statuses: [],
  },
  query: null,
  tablePagination: null,
  tableHeadersOptions: [],
  carPoint: null,
  orderPoint: null,
  couplingOptions: null,
  applicationsQuantity: null,
  todoApplicationsQuantity: null,
  tsApplicationsQuantity: null,
  copyClipboard: {
    copyActivator: false,
    clipboardArray: null,
  },
  counters: null,
  queryAllFilters: {},
  helpStatusList: [],
  geoStatusesLabels: [],
  geoStatusesList: [],
});

export const getters = {
  getApplications: state => state.applicationList,
  getToCompleteApplications: state => state.applicationList.filter(
    app => app.status === 1
      || app.status === 2
  ),
  getSetByTsApplications: state => state.applicationList.filter(
    app => app.status === 3 || app.status === 4 || app.status === 5 || app.status === 6
  ),
  getSelectedApplications: state => state.selectedApplications,
  isApplicationSelect: state => payload => {
    for (const application of state.selectedApplications) {
      if (Object.values(application).includes(payload)) {
        return true;
      }
    }
  },
  getLabels: state => state.labels,
  getIsTableLoading: state => state.isTableLoading,
  getQuery: state => state.query,
  getTablePagination: state => state.tablePagination,
  getTableHeadersOptions: state => state.tableHeadersOptions,
  getCarPoint: state => state.carPoint,
  getOrderPoint: state => state.orderPoint,
  getCouplingOptions: state => state.couplingOptions,
  getApplicationsQuantity: state => state.applicationsQuantity,
  getTodoApplicationsQuantity: state => state.todoApplicationsQuantity,
  getTsApplicationsQuantity: state => state.tsApplicationsQuantity,
  getCopyActivator: state => state.copyActivator,
  getCounters: state => state.counters,
  getQueryForAllFilters: state => state.queryAllFilters,
  getHelpStatusList: state => state.helpStatusList,
  getGeoLabels: state => state.geoStatusesLabels,
  getGeoStatusesList: state => state.geoStatusesList,
};

export const mutations = {
  setApplications(state, payload) {
    state.selectedApplications = [];
    state.applicationList = payload.reduce((acc, a) => {
      const order1 = a[0];
      const order2 = a[1];
      if (order2) {
        order1.isPairedFirst = true;
        order2.isPairedLast = true;
        acc.push(order1, order2);
      } else {
        acc.push(order1);
      }
      return acc;
    }, []);
  },

  refreshApplications(state, payload) {
    state.selectedApplications = [];
    state.applicationList = payload;
  },

  setLabels(state, payload) {
    Object.entries(payload).forEach(([labelType, labelValues]) => {
      const list = Object.entries(labelValues).map(([id, name]) => ({
        name,
        id,
        value: id,
      }));
      state.labels[labelType] = list;
    });
  },

  setIsTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setBackendQuery(state, payload) {
    state.query = _.mapValues(payload, labels => labels.map(label => ({
      name: label.name,
      address: label.address,
      id: label.id,
      value: String(label.id),
    })));
  },

  setTablePagination(state, payload) {
    state.tablePagination = payload;
  },

  mergeApplication(state, payload) {
    const targetApplication = state.applicationList.find(
      ({ id }) => id === payload.id
    );
    // const currentOperation = payload.operations.find(
    //   ({ id }) => id === targetApplication.operations.id
    // );

    if (targetApplication) {
      _.merge(targetApplication, {
        ...payload,
        operations: [...payload.operations],
      });
    }
  },

  setSelect(state, { isChecked, application }) {
    let isApplicationAlreadyExist;
    for (const app of state.selectedApplications) {
      if (Object.values(app).includes(application.id)) {
        isApplicationAlreadyExist = true;
        break;
      }
    }

    if (!isChecked && isApplicationAlreadyExist) state.selectedApplications = state.selectedApplications.filter(
      app => app.id !== application.id
    );
    else state.selectedApplications.push(application);
  },

  setSelectAll(state) {
    state.selectedApplications = [];
    state.applicationList.forEach(item => state.selectedApplications.push(item));
  },

  setUnselectAll(state) {
    state.selectedApplications = [];
  },

  setTableHeadersOptions(state, payload) {
    state.tableHeadersOptions = [...payload];
  },

  setCarPoint(state, payload) {
    state.carPoint = payload;
  },

  setOrderPoint(state, payload) {
    state.orderPoint = payload;
  },

  setCouplingOptions(state, payload) {
    state.couplingOptions = payload;
  },

  setApplicationsQuantity(state, payload) {
    state.applicationsQuantity = payload;
  },

  setTodoApplicationsQuantity(state, payload) {
    state.todoApplicationsQuantity = payload;
  },

  setTsApplicationsQuantity(state, payload) {
    state.tsApplicationsQuantity = payload;
  },

  saveLastOrdersFilter(state, payload) {
    try {
      if (payload) {
        localStorage.setItem('last-orders-filter', JSON.stringify(payload));
      } else {
        localStorage.setItem('last-orders-filter', null);
      }
    } catch (error) {
      console.log(error);
    }
  },

  saveLastTripsFilter(state, payload) {
    try {
      if (payload) {
        localStorage.setItem('last-trips-filter', JSON.stringify(payload));
      } else {
        localStorage.setItem('last-trips-filter', null);
      }
    } catch (error) {
      console.log(error);
    }
  },

  setCopyActivator(state) {
    state.copyActivator = !state.copyActivator;
  },

  setCounters(state, payload) {
    state.counters = payload;
  },

  setQueryForAllFilters(state, payload) {
    state.queryAllFilters = payload;
  },

  setHelpStatusList(state, payload) {
    state.helpStatusList = payload;
  },

  setGeoLabels(state, payload) {
    state.geoStatusesLabels = payload;
  },

  setGeoStatusesList(state, payload) {
    state.geoStatusesList = payload;
  },
};

export const actions = {

  getLastOrdersFilter() {
    try {
      return JSON.parse(localStorage.getItem('last-orders-filter'));
    } catch (error) {
      console.log(error);
    }

    return null;
  },

  getLastTripsFilter() {
    try {
      return JSON.parse(localStorage.getItem('last-trips-filter'));
    } catch (error) {
      console.log(error);
    }

    return null;
  },

  async loadApplications({ commit }, payload) {
    commit('setIsTableLoading', true);
    try {
      // const { data } = await this.$axios.get('/orders/paired/list', {
      // params: {
      // 'sortBy[field]': 'operations.data_operation',
      // 'sortBy[ordering]': 'desc',
      // ...payload,
      // },
      // });
      // const { data } = await this.$axios.get('/orders/paired/list', {
      //   params: {
      //     // 'sortBy[field]': 'operations.data_operation',
      //     // 'sortBy[ordering]': 'desc',
      //     ...payload,
      //   },
      // });
      const { data } = await this.$axios.post('/orders-areal/paired/list', payload);
      const dataHelpStatus = await this.$axios.get('/orders/cartography/list');
      const dataGeoStatuses = await this.$axios.get('/cartography/geo_statuses');
      commit('setApplications', data.data);
      commit('setTablePagination', data.meta);
      commit('setHelpStatusList', dataHelpStatus.data.data);
      commit('setGeoStatusesList', dataGeoStatuses.data);
    } catch (error) {
      commit('setApplications', []);
      console.log(error);
    } finally {
      commit('setIsTableLoading', false);
    }
  },

  async loadApplicationsQuantity({ state, commit }, payload) {
    try {
      const { data } = await this.$axios.get('/orders/counters', { params: payload });
      commit('setApplicationsQuantity', data.total);
      commit('setTodoApplicationsQuantity', data['to-do']);
      commit('setTsApplicationsQuantity', data['vehicle-assigned']);
    } catch (error) {
      console.log(error);
    }
  },

  async loadHeadersOptions({ state, commit }) {
    commit('setIsTableLoading', true);

    try {
      const { data } = await this.$axios.get('orders/settings-table-orders');
      commit('setTableHeadersOptions', data.data.options);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setIsTableLoading', false);
    }
  },

  async getQueryLabels({ commit }, params) {
    try {
      const {
        data: { data: query },
      } = await this.$axios.get('/orders/get-labels-for-filter', { params });
      commit('setBackendQuery', query);
    } catch (error) {
      console.log(error);
    }
  },

  async loadLabels({ commit }) {
    try {
      const { data } = await this.$axios.get('/orders/labels');

      commit('setLabels', data);

      // There are 2 stores with labels in state. Need to be refactored
      commit('orders/orders-form/setLabels', data, { root: true });
    } catch (error) {
      console.log(error);
    }
  },

  async updateApplicationFromList({ commit }, params) {
    const { applicationId, sendToTransporter, data } = params;
    const endpoint = sendToTransporter
      ? `/orders/${applicationId}/send-to-transporter`
      : `/orders/${applicationId}/update-from-list`;
    const response = await this.$axios.put(endpoint, data);
    // if (response) {
    //   try {
    //     await this.$axios.post('cartography', { order_id: applicationId });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    commit('mergeApplication', response.data.data);
  },

  async updateHeadersOptions({ state, commit }, data) {
    try {
      const response = await this.$axios.post('orders/settings-table-orders', {
        options: data,
      });
      commit('setTableHeadersOptions', response.data.data.options);
      commit('refreshApplications', [...state.applicationList]);
    } catch (error) {
      console.log(error);
    }
  },

  async sortApplications({ state, commit }, params) {
    try {
      commit('setIsTableLoading', true);
      // const { data } = await this.$axios.get(`orders?sortBy[field]=${params.field}&sortBy[ordering]=${params.order}`);
      const { data } = await this.$axios.get(`orders/paired/list?sortBy[field]=${params.field}&sortBy[ordering]=${params.order}`);
      // console.log(response)
      // commit('setTableHeadersOptions', response.data.data.options);
      commit('setApplications', data.data);
      commit('setTablePagination', data.meta);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setIsTableLoading', false);
    }
  },

  async unionOrders(_, payload) {
    return this.$axios.post('/orders/union-orders-one-trip', {
      ...payload,
    });
  },

  async unionOrdersWithoutTrips(_, payload) {
    return this.$axios.post('/orders/union-orders-without-trips', {
      ...payload,
    });
  },

  async createBididngDrafts(_, payload) {
    return this.$axios.post('/trading-auctions/create-auction-drafts', {
      ...payload,
    });
  },

  sendCouplingOrders(_, payload) {
    this.$axios.post(`/cartography/${payload.orderId}`, {
      cartography_ids: payload.ids,
    });
  },

  async getGeolocationPoints({ state, commit }, params) {
    try {
      const data = await this.$axios.get(`/cartography/map/${params}`);
      commit('setCarPoint', data.data.car_point);
      commit('setOrderPoint', data.data.order_point);
    } catch (error) {
      console.log(error);
    }
  },

  async loadCouplingOptions({ state, commit }, params) {
    try {
      const data = await this.$axios.get(`/cartography/${params}`);
      commit('setCouplingOptions', data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async sortCouplingOptions({ state, commit }, params) {
    try {
      const data = await this.$axios.get(`/cartography/${params.id}?sortBy[field]=${params.field}&sortBy[ordering]=${params.ordering}`);
      commit('setCouplingOptions', data.data);
    } catch (error) {
      console.log(error);
    }
  },

  // async loadApplicationsCounters({commit}, payload) {
  //   try {
  //     const newCountersData = await this.$axios.get('/orders/counters/status', { params: payload });
  async loadApplicationsCounters({ commit }, payload) {
    try {
      // const newCountersData = await this.$axios
      //  .get('/orders/counters/status', { params: payload });
      const newCountersData = await this.$axios.post('/orders-areal/counters/status', payload);
      commit('setCounters', newCountersData.data);
    } catch (error) {
      console.log(error);
    }
  },

  async loadGeoStatusesLabels({commit}) {
    try {
      const data = await this.$axios.get('cartography/settings/trip/labels');
      commit('setGeoLabels', data.data);
    }
    catch (error) {
      console.log(error);
    }
  },

  async loadGeoStatuses({commit}) {
    try {
      const data = await this.$axios.get('/api/cartography/geo_statuses');
      commit('setGeoStatusesList', data);
    } catch (error) {
      console.log(error);
    }
  },
  // async loadHelpStatuses({ commit }) {
  //   try {
  //     const data = await this.$axios.get('/orders/cartography/list');
  //     commit('setHelpStatusList', data.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
};
