<template>
  <component
    :is="tagName"
    class="t-heading"
    :class="{
      't-heading--inline': inline,
      't-heading--subheading': subheading,
      't-heading--nowrap': !multirow,
    }"
    :title="isTitleShown ? title : undefined"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'THeading',

  props: {
    inline: {
      type: Boolean,
      default: false,
    },

    tagName: {
      type: String,
      default: 'h1',
    },

    subheading: {
      type: Boolean,
      default: false,
    },

    multirow: {
      type: Boolean,
      default: false,
    },

    isTitleShown: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    title() {
      return this.$slots?.default?.[0]?.text?.trim() ?? '';
    },
  },
};
</script>

<style lang="scss">
.t-heading {
  margin-bottom: 46px;
  font-size: 2.25rem;
  line-height: 1.11;
  font-weight: 300;

  &--inline {
    display: inline;
  }

  &--subheading {
    font-weight: 500;
  }

  &--nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
