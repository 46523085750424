// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.t-checkbox{display:inline-flex}.t-checkbox:hover .t-checkbox__image-wrap{border-color:#a8b7cf}.t-checkbox--disabled,.t-checkbox--disabled .t-checkbox__wrapper{cursor:default}.t-checkbox--disabled .t-checkbox__image-wrap{background-color:#f1f6fe}.t-checkbox--disabled:hover .t-checkbox__image-wrap{border-color:#c9d2e1}.t-checkbox--disabled.t-checkbox--indeterminate .t-checkbox__image-wrap:after{background-color:#b5c3db}.t-checkbox--disabled .t-checkbox__image{stroke:#347af0}.t-checkbox--indeterminate .t-checkbox__image-wrap:after{width:8px;height:8px;position:absolute;content:\"\";background-color:#347af0;border-radius:1px}.t-checkbox__wrapper{display:inline-flex;cursor:pointer}.t-checkbox__input{display:none}.t-checkbox__label{font:400 14px/18px \"Graphik LCG\";-webkit-user-select:none;-moz-user-select:none;user-select:none;margin-left:10px}.t-checkbox__image-wrap{position:relative;display:flex;align-items:center;justify-content:center;width:16px;height:16px;flex:none;border:1px solid #c9d2e1;border-radius:2px}.t-checkbox__image{width:16px;height:16px;opacity:0;transition:opacity .2s ease-in-out}.t-checkbox--checked .t-checkbox__image{opacity:1}.t-checkbox--checked .t-checkbox__image-wrap{border:1px solid #347af0}.t-checkbox--checked:hover .t-checkbox__image-wrap{border-color:#347af0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
