import { render, staticRenderFns } from "./AppEntry.vue?vue&type=template&id=588f925e&"
import script from "./AppEntry.vue?vue&type=script&lang=js&"
export * from "./AppEntry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!./node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports