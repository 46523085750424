export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },

    xSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    medium() {
      return Boolean(
        !this.xSmall
        && !this.small
        && !this.large
      );
    },

    sizeableClasses() {
      return {
        't-size--x-small': this.xSmall,
        't-size--small': this.small,
        't-size--default': this.medium,
        't-size--large': this.large,
      };
    },

    fontSizeableClasses() {
      return {
        't-font--x-small': this.xSmall,
        't-font--small': this.small,
        't-font--default': this.medium,
        't-font--large': this.large,
      };
    },
  },
};
