import moment from 'moment';
import _ from 'lodash';
import { parsePreviewFileLinks, formatCarTrailerNumber, formatPhone } from '~/assets/js/utils';
import { TRIP_STATUSES_ICONS } from '~/assets/js/constants';

function transformPayments(payments, rootGetters) {
  const isTransporter = rootGetters['main/isTransporter'];
  const currentUserCompanyId = rootGetters['main/getCurrentUser'].active_company_id;

  const flatInvoices = payments.reduce((acc, current) => {
    const withTrips = current.trips.map(trip => ({
      ...current,
      ...trip,
      id: current.id,
    }));
    return [...acc, ...withTrips];
  }, []).filter(trip => {
    if (!isTransporter) {
      return true;
    }
    return trip.transporter.id === currentUserCompanyId;
  });

  return flatInvoices.reduce((acc, curr) => {
    let transporterName = curr.transporter.name;
    const transporterRoleLabel = curr.transporter.roles[0].label;

    transporterName = `${transporterRoleLabel} ${transporterName}`;

    if (acc[transporterName]) {
      !acc[transporterName].some(({ id }) => id === curr.id)
        && acc[transporterName].push(
          {
            ...curr,
            created_at: moment(curr.created_at).format('DD.MM.YYYY'),
          },
        );
    } else {
      return {
        ...acc,
        [transporterName]: [{
          ...curr,
          created_at: moment(curr.created_at).format('DD.MM.YYYY'),
        }],
      };
    }

    return acc;
  }, []);
}

const transformTrip = (trip, { statuses, transportationTypes, orderTypes, containerStatuses }) => ({
  ...trip,
  driver: _.isEmpty(trip.driver)
    ? null
    : { ...trip.driver, name: `${trip.driver.full_name}, ${formatPhone(trip.driver.phone)}` },
  status: trip.status && statuses.find(status => status.id === trip.status),
  transportation_type: trip.transportation_type && transportationTypes.find(transportation => transportation.id === (trip.transportation_type)),
  orders: trip?.orders?.length && trip.orders.map(order => ({
    ...order,
    type: orderTypes.find(type => type.id === (order.type)),
    container: order.container ? {
      ...order.container,
      id: order.container?.id || null,
      number: order.container?.number || null,
      status: order.container?.status && containerStatuses.find(container => container.id === (order.container.status)) || null,
    } : null,
  })),
});

export const state = () => ({
  drivers: [],
  cars: [],
  trailers: [],
  platforms: [],
  locations: [],
  timeToFill: 0,
  transportationModules: [],
  trips: [],
  trip: {},
  labels: {},
  media: {},
  tripLabels: {},
  isCreateTrip: false,
  history: [],
  invoices: [],
  transactionsCustomer: [],
  transactionsForwarder: [],
  transactionsTransporter: [],
  acts: [],
  query: {},
  modelName: '',
  tempMediaModelName: '',
  tempMediaModelId: null,
  tripStatus: null,
});

export const getters = {
  GET_DRIVERS: state => state.drivers && state.drivers.map(driver => ({
    id: driver.id,
    name: driver.profile.fullName,
    phone: driver.phone,
  })),
  GET_TRANSPORT: state => (state.cars
    && state.cars.map(cur => ({
      ...cur,
      name: `${formatCarTrailerNumber(cur.number)} ${cur.trailer_types.length > 0
        ? cur.trailer_types[0].name
        : ''}`,
    })))
    || [],
  GET_TRAILERS: state => (state.trailers
    && state.trailers.map(cur => ({
      ...cur,
      name: `${formatCarTrailerNumber(cur.number)} ${cur.trailer_types.length > 0
        ? cur.trailer_types[0].name
        : ''}`,
    })))
    || [],
  GET_TIME_TO_FILL: state => state.timeToFill,
  GET_MODULES: state => state.transportationModules,
  GET_TRIPS: state => state.trips,
  // eslint-disable-next-line no-shadow
  GET_GROUPED_TRIPS: (state, getters, rootState, rootGetters) => (Array.isArray(state.trips) ? _.flatten(state.trips) : [])
    .map(cur => {
      // eslint-disable-next-line camelcase
      const { loading_date, loading_time_from } = rootGetters['orders/orders-form/GET_FORM_DATA'];

      return {
        ...cur,
        // eslint-disable-next-line camelcase
        isOutdated: moment(`${loading_date} ${loading_time_from}`, 'YYYY-MM-DD HH-mm').isBefore(moment().add(state.timeToFill, 'hours')),
      };
    }),
  GET_IS_CREATE_TRIP: state => state.isCreateTrip,
  GET_TRIP_LABELS: state => state.tripLabels,
  GET_TRIP_HISTORY: state => state.history,
  GET_GROUPED_INVOICES: (state, getters, rootState, rootGetters) => transformPayments(state.invoices || [], rootGetters),
  GET_GROUPED_ACTS: (state, getters, rootState, rootGetters) => transformPayments(state.acts || [], rootGetters),
  GET_QUERY: state => state.query,
  GET_TRANSACTIONS_WITH_CUSTOMER: state => state.transactionsCustomer || [],
  GET_TRANSACTIONS_WITH_FORWARDER: state => state.transactionsForwarder || [],
  GET_TRANSACTIONS_WITH_TRANSPORTER: state => state.transactionsTransporter || [],
  GET_TEMP_MEDIA_MODEL_NAME: state => state.tempMediaModelName,
  GET_TEMP_MEDIA_MODEL_ID: state => state.tempMediaModelId,
  GET_TRIP: (state, getters, _, rootGetters) => transformTrip(state.trip, {
    statuses: getters.GET_STATUSES,
    transportationTypes: rootGetters['orders/orders-form/GET_TRANSPORTATION_TYPES'],
    orderTypes: rootGetters['orders/orders-form/GET_ORDER_TYPES'],
    containerStatuses: rootGetters['orders/orders-form/GET_CONTAINER_STATUSES'],
  }),
  GET_STATUSES: state => state.labels?.trip?.statuses ?? [],
  GET_DOCUMENTS_LABEL: state => state.labels?.trip?.webMedia ?? [],
  GET_MEDIA: state => state.media,
  GET_MODEL_NAME: state => state.modelName,
  GET_CURRENT_STATUS: state => state.tripStatus,
};

export const actions = {
  // eslint-disable-next-line camelcase
  LOAD_TRANSPORTER_DATA({ commit, rootGetters }, { orderId, moduleId }) {
    const isTransporter = rootGetters['main/isTransporter'];
    const ORDER_SUFFIX_PATH = isTransporter
      ? `/${moduleId}`
      : '';

    return this.$axios.get(`orders/${orderId}/data-for-trip-creation${ORDER_SUFFIX_PATH}`)
      .then(({ data }) => {
        commit('setTripData', data);
        commit('setTransportationModules', data.transportation_modules);
        commit('setTripSetting', data.setting);
        commit('setIsCreateTrip', true);
      })
      .catch(error => {
        console.log(error);
      });
  },

  LOAD_TRIP_STATUS({ commit }, tripId) {
    return this.$axios.get(`/trips/get-trip-status/${tripId}`)
      .then(({ data }) => {
        commit('setCurrentStatus', data);
      }).catch(e => console.log(e));
  },

  // eslint-disable-next-line camelcase
  LOAD_TRANSPORTER_STAFF({ commit }, transporter_id) {
    return this.$axios.get('/transporters/staff', {
      params: {
        transporter_id,
      },
    }).then(({ data }) => {
      commit('setTripData', data.data);
    });
  },

  LOAD_CARS({ commit }, params) {
    return this.$axios.get('/order/search/car?type=1', { params })
      .then(({ data }) => {
        commit('setCars', data.data);
      });
  },

  LOAD_TRAILERS({ commit }, params) {
    return this.$axios.get('/order/search/car?type=2', { params })
      .then(({ data }) => {
        commit('setTrailers', data.data);
      });
  },

  LOAD_DRIVERS({ commit }, params) {
    return this.$axios.get('/order/search/driver', { params })
      .then(({ data }) => {
        commit('setDrivers', data.data);
      });
  },

  LOAD_LABELS({ commit }) {
    return this.$axios.get('/trips/labels')
      .then(({ data }) => {
        commit('setLabels', data.labels);
        commit('setModelName', data.modelName);
      }).catch(e => console.log(e));
  },

  STORE_TRIP({ commit, dispatch }, payload) {
    return this.$axios.post('/trips', { ...payload });
  },

  APPLY_FOR_BID(_, payload) {
    return this.$axios.post('/auctions/contract', { ...payload });
  },

  SEND_CHECK({ commit, dispatch }, data) {
    return this.$axios.post('/invoices/create', { ...data });
  },

  SEND_ACT({ commit, dispatch }, data) {
    return this.$axios.post('/certificates/create', { ...data });
  },

  LOAD_TRIPS({ commit }, { orderId, moduleId, with_child_orders = 0, ...params }) {
    return this.$axios.get(`/orders/${orderId}/trips`, {
      params: {
        ...params,
        orderId,
        module_id: moduleId,
        with_child_orders,
      },
    })
      .then(({ data }) => {
        commit('setTrips', data);
      }).catch(e => console.log(e));
  },

  async LOAD_TRIP({ commit }, payload) {
    try {
      commit('setIsCreateTrip', false);
      const { data: { data: { cars, drivers, locations, trailers, platforms, media, trip } } } = await this.$axios.get(`/trips/${payload}`);

      commit('setCars', cars);
      commit('setDrivers', drivers);
      commit('setLocations', locations);
      commit('setTrailers', trailers);
      commit('setPlatforms', platforms);
      commit('setMedia', media);
      commit('setTrip', trip);
    } catch (error) {
      console.log(error);
    }
  },

  async DELETE_ORDER_FROM_TRIP(_, payload) {
    return this.$axios.post('/orders/disunion', {
      ...payload,
    });
  },

  LOAD_TRIP_DATA({ commit, dispatch }, params) {
    return this.$axios.get('/trips/create', { params }).then(({ data }) => data).catch(e => console.log(e));
  },

  UPDATE_TRIP({ commit, dispatch }, payload) {
    return this.$axios.put(`/trips/${payload.id}`, { ...payload });
  },

  APPROVE_TRIP({ commit, dispatch }, payload) {
    return this.$axios.post(`/trips/${payload.id}/approve-trip`, { ...payload });
  },

  REJECT_TRIP({ commit, dispatch }, payload) {
    return this.$axios.post(`/trips/${payload.id}/cancel-trip`);
  },

  CHECK_DRIVER({ commit, dispatch }, params) {
    return this.$axios.get('/trips/check-driver', { params });
  },

  CHECK_CAR_AND_TRAILER(ctx, params) {
    return this.$axios.get('trips/check-car-trailer', { params });
  },

  LOAD_TRIP_LABELS({ commit, dispatch }, payload) {
    return this.$axios.get('/trips/labels').then(({ data }) => {
      commit('setTripLabels', data);
      return data;
    }).catch(e => console.log(e));
  },

  LOAD_TRIP_HISTORY({ commit }, id) {
    return this.$axios.get(`/trips/${id}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);

        return data.history;
      })
      .catch(e => console.log(e));
  },

  ADD_COMMENT({ commit }, { modelName, modelId, comment }) {
    return this.$axios.post('/comments', { modelName, modelId, comment });
  },

  LOAD_INVOICES({ commit, dispatch }, payload) {
    return this.$axios.get(`/invoices/index/${payload}`)
      .then(({ data }) => {
        commit('setInvoices', data.invoices);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async REMOVE_INVOICE({ commit, dispatch }, { id, orderId }) {
    await this.$axios.post('/invoices/delete', { id });
    await dispatch('LOAD_INVOICES', orderId);
  },

  LOAD_ACTS({ commit, dispatch }, payload) {
    return this.$axios.get(`/certificates/index/${payload}`).then(({ data }) => {
      commit('setActs', data.certificates);
    });
  },

  async REMOVE_ACT({ commit, dispatch }, { id, orderId }) {
    await this.$axios.post('/certificates/delete', { id });
    await dispatch('LOAD_ACTS', orderId);
  },

  SAVE_QUERY({ commit, dispatch }, payload) {
    commit('setQuery', payload);
  },

  CREATE_TRANSACTION({ commit, dispatch }, { orderId, data }) {
    return this.$axios.post(`/orders/${orderId}/transactions`, data);
  },

  UPDATE_TRANSACTION({ commit, dispatch }, { id, data }) {
    return this.$axios.put(`/transactions/${id}`, data);
  },

  DELETE_TRANSACTION({ commit, dispatch }, transactionId) {
    return this.$axios.delete(`/transactions/${transactionId}`);
  },

  LOAD_TRANSACTIONS({ commit, dispatch }, orderId) {
    return this.$axios.get(`/orders/${orderId}/transactions`)
      .then(({ data }) => {
        commit('setTransactionsCustomer', data.transactions?.withCustomer);
        commit('setTransactionsTransporter', data.transactions?.withTransporters);
        commit('setTransactionsForwarder', data.transactions?.withForwarder);
      });
  },

  async GET_TEMP_MEDIA({ commit }) {
    await this.$axios.get('/media/temp').then(({ data }) => {
      commit('setTempMedia', data);
    });
  },
};

export const mutations = {
  setTripData(state, payload) {
    state.drivers = payload.drivers;
    state.cars = payload.cars;
    state.trailers = payload.trailers;
  },

  setTransportationModules(state, payload) {
    state.transportationModules = payload;
  },

  setCars(state, payload) {
    state.cars = payload;
  },

  setTrailers(state, payload) {
    state.trailers = payload;
  },

  setDrivers(state, payload) {
    state.drivers = payload;
  },

  setLocations(state, payload) {
    state.locations = payload;
  },

  setPlatforms(state, payload) {
    state.platforms = payload;
  },

  setMedia(state, {
    driver_files_count,
    act_party_transfer_docs,
    power_of_attorney_docs,
    bill_of_lading_docs,
    seal_docs,
    railway_docs,
    other_docs,
  }) {
    state.media = {
      driver_files_count,
      act_party_transfer_docs: parsePreviewFileLinks(act_party_transfer_docs),
      power_of_attorney_docs: parsePreviewFileLinks(power_of_attorney_docs),
      bill_of_lading_docs: parsePreviewFileLinks(bill_of_lading_docs),
      seal_docs: parsePreviewFileLinks(seal_docs),
      railway_docs: parsePreviewFileLinks(railway_docs),
      other_docs: parsePreviewFileLinks(other_docs),
    };
  },

  setTrip(state, payload) {
    state.trip = payload;
  },

  setTripSetting(state, payload) {
    state.timeToFill = payload;
  },

  setTrips(state, payload) {
    if (Array.isArray(payload.data)) {
      state.trips = payload.data;
    } else {
      state.trips = Object.values(payload.data);
    }
  },

  setLabels(state, { trip, ...otherLabels }) {
    state.labels = {
      trip: {
        ...trip,
        statuses: Object.entries(trip.statuses).map(([id, name]) => {
          const currentStatus = Object.values(TRIP_STATUSES_ICONS).find(icon => icon.id === Number(id));

          return {
            name,
            id: Number(id),
            icon: currentStatus?.icon,
            color: currentStatus?.color,
          };
        }),
        webMedia: Object.values(trip.webMedia),
      },
      ...otherLabels,
    };
  },

  setModelName(state, modelName) {
    state.modelName = modelName;
  },

  addDriver(state, payload) {
    state.drivers.push(payload);
  },

  addCar(state, payload) {
    state.cars.push(payload);
  },

  setTripLabels(state, payload) {
    state.tripLabels = {
      ...payload.labels,
      ...payload.labels.trip,
      modelName: payload.modelName,
    };
  },

  setIsCreateTrip(state, payload) {
    state.isCreateTrip = payload;
  },

  setHistory(state, payload) {
    state.history = payload;
  },

  setInvoices(state, payload) {
    state.invoices = payload;
  },

  setActs(state, payload) {
    state.acts = payload;
  },

  setQuery(state, payload) {
    state.query = payload;
  },

  setTransactionsCustomer(state, payload) {
    state.transactionsCustomer = payload;
  },

  setTransactionsTransporter(state, payload) {
    state.transactionsTransporter = payload;
  },

  setTransactionsForwarder(state, payload) {
    state.transactionsForwarder = payload;
  },

  setTempMedia(state, payload) {
    state.tempMediaModelName = payload.tempMediaModelName;
    state.tempMediaModelId = payload.tempMedia.id;
  },

  setCurrentStatus(state, payload) {
    state.tripStatus = payload;
  },
};
