<template>
  <div class="t-status">
    <div
      class="t-status__icon"
      :style="{ backgroundColor: color }"
    >
      <TIconSvg
        :name="iconName"
        width="16"
        height="16"
        :view-box="viewBox"
        fill="#fff"
        stroke="#fff"
      />
    </div>
    <div class="t-status__text">
      <span>{{label}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    viewBox: {
      type: String,
      default: '0 0 16 16',
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/_variables";

$border-raduis: 8px;

.t-status {
  display: inline-flex;
  align-items: stretch;
  border-radius: $border-raduis;
  background-color: $gray-dark-1;
  height: 24px;

  &__icon {
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: $border-raduis;
    border-bottom-left-radius: $border-raduis;
  }

  &__text {
    padding-left: 8px;
    padding-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    white-space: nowrap;
    font: $t4-regular;
  }
}
</style>
