var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disabled ? {
    content: _vm.tooltipHint,
    trigger: 'hover',
    delay: { show: 100, hide: 200 },
    autoHide: true,
  } : null),expression:"disabled ? {\n    content: tooltipHint,\n    trigger: 'hover',\n    delay: { show: 100, hide: 200 },\n    autoHide: true,\n  } : null"}],staticClass:"t-field",class:{
    't-field--no-label': !_vm.hasLabel,
    't-field--loading': _vm.loading,
    't-field--focused-loading': _vm.canEditLoading && _vm.loading,
    't-field--active': _vm.isActive || _vm.value || _vm.value === 0,
    't-field--actions': _vm.hasActions
  }},[_c('div',{class:[!_vm.iconPrepend ? 't-field__field-wrapper' : 'prepend-wrapper']},[(_vm.iconPrepend)?_c('div',[_vm._t("start-adornment")],2):_vm._e(),_vm._v(" "),_c(_vm.multiline ? 'textarea' : 'input',{ref:"inputComponentRef",tag:"Component",class:Object.assign({}, {'t-field__field': !_vm.iconPrepend,
        't-field__field--error': _vm.hasErrors,
        't-field__field--success': !_vm.hasErrors && _vm.hasSuccessMessages,
        't-field__field--multiline': _vm.multiline,
        't-field__field--auto-expanded': _vm.isAutoExpanded,
        't-field__field--border-transparent': _vm.borderTransparent,
        't-field__field prepend-icon-input': _vm.iconPrepend},
        _vm.sizeableClasses),attrs:{"id":_vm.id,"type":_vm.multiline ? undefined : _vm.type,"rows":_vm.multiline ? _vm.rows : undefined,"placeholder":_vm.isActive || !_vm.label ? _vm.placeholder : '',"required":_vm.required,"min":_vm.min,"max":_vm.max,"step":_vm.step || 1,"name":_vm.name,"maxlength":_vm.maxLength,"disabled":_vm.isDisabled,"autocomplete":_vm.multiline ? undefined : _vm.autocomplete,"readonly":_vm.readOnly},domProps:{"value":_vm.value},on:{"blur":function($event){_vm.$emit('blur'), _vm.isActive = false},"focus":function($event){_vm.$emit('focus'), _vm.isActive = true},"input":_vm.handleInput,"change":function($event){return _vm.$emit('change', $event.target.value)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event)},"keyup":function($event){return _vm.$emit('keyup', $event.target.value)}}}),_vm._v(" "),(_vm.hasLabel)?_c('span',{staticClass:"t-field__label t-field__label-input",class:Object.assign({}, {'t-field__label--error': _vm.hasErrors,
        't-field__label--success': !_vm.hasErrors && _vm.hasSuccessMessages,
        required: _vm.required},
        _vm.fontSizeableClasses)},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"t-field__actions"},[(_vm.calendar)?_c('TIconSvg',{staticClass:"t-datepicker__icon",attrs:{"name":"calendar","stroke":_vm.disabled ? _vm.$options.colors.GRAY_LIGHT : _vm.$options.colors.BLUE_LIGHT,"view-box":"0 0 24 24","width":"24","height":"24"},on:{"click":_vm.openCal}}):_vm._e(),_vm._v(" "),_c('v-fade-transition',[(_vm.hasClearValue && _vm.value && !(_vm.disabled || _vm.loading))?_c('TIconSvg',{staticClass:"s-datepicker__clear",attrs:{"name":"close","view-box":"0 0 24 24","height":"24","width":"24","stroke":_vm.$options.colors.GRAY_DARK_2,"fill":_vm.$options.GRAY_DARK_1},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('input', null)}}}):_vm._e()],1),_vm._v(" "),(_vm.hasErrors)?_c('div',{staticClass:"t-field__actions-icon"},[(_vm.hasErrors && !_vm.hasToast)?_c('TIconSvg',{attrs:{"name":'danger-triangle',"width":24,"height":24,"view-box":'0 0 24 24',"fill":"none","stroke":_vm.$options.RED_LIGHT}}):_vm._e()],1):_vm._e(),_vm._v(" "),_vm._t("actions")],2)],1),_vm._v(" "),(_vm.hasErrors && !_vm.hasToast && !_vm.dangerCode)?_c('div',{staticClass:"t-field__hint t-field__hint--error j-error"},[_vm._v("\n    "+_vm._s(_vm.errorMessages[0])+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.hasErrors)?_vm._t("toast"):_vm._e(),_vm._v(" "),(!_vm.hasErrors && _vm.hasSuccessMessages)?_c('div',{staticClass:"t-field__hint t-field__hint--success-message"},[_vm._v("\n    "+_vm._s(Array.isArray(_vm.successMessages) ? _vm.successMessages[0] : _vm.successMessages)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.hint && !_vm.hasErrors && !_vm.hasSuccessMessages)?_c('div',{staticClass:"t-field__hint"},[_vm._v("\n    "+_vm._s(_vm.hint)+"\n  ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }