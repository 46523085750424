import { parsePreviewFileLinks } from '~/assets/js/utils';

export const state = () => ({
  isLoadingBtn: false,
  additionalData: {
    greenStatements: false,
    yellowStatements: false,
    redStatements: false,
  },
  isFormRefreshing: false,
  history: [],
  agreements: [],
  contracts: [],
  companyData: {},
  companyLabels: {},
  isNonResident: false,
  isUnsavedPopupOpen: false,
  forwarderAccreditationDocs: [],
  currentRegistrationStep: '',
});

export const getters = {
  GET_BTN_LOADING: state => state.isLoadingBtn,
  GET_ADDITIONAL_DATA: state => state.additionalData,
  getHistory: state => state.history,
  getCompanyData: state => state.companyData,
  getIsFormRefreshing: state => state.isFormRefreshing,
  getIsNonResident: state => state.isNonResident,
  getIsCompaniesFormChanged: state => Object.values(state.isCompaniesFormChanged).some(val => val === true),
  getIsUnsavedPopupOpen: state => state.isUnsavedPopupOpen,
  getCompanyOrganizationStatuses: state => state.companyLabels.labels.company.organizationStatuses,
  getForwarderAccreditationDocs: state => state.forwarderAccreditationDocs,
};

export const actions = {
  GET_COMPANY_LABELS({ commit }, { isProfilePage } = {}) {
    const rightPath = isProfilePage ? '/companyprofile/labels' : '/companies/labels';

    return this.$axios.get(rightPath)
      .then(({ data }) => {
        commit('setCompanyLabels', data);
      });
  },

  GET_PROFILE_DATA({ commit }, { id, isProfilePage = false }) {
    const rightPath = isProfilePage ? `companyprofile/${id}` : `companies/${id}`;

    return this.$axios.get(rightPath)
      .then(({ data }) => {
        commit('setCompanyData', data);
        return data;
      });
  },

  GET_PROFILE_HISTORY({ commit }, { id, isProfilePage = false }) {
    const rightPath = isProfilePage
      ? `companyprofile/${id}/history`
      : `companies/${id}/history`;

    return this.$axios.get(rightPath)
      .then(({ data }) => {
        commit('setHistory', data.history);
        return data;
      }).catch(err => {
        console.log(err);
      });
  },

  GET_CUSTOMER_PROFILE_DATA({ commit }, { customerId, clientId }) {
    return this.$axios.get(`/customers/${customerId}/${clientId}`)
      .then(({ data }) => {
        commit('setCompanyData', data);
        return data;
      });
  },

  GET_PROFILE_DATA_BY_INN(_, inn) {
    return this.$axios.get('companies/focus-data', { params: { inn } })
      .then(({ data }) => data);
  },

  UPDATE_PROFILE_DATA({ commit }, { data, isProfilePage }) {
    const rightPath = isProfilePage ? `companyprofile/${data.id}` : `companies/${data.id}`;

    commit('setBtnLoading', true);
    return this.$axios.put(rightPath, { ...data })
      .then(({ data }) => {
        commit('setBtnLoading', false);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  COMPANY_COMFIRMATION({ commit }, payload) {
    return this.$axios.post('/companies/confirm', {
      child_company_id: payload.child_company_id,
      parent_company_id: payload.parent_company_id,
      code: payload.code,
    })
      .then(({ data }) => data);
  },

  CREATE_COMPANY({ commit }, payload) {
    commit('setBtnLoading', true);
    return this.$axios.post('/companies', { ...payload })
      .then(({ data }) => {
        commit('setBtnLoading', false);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  CREATE_CUSTOMER({ commit }, payload) {
    commit('setBtnLoading', true);
    return this.$axios.post('/customers/store', { ...payload })
      .then(({ data }) => {
        commit('setBtnLoading', false);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  UPDATE_CUSTOMER({ commit }, payload) {
    commit('setBtnLoading', true);
    return this.$axios.post('/customers/update', { ...payload })
      .then(({ data }) => {
        commit('setBtnLoading', false);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  CHECK_TICKER(_, { ticker, inn }) {
    return this.$axios.get('companies/check-ticker', {
      params: {
        ticker,
        inn,
      },
    });
  },

  CHECK_INN(_, { inn, kpp, role = null }) {
    return this.$axios.get('companies/details-for-creation', {
      params: {
        inn,
        kpp,
        role,
      },
    });
  },

  LOAD_ADDITIONAL_DATA({ commit }, { id, isProfilePage = false }) {
    const rightPath = isProfilePage
      ? `companyprofile/additional-data/${id}`
      : `companies/additional-data/${id}`;

    commit('setBtnLoading', true);
    return this.$axios.get(rightPath)
      .then(({ data }) => {
        commit('setBtnLoading', false);
        commit('setAdditionalData', data);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  loadForwarderAccreditationDocs({ commit }, { id, isProfilePage = false, query = {} }) {
    const rightPath = isProfilePage
      ? `companyprofile/${id}/forwarder-accreditation-tab`
      : `companies/${id}/forwarder-accreditation-tab`;

    return this.$axios.get(rightPath, { params: { ...query } })
      .then(({ data }) => {
        commit('setForwarderAccreditationDocs', data.accreditation_requirements);
        return data;
      }).catch(err => {
        console.log(err);
      });
  },

  async startRegistration() {
    try {
      await this.$axios.put('/companies/start-registration');
    } catch (error) {
      console.log(error);
    }
  },

  async getCurrentRegistrationStep({ commit }) {
    try {
      const { data: { registration_step } } = await this.$axios.get('companies/current-registration-step');
      commit('setCurrentRegistrationStep', registration_step);
    } catch (error) {
      console.log(error);
    }
  },

  async resetIsDataUpdated({ commit, getters }, params) {
    if (!getters.getCompanyData?.company?.is_data_updated) {
      return false;
    }
    try {
      const { data } = await this.$axios.put(`/companies/${params.id}/reset-is-data-updated`);
      commit('resetDataUpdated', false);
      return data?.success;
    } catch (error) {
      console.log(error);
    }
  },
};

export const mutations = {
  setIsFormRefreshing(state, payload) {
    state.isFormRefreshing = payload;
  },

  setForwarderAccreditationDocs(state, payload) {
    state.forwarderAccreditationDocs = payload;
  },

  setCompanyData(state, { company, media }) {
    state.companyData = {
      company,
      INN_docs: parsePreviewFileLinks(media.INN_docs),
      OGRN_docs: parsePreviewFileLinks(media.OGRN_docs),
      agreements_docs: parsePreviewFileLinks(media.agreements_docs),
      appointment_docs: parsePreviewFileLinks(media.appointment_docs),
      assurance_docs: parsePreviewFileLinks(media.assurance_docs),
      consent_docs: parsePreviewFileLinks(media.consent_docs),
      contract: parsePreviewFileLinks(media.contract),
      contract_protocol_disagreements: parsePreviewFileLinks(media.contract_protocol_disagreements),
      insurance_docs: parsePreviewFileLinks(media.insurance_docs),
      legal_accounts_docs: parsePreviewFileLinks(media.legal_accounts_docs),
      legal_additional_docs: parsePreviewFileLinks(media.legal_additional_docs),
      legal_bank_docs: parsePreviewFileLinks(media.legal_bank_docs),
      legal_charter_docs: parsePreviewFileLinks(media.legal_charter_docs),
      legal_entrepreneur_docs: parsePreviewFileLinks(media.legal_entrepreneur_docs),
      legal_extremist_docs: parsePreviewFileLinks(media.legal_extremist_docs),
      legal_passport_docs: parsePreviewFileLinks(media.legal_passport_docs),
      legal_vat_docs: parsePreviewFileLinks(media.legal_vat_docs),
      passport_main_spread_docs: parsePreviewFileLinks(media.passport_main_spread_docs),
      passport_registration_docs: parsePreviewFileLinks(media.passport_registration_docs),
      registration_docs: parsePreviewFileLinks(media.registration_docs),
      regulations_docs: parsePreviewFileLinks(media.regulations_docs),
      required_by_client_custom_docs: parsePreviewFileLinks(media.required_by_client_custom_docs),
      signatories_docs: parsePreviewFileLinks(media.signatories_docs),
    };
  },

  setCompanyLabels(state, payload) {
    state.companyLabels = payload;
  },

  setBtnLoading(state, payload) {
    state.isLoadingBtn = payload;
  },

  setAgreements(state, payload) {
    state.agreements = payload;
  },

  setContracts(state, payload) {
    state.contracts = payload;
  },

  setAdditionalData(state, [payload]) {
    let additionalData;
    const defaultAdditionslData = {
      greenStatements: false,
      yellowStatements: false,
      redStatements: false,
    };

    try {
      additionalData = payload.briefReport.summary;
    } catch (e) {
      additionalData = {};
    }

    Object.assign(defaultAdditionslData, additionalData);
    state.additionalData = defaultAdditionslData;
  },

  setHistory(state, payload) {
    state.history = payload;
  },

  updateLegalCheckCommentsCount(state, { type, value }) {
    state.companyData.company.legalCheckComments[type].count += value;
  },

  setIsNonResident(state, payload) {
    state.isNonResident = payload;
  },

  setIsUnsavedPopupOpen(state, payload) {
    state.isUnsavedPopupOpen = payload;
  },

  setCurrentRegistrationStep(state, payload) {
    state.currentRegistrationStep = payload;
  },

  resetDataUpdated(state, payload) {
    if (state.companyData?.company?.is_data_updated) {
      state.companyData.company.is_data_updated = payload;
    }
  },
};
