import { clone, isEqual, isEmpty } from 'chober';

function mapArrayValues(array) {
  return array.map(item => (typeof item === 'number' ? String(item) : item));
}

function transformValuesToString(rawQuery) {
  return Object.entries(rawQuery).reduce((acc, [key, value]) => {
    if (value === null) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return {
      ...acc,
      [key]: Array.isArray(value) ? mapArrayValues(value) : String(value),
    };
  }, {});
}

export default {
  methods: {
    async setQuery(queries = {}, options = { isSaveOld: false, isSaveHash: false }) {
      const params = { query: {}, hash: '' };
      if (options.isSaveHash) {
        params.hash = this.getHash();
      }

      if (options.isSaveOld) {
        params.query = this.getQuery();
      }

      Object.assign(params.query, transformValuesToString(queries));

      if (!isEqual(params.query, this.getQuery())) {
        try {
          await this.$router.replace(params);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }

      return Promise.resolve();
    },

    async removeQuery(queries) {
      const params = { query: {}, hash: '' };

      params.hash = this.getHash();
      params.query = this.getQuery();

      if (isEmpty(params.query)) {
        return;
      }

      queries.forEach(query => {
        delete params.query[query];
      });

      await this.$router.replace(params);
    },

    getQuery(options = { arrays: [] }) {
      const query = clone(this.$router.history.current.query);

      options.arrays.forEach(key => {
        const value = query[key];

        if (!value || !Array.isArray(value)) {
          query[key] = value ? [value] : [];
        }
      });

      return query;
    },

    getHash() {
      return this.$router.history.current.hash;
    },
  },
};
