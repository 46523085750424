// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.auth{width:100%;min-height:100vh;display:flex;background-color:#fff;overflow:hidden}.auth__col-left{margin-top:80px;margin-bottom:32px}.auth__left{padding-right:90px;height:100%}.auth .help,.auth__info,.auth__logo{padding-left:80px}.auth__logo{margin-bottom:45px}.auth__logo img{display:block}.auth__wrapper{display:flex;flex-direction:column;justify-content:space-between;height:88%}.auth__info{display:flex}.auth__info-title{font:500 24px/117% \"Graphik LCG\";padding-right:75px;max-width:670px}.auth__img{padding-left:12px;margin-bottom:50px}.auth__img img{display:block}.auth .statistic{display:flex;flex-direction:column;min-width:145px}.auth .statistic__count{font:500 46px/100% \"Graphik LCG\";color:#fe991f}.auth .statistic__transportation{font:400 14px/18px \"Graphik LCG\"}.auth__right{width:500px;padding-top:72px}.auth .t-field{max-width:420px}.auth .t-link{font:400 18px/156% \"Graphik LCG\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
