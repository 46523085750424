// Constants
import {
  RUSSIA_ISO_CODE,
  ADMIN_ROLE_SLUG,
  TRANSPORTER_ROLE_SLUG,
  CLIENT_ROLE_SLUG,
  FORWARDER_ROLE_SLUG,
  DISPATCHER_ROLE_SLUG,
} from '~/assets/js/constants';

export const state = () => (
  {
    currentUser: {},
    permissions: {},
    access: {},
    currentAccess: {},
    serverTime: '',
    availableCountries: [],
    ddServersList: [],
    counter: {},
    simplePassword: false,
  }
);

export const getters = {
  isAdmin: state => state.currentUser.roles
    && state.currentUser.roles.some(role => role.name === ADMIN_ROLE_SLUG),
  isClient: state => state.currentUser?.active_company?.roles
    .some(role => role.name === CLIENT_ROLE_SLUG) || false,
  isTransporter: state => state.currentUser?.active_company?.roles
    .some(role => role.name === TRANSPORTER_ROLE_SLUG) || false,
  isForwarder: state => state.currentUser?.active_company?.roles
    .some(role => role.name === FORWARDER_ROLE_SLUG) || false,
  isClientOrForwarder: state => state.currentUser?.active_company?.roles
    .some(role => role.name === CLIENT_ROLE_SLUG || role.name === FORWARDER_ROLE_SLUG)
    || false,
  isDispatcher: (state, getters) => state.currentUser?.roles
    // .some(role => role.name === DISPATCHER_ROLE_SLUG) || getters.isForwarder,
    ?.some(role => role.name === DISPATCHER_ROLE_SLUG) || getters.isForwarder,
  isTransporterOrForwarder: (_state, getters) => getters.isTransporter || getters.isForwarder,
  isUserCompanyRequiringAccreditation: store => store.currentUser.active_company?.is_new || false,
  isNonResident: store => store.currentUser.is_non_resident,
  getUserCompany: state => state.currentUser.active_company,
  getUserCompaniesList: state => state.currentUser.companies || [],
  getUserActiveCompanyId: state => state.currentUser.active_company_id,
  getCurrentAccess: state => state.currentAccess,
  getServerTime: state => state.serverTime,
  getAvailableCountries: state => state.availableCountries,
  getCountriesWithoutRussia: state => state.availableCountries
    .filter(country => country.country_id !== RUSSIA_ISO_CODE),
  getDdServersList: state => state.ddServersList,
  getCurrentUser: state => state.currentUser,
  getCounter: state => state.counter,
  getIsSimplePassword: state => state.simplePassword,
  isTransporterWithoutEmployee: state => !state.currentUser.profile.shortName,
  userId: state => state.currentUser.id,
};

function forrmattedPermissions(defaultPermission, userPermission) {
  return defaultPermission.reduce((acc, { id, section, action }) => {
    const currentPerm = userPermission.find(({ id: currentId }) => currentId === id);

    if (!currentPerm) return acc;

    if (acc[section]) {
      // eslint-disable-next-line no-param-reassign
      acc[section][action] = {
        isCheck: true,
        id: currentPerm.id,
      };
    } else {
      // eslint-disable-next-line no-param-reassign
      acc[section] = {};
      // eslint-disable-next-line no-param-reassign
      acc[section][action] = {
        isCheck: true,
        id: currentPerm.id,
      };
    }
    return acc;
  }, {});
}
export const mutations = {
  setInfo(state, data) {
    state.currentUser = data.currentUser;
    state.currentUser.roles = data.currentUser.roles?.length ? data.currentUser.roles : data.currentUser.active_company?.roles;
    state.permissions = data.permissions;
    state.serverTime = data.app_time;
    state.counter = data.counter;
    state.simplePassword = data.currentUser.simple_password;
  },

  setAvailableCountries(state, payload) {
    state.availableCountries = Object.values(payload).map(country => ({
      ...country,
      id: country.country_id,
    }));
  },

  createAccess(state, data) {
    const defaultAccess = data.menu_sections.reduce((acc, cur) => {
      if (cur.is_public) return acc;
      return {
        ...acc,
        [cur.key]: {
          label: cur.label,
          view: {
            isCheck: false,
            id: '',
          },
          edit: {
            isCheck: false,
            id: '',
          },
        },
      };
    }, {});

    data.permissions.forEach(perm => {
      if (!defaultAccess[perm.section]) return;
      defaultAccess[perm.section][perm.action].id = perm.id;
    });

    state.currentAccess = forrmattedPermissions(data.permissions, data.currentUser.permissions);

    state.access = defaultAccess;
  },

  setDdServers(state, payload) {
    state.ddServersList = payload;
  },
};

export const actions = {
  async getInfo({ commit }) {
    try {
      const { data } = await this.$axios.get('main/info');
      commit('setInfo', data);
      commit('setAvailableCountries', data.countries);
      commit('createAccess', data);
    } catch (error) {
      console.log(error);
    }
  },

  async getPublicInfo({ commit }) {
    try {
      const { data } = await this.$axios.get('info');

      commit('setAvailableCountries', data.countries);
    } catch (error) {
      console.log(error);
    }
  },

  async updateCurrentCompany(_, newCompanyId) {
    try {
      await this.$axios.put(
        'users/update-active-company',
        {
          active_company_id: newCompanyId,
        },
      );

      this.$router.push('/');
    } catch (error) {
      console.log(error);
    }
  },

  saveLocale({ state }, locale) {
    const { id } = state.currentUser;

    try {
      this.$axios.put(
        `users/${id}/update-locale`,
        { locale }
      );
    } catch (error) {
      console.log(error);
    }
  },

  async loadDdServers({ commit }) {
    try {
      const { data } = await this.$axios.get('/dd/get-servers-dd');

      commit('setDdServers', data);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  actions,
  state,
  mutations,
  getters,
};
