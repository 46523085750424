<template>
  <div class="addition-counter__wrap">
  <v-popover
    :trigger="trigger"
    :offset="offset"
    :placement="placement"
    popover-class="t-addition-counter-new"
    popover-inner-class="t-addition-counter-new__content"
    popover-arrow-class="t-addition-counter-new__arrow"
  >
    <span class="t-addition-counter-new__count">+{{count}}</span>
    <template
      slot="popover"
    >
      <slot />
    </template>
  </v-popover>
  </div>
</template>

<script>
export default {
  name: 'TAdditionCounterNew',

  props: {
    count: {
      type: Number,
      required: true,
    },

    trigger: {
      type: String,
      default: 'hover',
    },

    offset: {
      type: Number,
      default: 0,
    },

    placement: {
      type: String,
      default: 'bottom-end',
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-addition-counter-new {
  z-index: 999;
  margin-top: 2px !important;

  &__content {
    padding: 16px 82px 16px 16px;
    background: $white;
    box-sizing: border-box;
    border: 1px solid $gray-light-1;
    z-index: 999;

    div {
      display: grid;
      gap: 20px;
    }
  }

  &__count {
    font: $t5-semibold;
    color: $gray-dark-2;
    text-align: center;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 16px;
    padding: 3px 5px;
    border-radius: 5px;
    border: 1px solid $gray-dark-2;
    transition: 0.15s ease-in border-color;
    cursor: pointer;
    z-index: 999;

    &:hover {
      border: 1px solid lighten($gray-dark-2, 5%);
    }
  }

  &__arrow {
    display: none;
  }
}

//.addition-counter__wrap > .v-popover,
//.trigger {
//  display: inline-flex !important;
//}
</style>
