import Vue from 'vue';

import THeading from '~/components/Common/THeading';
import TPopup from '~/components/Common/TPopup';
import TPseudoLink from '~/components/Common/TPseudoLink';
import TFlex from '~/components/Common/TFlex';
import TCol from '~/components/Common/TCol';
import TIconSvg from '~/components/Common/TIconSvg';
import TInput from '~/components/Common/TInput';
import TInputProvider from '~/components/Common/TInputProvider';
import TTextarea from '~/components/Common/TTextarea';
import TButton from '~/components/Common/TButton';
import TPreloader from '~/components/Common/TPreloader';
import TButtonGroup from '~/components/Common/TButtonGroup';
import TCheckbox from '~/components/Common/TCheckbox';
import TSwitch from '~/components/Common/TSwitch';
import TUploader from '~/components/Common/TUploader';
import TDropdown from '~/components/Common/TDropdown';
import TLink from '~/components/Common/TLink';
import TMultiselect from '~/components/Common/TMultiselect';
import TMultiselectProvider from '~/components/Common/TMultiselectProvider';
import TTable from '~/components/Common/TTable';
import TPagination from '~/components/Common/TPagination';
import TSlider from '~/components/Common/TSlider';
import TInputSearch from '~/components/Common/TInputSearch';
import TDatepicker from '~/components/Common/TDatepicker';
import TRadio from '~/components/Common/TRadio';
import TToast from '~/components/Common/TToast';
import TTabs from '~/components/Common/TTabs';
import TTab from '~/components/Common/TTab';
import TIconSvg2 from '~/components/Common/TIconSvg2';
import TAdditionCounter from '~/components/Common/TAdditionCounter';
import TAdditionCounterNew from '~/components/Common/TAdditionCounterNew';
import TRating from '~/components/Common/TRating';
import TStatusSelect from '~/components/Common/TStatusSelect';
import TSpinner from '~/components/Common/TSpinner';
import TFilters from '~/components/Common/TFilters';
import TDivider from '~/components/Common/TDivider';
import TBadge from '~/components/Common/TBadge';
import TStatus from '~/components/Common/TStatus';
import TSort from '~/components/Common/TSort';
import TSlide from '~/components/Common/TSlide';

const components = {
  install(Vue) {
    Vue.component('THeading', THeading);
    Vue.component('TPopup', TPopup);
    Vue.component('TPseudoLink', TPseudoLink);
    Vue.component('TFlex', TFlex);
    Vue.component('TCol', TCol);
    Vue.component('TIconSvg', TIconSvg);
    Vue.component('TInput', TInput);
    Vue.component('TInputProvider', TInputProvider);
    Vue.component('TTextarea', TTextarea);
    Vue.component('TButton', TButton);
    Vue.component('TPreloader', TPreloader);
    Vue.component('TButtonGroup', TButtonGroup);
    Vue.component('TCheckbox', TCheckbox);
    Vue.component('TSwitch', TSwitch);
    Vue.component('TUploader', TUploader);
    Vue.component('TDropdown', TDropdown);
    Vue.component('TLink', TLink);
    Vue.component('TMultiselect', TMultiselect);
    Vue.component('TMultiselectProvider', TMultiselectProvider);
    Vue.component('TTable', TTable);
    Vue.component('TPagination', TPagination);
    Vue.component('TSlider', TSlider);
    Vue.component('TInputSearch', TInputSearch);
    Vue.component('TDatepicker', TDatepicker);
    Vue.component('TRadio', TRadio);
    Vue.component('TToast', TToast);
    Vue.component('TTabs', TTabs);
    Vue.component('TTab', TTab);
    Vue.component('TIconSvg2', TIconSvg2);
    Vue.component('TAdditionCounter', TAdditionCounter);
    Vue.component('TAdditionCounterNew', TAdditionCounterNew);
    Vue.component('TRating', TRating);
    Vue.component('TStatusSelect', TStatusSelect);
    Vue.component('TSpinner', TSpinner);
    Vue.component('TFilters', TFilters);
    Vue.component('TDivider', TDivider);
    Vue.component('TBadge', TBadge);
    Vue.component('TStatus', TStatus);
    Vue.component('TSort', TSort);
    Vue.component('TSlide', TSlide);
  },
};

Vue.use(components);
