<!--<template>-->
<!--  <div-->
<!--    :class="{-->
<!--      't-field&#45;&#45;no-label': !label,-->
<!--      't-field&#45;&#45;loading': loading,-->
<!--      't-datepicker&#45;&#45;has-warning': warning,-->
<!--      't-datepicker&#45;&#45;has-error': error,-->
<!--      't-datepicker&#45;&#45;has-value': value,-->
<!--      't-datepicker&#45;&#45;not-field': isNotField,-->
<!--      't-datepicker&#45;&#45;opened': isOpened,-->
<!--      't-datepicker&#45;&#45;disabled': disabled,-->
<!--      't-datepicker&#45;&#45;readonly': readonly,-->
<!--    }"-->
<!--    class="t-field t-datepicker"-->
<!--  >-->
<!--    <span-->
<!--      v-if="label"-->
<!--      :class="{-->
<!--        't-field__label&#45;&#45;error': hasErrors,-->
<!--        't-field__label&#45;&#45;success': !hasErrors && hasSuccessMessages,-->
<!--        required,-->
<!--      }"-->
<!--      class="t-field__label"-->
<!--    >-->
<!--      {{label}}-->
<!--    </span>-->
<!--    <div class="t-field__group t-datepicker__picker-wrapper">-->
<!--      <TButton-->
<!--        v-if="hasArrows"-->
<!--        class="t-datepicker__picker-arrow t-datepicker__picker-arrow&#45;&#45;left"-->
<!--        icon="arrow-left"-->
<!--        secondary-action-->
<!--        disable-lover-->
<!--        icon-width="7"-->
<!--        icon-height="12"-->
<!--        icon-view-box="0 0 7 12"-->
<!--        @click="setDay('prev')"-->
<!--      />-->
<!--      <TIconSvg-->
<!--        v-if="hasClearValue && value && !(disabled || readonly || loading)"-->
<!--        class="datepicker__clear"-->
<!--        name="close"-->
<!--        view-box="0 0 24 24"-->
<!--        height="24"-->
<!--        width="24"-->
<!--        :stroke="$options.colors.GRAY_DARK_2"-->
<!--        :fill="$options.GRAY_DARK_1"-->
<!--        @click.stop="$emit('input', null)"-->
<!--      />-->
<!--      <div-->
<!--        class="t-datepicker__input-wrapper"-->
<!--        :class="{-->
<!--          't-datepicker__input-wrapper&#45;&#45;error': hasErrors-->
<!--        }"-->
<!--      >-->
<!--        <div class="t-datepicker__icons">-->
<!--          <TIconSvg-->
<!--            v-if="hasErrors"-->
<!--            name="danger-triangle"-->
<!--            view-box="0 0 24 24"-->
<!--            fill="none"-->
<!--            :stroke="$options.colors.RED_LIGHT"-->
<!--            width="24"-->
<!--            height="24"-->
<!--            class="mr-2 t-datepicker__icon"-->
<!--          />-->
<!--          <TIconSvg-->
<!--            name="calendar"-->
<!--            :stroke="disabled ? $options.colors.GRAY_LIGHT : $options.colors.BLUE_LIGHT"-->
<!--            view-box="0 0 24 24"-->
<!--            width="24"-->
<!--            height="24"-->
<!--            class="t-datepicker__icon"-->
<!--          />-->
<!--        </div>-->
<!--        <Datepicker-->
<!--          ref="calendar"-->
<!--          v-model="localValue"-->
<!--          :config="localConfig"-->
<!--          :disabled="disabled || readonly || loading"-->
<!--          class="t-field t-datepicker__input"-->
<!--          :class="sizeableClasses"-->
<!--          @input="inputDate($event)"-->
<!--          @on-change="$emit('on-change', $event)"-->
<!--          @on-close="onCalendarClose"-->
<!--          @on-open="isOpened = true"-->
<!--        />-->
<!--        <span-->
<!--          class="t-datepicker__placeholder"-->
<!--          :class="{'t-datepicker__placeholder&#45;&#45;required': required}"-->
<!--        >-->
<!--          {{placeholderText}}-->
<!--        </span>-->
<!--      </div>-->
<!--      <TButton-->
<!--        v-if="hasArrows"-->
<!--        class="t-datepicker__picker-arrow t-datepicker__picker-arrow&#45;&#45;right"-->
<!--        icon="arrow-right"-->
<!--        secondary-action-->
<!--        disable-lover-->
<!--        icon-width="7"-->
<!--        icon-height="12"-->
<!--        icon-view-box="0 0 7 12"-->
<!--        @click="setDay('next')"-->
<!--      />-->
<!--      <div-->
<!--        v-if="warning || error"-->
<!--        class="t-datepicker__tooltip j-error"-->
<!--      >-->
<!--        <TIconSvg-->
<!--          v-tooltip="error || warning"-->
<!--          name="tooltip"-->
<!--          view-box="0 0 15 15"-->
<!--          class="ml-2 t-datepicker__tooltip-icon"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div-->
<!--      v-if="hasErrors && errorMessages[0]"-->
<!--      class="error-text j-error t-datepicker__error"-->
<!--    >-->
<!--      {{errorMessages[0]}}-->
<!--    </div>-->
<!--    <div-->
<!--      v-if="validateErrorMessage"-->
<!--      class="error-text j-error t-datepicker__error"-->
<!--    >-->
<!--      {{validateErrorMessage}}-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--// libs-->
<!--import moment from 'moment';-->
<!--// Utils-->
<!--import { isEmpty } from 'chober';-->
<!--// Mixins-->
<!--import sizes from '@/assets/js/mixins/sizes';-->
<!--// Constants-->
<!--import colors from '@/assets/scss/_variables.scss';-->
<!--// Components-->
<!--import Datepicker from 'vue-flatpickr-component';-->
<!--/* eslint-disable import/no-extraneous-dependencies */-->
<!--import 'flatpickr/dist/flatpickr.min.css';-->
<!--import { Russian } from 'flatpickr/dist/l10n/ru';-->
<!--import { Georgian } from 'flatpickr/dist/l10n/ka';-->
<!--import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';-->
<!--import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';-->
<!--/* eslint-enable import/no-extraneous-dependencies */-->

<!--const DEFAULT_CONFIG = {-->
<!--  altInput: true,-->
<!--  dateFormat: 'Y-m-d',-->
<!--  disableMobile: true,-->
<!--};-->

<!--const defaultMode = 'single';-->

<!--export default {-->
<!--  name: 'TDatepicker',-->

<!--  components: {-->
<!--    Datepicker,-->
<!--  },-->

<!--  mixins: [sizes],-->

<!--  colors,-->

<!--  props: {-->
<!--    value: {-->
<!--      type: [String, Array],-->
<!--      default: '',-->
<!--    },-->

<!--    placeholder: {-->
<!--      type: String,-->
<!--      default: '_default',-->
<!--    },-->

<!--    label: {-->
<!--      type: String,-->
<!--      default: '',-->
<!--    },-->

<!--    loading: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->

<!--    disabled: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->

<!--    readonly: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->

<!--    allowInput: {-->
<!--      type: Boolean,-->
<!--      default: true,-->
<!--    },-->

<!--    warning: {-->
<!--      type: String,-->
<!--      default: '',-->
<!--    },-->

<!--    error: {-->
<!--      type: String,-->
<!--      default: '',-->
<!--    },-->

<!--    minDate: {-->
<!--      type: String,-->
<!--      default: '',-->
<!--    },-->

<!--    maxDate: {-->
<!--      type: String,-->
<!--      default: '',-->
<!--    },-->

<!--    defaultDate: {-->
<!--      type: String,-->
<!--      default: '',-->
<!--    },-->

<!--    altFormat: {-->
<!--      type: String,-->
<!--      default: 'd.m.Y',-->
<!--    },-->

<!--    dateFormat: {-->
<!--      type: String,-->
<!--      default: 'Y-m-d',-->
<!--    },-->

<!--    errorMessages: {-->
<!--      type: Array,-->
<!--      default: () => [],-->
<!--    },-->

<!--    successMessages: {-->
<!--      type: [Array, String],-->
<!--      default: '',-->
<!--    },-->

<!--    mode: {-->
<!--      type: String,-->
<!--      default: defaultMode,-->
<!--    },-->

<!--    hasArrows: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->

<!--    isNotField: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->

<!--    rangeSeparator: {-->
<!--      type: String,-->
<!--      default: ' — ',-->
<!--    },-->

<!--    required: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->

<!--    enableTime: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->

<!--    hasClearValue: {-->
<!--      type: Boolean,-->
<!--      default: false,-->
<!--    },-->
<!--  },-->

<!--  data() {-->
<!--    return {-->
<!--      localValue: null,-->
<!--      isOpened: false,-->
<!--      validateErrorMessage: null,-->
<!--    };-->
<!--  },-->

<!--  watch: {-->
<!--    value(date) {-->
<!--      this.localValue = this.formatValue(date);-->
<!--    },-->
<!--  },-->

<!--  computed: {-->
<!--    localConfig() {-->
<!--      return {-->
<!--        ...DEFAULT_CONFIG,-->
<!--        locale: {-->
<!--          ...this.locale,-->
<!--          rangeSeparator: this.rangeSeparator,-->
<!--        },-->
<!--        altFormat: this.enableTime ? 'd.m.Y H:i' : 'd.m.Y',-->
<!--        dateFormat: this.enableTime ? 'Y-m-d H:i:ss' :'Y-m-d',-->
<!--        defaultDate: null,-->
<!--        defaultHour: 0,-->
<!--        defaultMinute: 0,-->
<!--        mode: this.mode,-->
<!--        minDate: this.minDate,-->
<!--        maxDate: this.maxDate,-->
<!--        allowInput: this.allowInput,-->
<!--        enableTime: this.enableTime,-->
<!--        allowInvalidPreload: true,-->
<!--        parseDate: (datestr) => {-->
<!--          this.close();-->
<!--          return this.formatValue(datestr);-->
<!--        },-->
<!--        plugins: [-->
<!--          new ConfirmDatePlugin({ confirmText: this.$t('Choose'), showAlways: false, confirmIcon: '' }),-->
<!--        ],-->
<!--      };-->
<!--    },-->

<!--    locale() {-->
<!--      return Russian;-->
<!--    },-->

<!--    hasErrors() {-->
<!--      return !isEmpty(this.errorMessages);-->
<!--    },-->

<!--    hasSuccessMessages() {-->
<!--      return !isEmpty(this.successMessages);-->
<!--    },-->

<!--    placeholderText() {-->
<!--      if (this.placeholder === '_default') {-->
<!--        return this.$t('Select');-->
<!--      }-->

<!--      return this.placeholder;-->
<!--    },-->

<!--    possibleFormats() {-->
<!--      return this.enableTime-->
<!--        ? ["DDMMYYYY HHmm", "DDMMYYHHmm", "DDMMYYYYHHmm", "YYYY-MM-DD HH:mm:ss"]-->
<!--        : ["DDMMYYYY", "YYYY-MM-DD"];-->
<!--    }-->
<!--  },-->

<!--  created() {-->
<!--    if (this.value) {-->
<!--      this.localValue = this.formatValue(this.value);-->
<!--    }-->
<!--  },-->

<!--  mounted() {-->
<!--    this.$refs.calendar?.$data?.fp?.altInput?.addEventListener('beforeinput', this.mask);-->
<!--  },-->

<!--  methods: {-->
<!--    moment,-->

<!--    formatValue(date) {-->
<!--      if (!date) {-->
<!--        return null;-->
<!--      }-->

<!--      if (this.mode === 'range') {-->
<!--        const d1 = moment(date?.[0], this.possibleFormats);-->
<!--        const d2 = moment(date?.[1], this.possibleFormats);-->
<!--        return d1.isValid() && d2.isValid() ? [d1.toDate(), d2.toDate()] : null;-->
<!--      }-->

<!--      const d = moment(date, this.possibleFormats);-->

<!--      if (d.isValid()) {-->
<!--        this.validateErrorMessage = null;-->
<!--        return d.toDate();-->
<!--      } else {-->
<!--        this.validateErrorMessage = 'Неверный формат даты';-->
<!--        return null;-->
<!--      }-->

<!--      // return d.isValid() ? d.toDate() : null;-->
<!--    },-->

<!--    mask(event) {-->
<!--      if (!event.data || !this.allowInput || this.mode !== defaultMode) {-->
<!--        return;-->
<!--      }-->

<!--      if (!(event.data >= 0)) {-->
<!--        event.preventDefault();-->
<!--      }-->

<!--      const { value } = event.target;-->
<!--      const dateTimeDelimiter = ' ';-->
<!--      const defaultTimePosition = 10;-->
<!--      const currentTimePosition = value.indexOf(dateTimeDelimiter);-->
<!--      const timePosition = currentTimePosition === -1 ? defaultTimePosition : currentTimePosition;-->

<!--      const c = value.length;-->
<!--      const maxLength = this.enableTime ? timePosition + 5 : 9;-->

<!--      if (c > maxLength) {-->
<!--        event.preventDefault();-->
<!--        return;-->
<!--      }-->

<!--      switch (c) {-->
<!--        case 2:-->
<!--        case 5:-->
<!--          event.target.value += '.';-->
<!--          break;-->
<!--        case timePosition:-->
<!--          event.target.value += timePosition === defaultTimePosition && event.data !== dateTimeDelimiter ? dateTimeDelimiter : '';-->
<!--          break;-->
<!--        case timePosition + 3:-->
<!--          event.target.value += ':';-->
<!--          break;-->
<!--      }-->
<!--    },-->

<!--    setDay(direction) {-->
<!--      let date = null;-->
<!--      const flatPicker = this.$refs.calendar.$data.fp;-->

<!--      if (direction === 'prev') {-->
<!--        date = this.moment(this.value).subtract(1, 'day').format('YYYY-MM-DD');-->
<!--      } else {-->
<!--        date = this.moment(this.value).add(1, 'day').format('YYYY-MM-DD');-->
<!--      }-->

<!--      flatPicker.setDate(date);-->
<!--      this.$emit('input', date);-->
<!--    },-->

<!--    onCalendarClose(event) {-->
<!--      // prevent datepicker opening on file dragging-->
<!--      document.activeElement.blur();-->
<!--      this.isOpened = false;-->
<!--      this.$emit('on-close', event);-->
<!--    },-->

<!--    close() {-->
<!--      this.$refs.calendar?.$data?.fp?.close();-->
<!--    },-->

<!--    inputDate(value) {-->
<!--      value ? this.$emit('input', value) : this.localValue = '';-->
<!--    },-->
<!--  },-->
<!--};-->
<!--</script>-->

<!--<style lang="scss">-->
<!--@import 'assets/scss/_variables';-->
<!--@import 'assets/scss/_mixins';-->
<!--@import 'assets/scss/_global-classes';-->

<!--.t-datepicker {-->
<!--  $self: &;-->

<!--  color: $dusty-grey;-->

<!--  &&#45;&#45;has-value {-->
<!--    color: unset;-->
<!--  }-->

<!--  &&#45;&#45;has-warning {-->
<!--    #{$self}__input {-->
<!--      border: 1px solid $honey-yellow;-->
<!--    }-->
<!--    #{$self}__tooltip-icon {-->
<!--      fill: $honey-yellow;-->
<!--    }-->
<!--  }-->

<!--  &&#45;&#45;has-error {-->
<!--    #{$self}__input {-->
<!--      border: 1px solid $red-light;-->
<!--    }-->
<!--    #{$self}__tooltip-icon {-->
<!--      fill: $red-light;-->
<!--    }-->
<!--  }-->

<!--  &&#45;&#45;not-field {-->
<!--    .input {-->
<!--      background: transparent;-->
<!--      border: none;-->
<!--      cursor: pointer;-->
<!--      color: $steel-grey;-->
<!--      padding-left: 20px!important;-->
<!--    }-->

<!--    .t-datepicker__icon {-->
<!--      left: 0;-->
<!--    }-->
<!--  }-->

<!--  &&#45;&#45;has-value,-->
<!--  &&#45;&#45;opened {-->
<!--    #{$self}__placeholder {-->
<!--      left: 10px;-->
<!--      font: $t4-regular;-->
<!--      top: 0;-->
<!--      padding: 0 6px;-->
<!--      background-color: $white;-->

<!--      &&#45;&#45;required {-->
<!--        padding-right: 15px;-->

<!--        &::after {-->
<!--          right: 6px;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  &&#45;&#45;opened {-->
<!--    z-index: 3;-->

<!--    #{$self}__input-wrapper {-->
<!--      .active {-->
<!--        border: 1px solid $blue-light;-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  &&#45;&#45;disabled {-->
<!--    #{$self}__input {-->
<!--      color: $gray-light-2;-->
<!--    }-->

<!--  }-->

<!--  &&#45;&#45;readonly {-->
<!--    #{$self}__input {-->
<!--      color: $black;-->
<!--    }-->
<!--  }-->

<!--  &__picker {-->
<!--    &-arrow {-->
<!--      flex-shrink: 0;-->

<!--      &&#45;&#45;left {-->
<!--        border-right: 0px;-->
<!--        border-top-right-radius: 0px;-->
<!--        border-bottom-right-radius: 0px;-->
<!--      }-->
<!--      &&#45;&#45;right {-->
<!--        border-left: 0px;-->
<!--        border-top-left-radius: 0px;-->
<!--        border-bottom-left-radius: 0px;-->
<!--      }-->
<!--    }-->
<!--    &-wrapper {-->
<!--      align-items: center;-->
<!--      position: relative;-->
<!--    }-->
<!--  }-->

<!--  &__tooltip {-->
<!--    position: absolute;-->
<!--    top: 10px;-->
<!--    right: -20px;-->
<!--  }-->

<!--  &__error {-->
<!--    font: $t4-regular;-->
<!--    position: absolute;-->
<!--    left: 13px;-->
<!--    line-height: 1.2;-->
<!--  }-->

<!--  input {-->
<!--    border: none;-->
<!--    padding: 0;-->
<!--    outline: none;-->
<!--    padding-left: 35px;-->

<!--    &::placeholder {-->
<!--      color: $steel-grey;-->
<!--    }-->
<!--  }-->

<!--  &__input-wrapper {-->
<!--    position: relative;-->
<!--    width: 100%;-->

<!--    input {-->
<!--      border: 1px solid $gray-light-2;-->
<!--    }-->

<!--    &&#45;&#45;error {-->
<!--      input {-->
<!--        border-color: $red-light;-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  &__placeholder {-->
<!--    @include centerAbsoluteY;-->
<!--    left: 16px;-->
<!--    font: $t2-regular;-->
<!--    color: $gray-dark-2;-->
<!--    transition: all .2s;-->
<!--    pointer-events: none;-->
<!--    transition-property: left, right, top, transform, font-size;-->
<!--    transition-duration: 0.3s;-->
<!--    z-index: 5;-->

<!--    &&#45;&#45;required {-->
<!--      &::after {-->
<!--        position: absolute;-->
<!--        content: '*';-->
<!--        right: -10px;-->
<!--        top: 0;-->
<!--        color: $orange-light;-->
<!--        font-size: 12px;-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  &__icons {-->
<!--    @include centerAbsoluteY;-->
<!--    right: 19px;-->
<!--    display: flex;-->
<!--    align-items: center;-->
<!--    pointer-events: none;-->
<!--    z-index: 5;-->
<!--  }-->

<!--  &__input {-->
<!--    padding: 0 0 0 16px !important;-->
<!--  }-->

<!--  &__tooltip-icon {-->
<!--    display: block;-->
<!--  }-->
<!--}-->

<!--  input {-->
<!--    @extend .t-field__field;-->
<!--    width: initial;-->
<!--    padding: 0;-->
<!--    outline: none;-->
<!--    padding-left: 35px;-->

<!--    &::placeholder {-->
<!--      color: $steel-grey;-->
<!--    }-->
<!--  }-->

<!--.flatpickr-calendar.open {-->
<!--  width: 448px;-->
<!--  padding: 40px 80px 64px 80px;-->
<!--  line-height: 1.7;-->
<!--  border-radius: 2px;-->
<!--  border: 1px solid $light-grey;-->
<!--  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0.1);-->
<!--  background-color: $gray-ultra-light-2;-->

<!--  &.arrowBottom {-->
<!--    //position fix-->
<!--    margin-top: -153px;-->
<!--  }-->

<!--  &::before,-->
<!--  &::after {-->
<!--    display: none;-->
<!--  }-->

<!--  .numInputWrapper span {-->
<!--    z-index: 99;-->
<!--  }-->
<!--}-->

<!--.flatpickr-months {-->
<!--  padding: 0 20px;-->
<!--  position: relative;-->
<!--  margin-bottom: 15px;-->
<!--}-->

<!--.flatpickr-months .flatpickr-prev-month,-->
<!--.flatpickr-months .flatpickr-next-month {-->
<!--  padding: 0;-->
<!--  top: 11px;-->

<!--  svg {-->
<!--    width: 11px;-->
<!--    height: 15px;-->
<!--    fill: none;-->
<!--    stroke: $blue-light;-->
<!--    stroke-width: 1.7;-->
<!--  }-->
<!--}-->

<!--.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {-->
<!--  left: 6px;-->
<!--}-->
<!--.flatpickr-months .flatpickr-next-month.flatpickr-next-month {-->
<!--  right: 6px;-->
<!--}-->

<!--.flatpickr-month {-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--}-->

<!--.flatpickr-current-month {-->
<!--  display: flex;-->
<!--  gap: 10px;-->
<!--  padding: 0;-->
<!--  width: auto;-->
<!--  font: $h3-semibold;-->
<!--  color: $black;-->
<!--  height: unset;-->

<!--  .numInputWrapper {-->
<!--    width: 80px;-->
<!--    color: $gray-dark-2;-->

<!--    span {-->
<!--      z-index: 3;-->
<!--    }-->
<!--  }-->

<!--  .flatpickr-monthDropdown-month {-->
<!--    font: $t1-regular;-->
<!--  }-->
<!--}-->

<!--.flatpickr-current-month .flatpickr-monthDropdown-months,-->
<!--.flatpickr-current-month input.cur-year {-->
<!--  padding: 0;-->
<!--  font-weight: 600;-->
<!--  font-family: inherit;-->
<!--  -webkit-appearance: none;-->
<!--  -moz-appearance:textfield;-->
<!--}-->

<!--.flatpickr-current-month input.cur-year {-->
<!--  padding-left: 0;-->
<!--}-->

<!--.flatpickr-weekdays {-->
<!--  height: 18px;-->
<!--  margin-bottom: 7px;-->
<!--}-->

<!--.flatpickr-weekdaycontainer .flatpickr-weekday {-->
<!--  font-size: 0.86rem;-->
<!--}-->

<!--.flatpickr-rContainer,-->
<!--.flatpickr-days,-->
<!--.dayContainer {-->
<!--  min-width: 288px;-->
<!--  max-width: 288px;-->
<!--  width: 288px;-->
<!--}-->

<!--.flatpickr-days {-->
<!--  padding-top: 1px;-->
<!--}-->

<!--span.flatpickr-weekday {-->
<!--  width: 36px;-->
<!--  margin-right: 6px;-->
<!--  font: $t3-regular;-->
<!--  color: $orange-light;-->
<!--  text-transform: uppercase;-->

<!--  &:last-child {-->
<!--    margin-right: 0;-->
<!--  }-->
<!--}-->

<!--.flatpickr-day {-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  width: 36px;-->
<!--  flex-basis: 36px;-->
<!--  -ms-flex-preferred-size: 36px;-->
<!--  margin-right: 6px;-->
<!--  height:  36px;-->
<!--  font-size: 1rem;-->
<!--  line-height: 27px;-->
<!--  border-radius: 0;-->

<!--  &:nth-child(7n) {-->
<!--    margin-right: 0;-->
<!--  }-->

<!--  &.selected,-->
<!--  &.startRange,-->
<!--  &.endRange,-->
<!--  &.selected.inRange,-->
<!--  &.startRange.inRange,-->
<!--  &.endRange.inRange,-->
<!--  &.selected.prevMonthDay,-->
<!--  &.startRange.prevMonthDay,-->
<!--  &.endRange.prevMonthDay,-->
<!--  &.selected.nextMonthDay,-->
<!--  &.startRange.nextMonthDay,-->
<!--  &.endRange.nextMonthDay,-->
<!--  &.selected:focus,-->
<!--  &.startRange:focus,-->
<!--  &.endRange:focus,-->
<!--  &.selected:hover,-->
<!--  &.startRange:hover,-->
<!--  &.endRange:hover {-->
<!--    background-color: $blue-light;-->
<!--    border-radius: 0;-->
<!--    border-color: $blue-light;-->
<!--    transition: all 0.15s ease;-->
<!--    transition-property: background-color, border-color, color;-->
<!--    box-shadow: none !important;-->
<!--    color: #fff;-->
<!--  }-->

<!--  &:hover {-->
<!--    border-color: $blue-light;-->
<!--    color: $blue-light;-->
<!--    background: none;-->
<!--  }-->

<!--  &.nextMonthDay:not(.flatpickr-disabled),-->
<!--  &.prevMonthDay:not(.flatpickr-disabled) {-->
<!--    color: $gray-light-2;-->

<!--    &:hover {-->
<!--      background: none;-->
<!--      border: 1px solid $blue-light;-->
<!--      color: $blue-light;-->
<!--    }-->
<!--  }-->

<!--  &:not(.flatpickr-disabled) {-->
<!--    &:nth-child(7n-1),-->
<!--    &:nth-child(7n) {-->
<!--      &.selected {-->
<!--        color: #fff;-->
<!--      }-->
<!--    }-->

<!--    &.endRange , &.startRange {-->
<!--      color: #fff;-->
<!--      border-radius: 0;-->
<!--    }-->

<!--    &.inRange {-->
<!--      border-top: 1px solid $blue-light;-->
<!--      border-bottom: 1px solid $blue-light;-->
<!--      background-color: transparent;-->
<!--      border-right-color: transparent;-->
<!--      border-left-color: transparent;-->
<!--      box-shadow: none;-->

<!--      &.prevMonthDay.today {-->
<!--        background-color: transparent;-->
<!--        border-color: transparent;-->
<!--        border-top-color: $blue-light;-->
<!--      }-->
<!--    }-->

<!--    &:nth-child(7n).inRange {-->
<!--      border-right-color: $blue-light;-->
<!--    }-->

<!--    &:nth-child(7n + 1).inRange {-->
<!--      border-left-color: $blue-light;-->
<!--    }-->
<!--  }-->

<!--  &.nextMonthDay,-->
<!--  &.prevMonthDay {-->
<!--    color: $light-grey;-->
<!--  }-->

<!--  &.nextMonthDay.endRange:hover,-->
<!--  &.prevMonthDay.startRange:hover,-->
<!--  &.selected.startRange.nextMonthDay {-->
<!--    background-color: $blue-light;-->
<!--    color: $white;-->
<!--  }-->

<!--  &.nextMonthDay.endRange,-->
<!--  &.nextMonthDay.startRange {-->
<!--    background-color: $blue-light;-->
<!--  }-->

<!--  &.today {-->
<!--    position: relative;-->
<!--    border-color: transparent;-->
<!--    color: $orange-light;-->

<!--    &::after {-->
<!--      content: "•";-->
<!--      position: absolute;-->
<!--      bottom: -10px;-->
<!--      font-size: 20px;-->
<!--    }-->

<!--    &:hover {-->
<!--      background: none;-->
<!--      border: 1px solid $blue-light;-->
<!--      color: $blue-light;-->
<!--    }-->
<!--  }-->
<!--}-->

<!--.rangeMode {-->
<!--  .flatpickr-day {-->
<!--    position: relative;-->
<!--    margin-top: -1px;-->
<!--  }-->

<!--  .selected.startRange.endRange,-->
<!--  .selected.startRange.nextMonthDay {-->
<!--    border-radius: 0;-->

<!--    &::after,-->
<!--    &::before {-->
<!--      display: none;-->
<!--    }-->
<!--  }-->

<!--  .inRange,-->
<!--  .endRange {-->
<!--    &::after {-->
<!--      content: '';-->
<!--      position: absolute;-->
<!--      top: -1px;-->
<!--      left: -8px;-->
<!--      width: 8px;-->
<!--      height: 1px;-->
<!--      background-color: $blue-light;-->
<!--    }-->

<!--    &::before {-->
<!--      content: '';-->
<!--      position: absolute;-->
<!--      bottom: -1px;-->
<!--      left: -8px;-->
<!--      width: 8px;-->
<!--      height: 1px;-->
<!--      background-color: $blue-light;-->
<!--    }-->
<!--  }-->
<!--}-->

<!--.flatpickr-innerContainer {-->
<!--  overflow: visible;-->
<!--}-->

<!--.flatpickr-confirm {-->
<!--  margin-left: auto;-->
<!--  margin-top: 32px;-->
<!--  width: 132px;-->
<!--  height: 48px;-->
<!--  color: #fff;-->
<!--  background: #347AF0;-->
<!--}-->

<!--.datepicker__clear {-->
<!--  position: absolute;-->
<!--  top: 50%;-->
<!--  transform: translateY(-50%);-->
<!--  right: 50px;-->
<!--  z-index: 4;-->
<!--  cursor: pointer;-->
<!--}-->
<!--</style>-->

<template>
  <div
    :class="{
      't-field--no-label': !label,
      't-field--loading': loading,
      't-datepicker--has-warning': warning,
      't-datepicker--has-error': error,
      't-datepicker--has-value': value,
      't-datepicker--not-field': isNotField,
      't-datepicker--opened': isOpened,
      't-datepicker--disabled': disabled,
      't-datepicker--readonly': readonly,
    }"
    class="t-field t-datepicker"
  >
    <span
      v-if="label"
      :class="{
        't-field__label--error': hasErrors,
        't-field__label--success': !hasErrors && hasSuccessMessages,
        required,
      }"
      class="t-field__label"
    >
      {{label}}
    </span>
    <div class="t-field__group t-datepicker__picker-wrapper">
      <TButton
        v-if="hasArrows"
        class="t-datepicker__picker-arrow t-datepicker__picker-arrow--left"
        icon="arrow-left"
        secondary-action
        disable-lover
        icon-width="7"
        icon-height="12"
        icon-view-box="0 0 7 12"
        @click="setDay('prev')"
      />
      <TIconSvg
        v-if="hasClearValue && value && !(disabled || readonly || loading)"
        class="datepicker__clear"
        name="close"
        view-box="0 0 24 24"
        height="24"
        width="24"
        :stroke="$options.colors.GRAY_DARK_2"
        :fill="$options.GRAY_DARK_1"
        @click.stop="$emit('input', null)"
      />
      <div
        class="t-datepicker__input-wrapper"
        :class="{
          't-datepicker__input-wrapper--error': hasErrors
        }"
      >
        <div class="t-datepicker__icons">
          <TIconSvg
            v-if="hasErrors"
            name="danger-triangle"
            view-box="0 0 24 24"
            fill="none"
            :stroke="$options.colors.RED_LIGHT"
            width="24"
            height="24"
            class="mr-2 t-datepicker__icon"
          />
          <TIconSvg
            name="calendar"
            :stroke="disabled ? $options.colors.GRAY_LIGHT : $options.colors.BLUE_LIGHT"
            view-box="0 0 24 24"
            width="24"
            height="24"
            class="t-datepicker__icon"
          />
        </div>
        <Datepicker
          ref="calendar"
          v-model="localValue"
          :config="localConfig"
          :disabled="disabled || readonly || loading"
          class="t-field t-datepicker__input"
          :class="sizeableClasses"
          @input="$emit('input', $event)"
          @on-change="$emit('on-change', $event)"
          @on-close="onCalendarClose"
          @on-open="isOpened = true"
        />
        <span
          class="t-datepicker__placeholder"
          :class="{'t-datepicker__placeholder--required': required}"
        >
          {{placeholderText}}
        </span>
      </div>
      <TButton
        v-if="hasArrows"
        class="t-datepicker__picker-arrow t-datepicker__picker-arrow--right"
        icon="arrow-right"
        secondary-action
        disable-lover
        icon-width="7"
        icon-height="12"
        icon-view-box="0 0 7 12"
        @click="setDay('next')"
      />
      <div
        v-if="warning || error"
        class="t-datepicker__tooltip j-error"
      >
        <TIconSvg
          v-tooltip="error || warning"
          name="tooltip"
          view-box="0 0 15 15"
          class="ml-2 t-datepicker__tooltip-icon"
        />
      </div>
    </div>
    <div
      v-if="hasErrors && errorMessages[0]"
      class="error-text j-error t-datepicker__error"
    >
      {{errorMessages[0]}}
    </div>
  </div>
</template>

<script>
// libs
import moment from 'moment';
// Utils
import { isEmpty } from 'chober';
// Mixins
import sizes from '@/assets/js/mixins/sizes';
// Constants
import colors from '@/assets/scss/_variables.scss';
// Components
import Datepicker from 'vue-flatpickr-component';
/* eslint-disable import/no-extraneous-dependencies */
import 'flatpickr/dist/flatpickr.min.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import { Georgian } from 'flatpickr/dist/l10n/ka';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
/* eslint-enable import/no-extraneous-dependencies */

const DEFAULT_CONFIG = {
  altInput: true,
  dateFormat: 'Y-m-d',
  disableMobile: true,
};

const defaultMode = 'single';

export default {
  name: 'TDatepicker',

  components: {
    Datepicker,
  },

  mixins: [sizes],

  colors,

  props: {
    value: {
      type: [String, Array],
      default: '',
    },

    placeholder: {
      type: String,
      default: '_default',
    },

    label: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    allowInput: {
      type: Boolean,
      default: true,
    },

    warning: {
      type: String,
      default: '',
    },

    error: {
      type: String,
      default: '',
    },

    minDate: {
      type: String,
      default: '',
    },

    maxDate: {
      type: String,
      default: '',
    },

    defaultDate: {
      type: String,
      default: '',
    },

    altFormat: {
      type: String,
      default: 'd.m.Y',
    },

    dateFormat: {
      type: String,
      default: 'Y-m-d',
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    successMessages: {
      type: [Array, String],
      default: '',
    },

    mode: {
      type: String,
      default: defaultMode,
    },

    hasArrows: {
      type: Boolean,
      default: false,
    },

    isNotField: {
      type: Boolean,
      default: false,
    },

    rangeSeparator: {
      type: String,
      default: ' — ',
    },

    required: {
      type: Boolean,
      default: false,
    },

    enableTime: {
      type: Boolean,
      default: false,
    },

    hasClearValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localValue: null,
      isOpened: false,
    };
  },

  watch: {
    value(date) {
      this.localValue = this.formatValue(date);
    },
  },

  computed: {
    localConfig() {
      return {
        ...DEFAULT_CONFIG,
        locale: {
          ...this.locale,
          rangeSeparator: this.rangeSeparator,
        },
        altFormat: this.enableTime ? 'd.m.Y H:i' : 'd.m.Y',
        dateFormat: this.enableTime ? 'Y-m-d H:i:ss' :'Y-m-d',
        defaultDate: null,
        defaultHour: 0,
        defaultMinute: 0,
        mode: this.mode,
        minDate: this.minDate,
        maxDate: this.maxDate,
        allowInput: this.allowInput,
        enableTime: this.enableTime,
        allowInvalidPreload: true,
        parseDate: (datestr) => {
          this.close();
          return this.formatValue(datestr);
        },
        plugins: [
          new ConfirmDatePlugin({ confirmText: this.$t('Choose'), showAlways: false, confirmIcon: '' }),
        ],
      };
    },

    locale() {
      return Russian;
    },

    hasErrors() {
      return !isEmpty(this.errorMessages);
    },

    hasSuccessMessages() {
      return !isEmpty(this.successMessages);
    },

    placeholderText() {
      if (this.placeholder === '_default') {
        return this.$t('Select');
      }

      return this.placeholder;
    },

    possibleFormats() {
      return this.enableTime
        ? ["DDMMYYYY HHmm", "DDMMYYHHmm", "DDMMYYYYHHmm", "YYYY-MM-DD HH:mm:ss"]
        : ["DDMMYYYY", "YYYY-MM-DD"];
    },
  },

  created() {
    if (this.value) {
      this.localValue = this.formatValue(this.value);
    }
  },

  mounted() {
    this.$refs.calendar?.$data?.fp?.altInput?.addEventListener('beforeinput', this.mask);
  },

  methods: {
    moment,

    formatValue(date) {
      if (!date) {
        return null;
      }

      if (this.mode === 'range') {
        const d1 = moment(date?.[0], this.possibleFormats);
        const d2 = moment(date?.[1], this.possibleFormats);
        return d1.isValid() && d2.isValid() ? [d1.toDate(), d2.toDate()] : null;
      }

      const d = moment(date, this.possibleFormats);

      return d.isValid() ? d.toDate() : null;
    },

    mask(event) {
      if (!event.data || !this.allowInput || this.mode !== defaultMode) {
        return;
      }

      if (!(event.data >= 0)) {
        event.preventDefault();
      }

      const { value } = event.target;
      const dateTimeDelimiter = ' ';
      const defaultTimePosition = 10;
      const currentTimePosition = value.indexOf(dateTimeDelimiter);
      const timePosition = currentTimePosition === -1 ? defaultTimePosition : currentTimePosition;

      const c = value.length;
      const maxLength = this.enableTime ? timePosition + 5 : 9;

      if (c > maxLength) {
        event.preventDefault();
        return;
      }

      switch (c) {
        case 2:
        case 5:
          event.target.value += '.';
          break;
        case timePosition:
          event.target.value += timePosition === defaultTimePosition && event.data !== dateTimeDelimiter ? dateTimeDelimiter : '';
          break;
        case timePosition + 3:
          event.target.value += ':';
          break;
      }
    },

    setDay(direction) {
      let date = null;
      const flatPicker = this.$refs.calendar.$data.fp;

      if (direction === 'prev') {
        date = this.moment(this.value).subtract(1, 'day').format('YYYY-MM-DD');
      } else {
        date = this.moment(this.value).add(1, 'day').format('YYYY-MM-DD');
      }

      flatPicker.setDate(date);
      this.$emit('input', date);
    },

    onCalendarClose(event) {
      // prevent datepicker opening on file dragging
      document.activeElement.blur();
      this.isOpened = false;
      this.$emit('on-close', event);
    },

    close() {
      this.$refs.calendar?.$data?.fp?.close();
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';
@import 'assets/scss/_global-classes';

.t-datepicker {
  $self: &;

  color: $dusty-grey;

  &--has-value {
    color: unset;
  }

  &--has-warning {
    #{$self}__input {
      border: 1px solid $honey-yellow;
    }
    #{$self}__tooltip-icon {
      fill: $honey-yellow;
    }
  }

  &--has-error {
    #{$self}__input {
      border: 1px solid $red-light;
    }
    #{$self}__tooltip-icon {
      fill: $red-light;
    }
  }

  &--not-field {
    .input {
      background: transparent;
      border: none;
      cursor: pointer;
      color: $steel-grey;
      padding-left: 20px!important;
    }

    .t-datepicker__icon {
      left: 0;
    }
  }

  &--has-value,
  &--opened {
    #{$self}__placeholder {
      left: 10px;
      font: $t4-regular;
      top: 0;
      padding: 0 6px;
      background-color: $white;

      &--required {
        padding-right: 15px;

        &::after {
          right: 6px;
        }
      }
    }
  }

  &--opened {
    z-index: 3;

    #{$self}__input-wrapper {
      .active {
        border: 1px solid $blue-light;
      }
    }
  }

  &--disabled {
    #{$self}__input {
      color: $gray-light-2;
    }

  }

  &--readonly {
    #{$self}__input {
      color: $black;
    }
  }

  &__picker {
    &-arrow {
      flex-shrink: 0;

      &--left {
        border-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      &--right {
        border-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    &-wrapper {
      align-items: center;
      position: relative;
    }
  }

  &__tooltip {
    position: absolute;
    top: 10px;
    right: -20px;
  }

  &__error {
    font: $t4-regular;
    position: absolute;
    left: 13px;
    line-height: 1.2;
  }

  input {
    border: none;
    padding: 0;
    outline: none;
    padding-left: 35px;

    &::placeholder {
      color: $steel-grey;
    }
  }

  &__input-wrapper {
    position: relative;
    width: 100%;

    input {
      border: 1px solid $gray-light-2;
    }

    &--error {
      input {
        border-color: $red-light;
      }
    }
  }

  &__placeholder {
    @include centerAbsoluteY;
    left: 16px;
    font: $t2-regular;
    color: $gray-dark-2;
    transition: all .2s;
    pointer-events: none;
    transition-property: left, right, top, transform, font-size;
    transition-duration: 0.3s;
    z-index: 5;

    &--required {
      &::after {
        position: absolute;
        content: '*';
        right: -10px;
        top: 0;
        color: $orange-light;
        font-size: 12px;
      }
    }
  }

  &__icons {
    @include centerAbsoluteY;
    right: 19px;
    display: flex;
    align-items: center;
    pointer-events: none;
    z-index: 5;
  }

  &__input {
    padding: 0 0 0 16px !important;
  }

  &__tooltip-icon {
    display: block;
  }
}

input {
  @extend .t-field__field;
  width: initial;
  padding: 0;
  outline: none;
  padding-left: 35px;

  &::placeholder {
    color: $steel-grey;
  }
}

.flatpickr-calendar.open {
  width: 448px;
  padding: 40px 80px 64px 80px;
  line-height: 1.7;
  border-radius: 2px;
  border: 1px solid $light-grey;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0.1);
  background-color: $gray-ultra-light-2;

  &.arrowBottom {
    //position fix
    margin-top: -153px;
  }

  &::before,
  &::after {
    display: none;
  }

  .numInputWrapper span {
    z-index: 99;
  }
}

.flatpickr-months {
  padding: 0 20px;
  position: relative;
  margin-bottom: 15px;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  padding: 0;
  top: 11px;

  svg {
    width: 11px;
    height: 15px;
    fill: none;
    stroke: $blue-light;
    stroke-width: 1.7;
  }
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 6px;
}
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 6px;
}

.flatpickr-month {
  display: flex;
  align-items: center;
}

.flatpickr-current-month {
  display: flex;
  gap: 10px;
  padding: 0;
  width: auto;
  font: $h3-semibold;
  color: $black;
  height: unset;

  .numInputWrapper {
    width: 80px;
    color: $gray-dark-2;

    span {
      z-index: 3;
    }
  }

  .flatpickr-monthDropdown-month {
    font: $t1-regular;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  padding: 0;
  font-weight: 600;
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance:textfield;
}

.flatpickr-current-month input.cur-year {
  padding-left: 0;
}

.flatpickr-weekdays {
  height: 18px;
  margin-bottom: 7px;
}

.flatpickr-weekdaycontainer .flatpickr-weekday {
  font-size: 0.86rem;
}

.flatpickr-rContainer,
.flatpickr-days,
.dayContainer {
  min-width: 288px;
  max-width: 288px;
  width: 288px;
}

.flatpickr-days {
  padding-top: 1px;
}

span.flatpickr-weekday {
  width: 36px;
  margin-right: 6px;
  font: $t3-regular;
  color: $orange-light;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }
}

.flatpickr-day {
  display: flex;
  align-items: center;
  width: 36px;
  flex-basis: 36px;
  -ms-flex-preferred-size: 36px;
  margin-right: 6px;
  height:  36px;
  font-size: 1rem;
  line-height: 27px;
  border-radius: 0;

  &:nth-child(7n) {
    margin-right: 0;
  }

  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover {
    background-color: $blue-light;
    border-radius: 0;
    border-color: $blue-light;
    transition: all 0.15s ease;
    transition-property: background-color, border-color, color;
    box-shadow: none !important;
    color: #fff;
  }

  &:hover {
    border-color: $blue-light;
    color: $blue-light;
    background: none;
  }

  &.nextMonthDay:not(.flatpickr-disabled),
  &.prevMonthDay:not(.flatpickr-disabled) {
    color: $gray-light-2;

    &:hover {
      background: none;
      border: 1px solid $blue-light;
      color: $blue-light;
    }
  }

  &:not(.flatpickr-disabled) {
    &:nth-child(7n-1),
    &:nth-child(7n) {
      &.selected {
        color: #fff;
      }
    }

    &.endRange , &.startRange {
      color: #fff;
      border-radius: 0;
    }

    &.inRange {
      border-top: 1px solid $blue-light;
      border-bottom: 1px solid $blue-light;
      background-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
      box-shadow: none;

      &.prevMonthDay.today {
        background-color: transparent;
        border-color: transparent;
        border-top-color: $blue-light;
      }
    }

    &:nth-child(7n).inRange {
      border-right-color: $blue-light;
    }

    &:nth-child(7n + 1).inRange {
      border-left-color: $blue-light;
    }
  }

  &.nextMonthDay,
  &.prevMonthDay {
    color: $light-grey;
  }

  &.nextMonthDay.endRange:hover,
  &.prevMonthDay.startRange:hover,
  &.selected.startRange.nextMonthDay {
    background-color: $blue-light;
    color: $white;
  }

  &.nextMonthDay.endRange,
  &.nextMonthDay.startRange {
    background-color: $blue-light;
  }

  &.today {
    position: relative;
    border-color: transparent;
    color: $orange-light;

    &::after {
      content: "•";
      position: absolute;
      bottom: -10px;
      font-size: 20px;
    }

    &:hover {
      background: none;
      border: 1px solid $blue-light;
      color: $blue-light;
    }
  }
}

.rangeMode {
  .flatpickr-day {
    position: relative;
    margin-top: -1px;
  }

  .selected.startRange.endRange,
  .selected.startRange.nextMonthDay {
    border-radius: 0;

    &::after,
    &::before {
      display: none;
    }
  }

  .inRange,
  .endRange {
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: -8px;
      width: 8px;
      height: 1px;
      background-color: $blue-light;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: -8px;
      width: 8px;
      height: 1px;
      background-color: $blue-light;
    }
  }
}

.flatpickr-innerContainer {
  overflow: visible;
}

.flatpickr-confirm {
  margin-left: auto;
  margin-top: 32px;
  width: 132px;
  height: 48px;
  color: #fff;
  background: #347AF0;
}

.datepicker__clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  z-index: 4;
  cursor: pointer;
}
</style>
