import moment from 'moment';

export const state = () => ({
  bidsList: [],
  bidsPivot: [],
  bidsWinners: [],
  splittableQuantityRest: null,
  timeFromLoad: null,
  lastLoadedParams: null,
});

export const getters = {
  getBidsList: state => state.bidsList,
  getBidsPivot: state => state.bidsPivot,
  getBidsWinners: state => state.bidsWinners,
  getSplittableQuantityRest: state => state.splittableQuantityRest,
  getTimeFromLoad: state => state.timeFromLoad,
  getLastLoadedParams: state => state.lastLoadedParams,
};

export const mutations = {
  setBidsList(state, payload) {
    state.bidsList = payload;
  },

  setBidsPivot(state, payload) {
    state.bidsPivot = payload;
  },

  setBidsWinners(state, payload) {
    state.bidsWinners = payload;
  },

  setSplittableQuantityRest(state, payload) {
    state.splittableQuantityRest = payload;
  },

  setLastLoadingDate(state) {
    state.timeFromLoad = moment();
  },

  setLastLoadingParams(state, payload) {
    state.lastLoadedParams = payload;
  },
};

export const actions = {
  loadBidList({ commit }, id) {
    return this.$axios.get(`auctions/${id}/bids`)
      .then(({ data }) => {
        commit('setBidsList', data.data.bids);
        commit('setBidsPivot', data.data.pivot);
        commit('setBidsWinners', data.data.winners);
        commit('setSplittableQuantityRest', data.data.quantityRest);
        commit('setLastLoadingParams', id);
        commit('setLastLoadingDate');
        return data.bids;
      });
  },

  loadGPBidList({ commit }, id) {
    return this.$axios.get(`trading-auctions/${id}/bids`)
      .then(({ data }) => {
        commit('setBidsList', data.data.bids);
        commit('setBidsPivot', data.data.pivot);
        commit('setBidsWinners', data.data.winners);
        commit('setSplittableQuantityRest', data.data.quantityRest);
        commit('setLastLoadingParams', id);
        commit('setLastLoadingDate');
        return data.bids;
      });
  },

  clearBidList({ commit }) {
    commit('setBidsList', []);
    commit('setBidsPivot', []);
    commit('setBidsWinners', []);
    commit('setSplittableQuantityRest', []);
    commit('setLastLoadingParams', null);
    commit('setLastLoadingDate');
  },

  placeBid({ commit }, payload) {
    return this.$axios.post(`auctions/${payload.id}/bids`, {
      ...payload,
    }).then(({ data }) => data.success);
  },

  placeGPBid({ commit }, payload) {
    return this.$axios.post(`trading-auctions/${payload.id}/bids`, {
      ...payload,
    }).then(({ data }) => data.success);
  },

  approveGPBid({ commit }, payload) {
    return this.$axios.post(`trading-auctions/bids/${payload.id}/approve`, {
      ...payload,
    }).then(({ data }) => data.success);
  },

  rejectGPBid({ commit }, payload) {
    return this.$axios.post(`trading-auctions/bids/${payload.id}/reject`, {
      ...payload,
    }).then(({ data }) => data.success);
  },

  instantBuy({ commit }, payload) {
    return this.$axios.put(`auctions/${payload.id}/instant-buy`, {
      ...payload,
    }).then(({ data }) => data.success);
  },

  setWinner({ commit }, bid) {
    return this.$axios.post(`auctions/bids/${bid}/winner/set`)
      .then(({ data }) => data.success);
  },

  setGPWinner({ commit }, bid) {
    return this.$axios.post(`trading-auctions/bids/${bid}/winner/set`)
      .then(({ data }) => data.success);
  },
};
