export const state = () => ({
  isWarningPopupVisible: false,
  unsavedForms: {},
  nextAction: null,
  dirtyApplicationId: -1,
});

export const getters = {
  isWarningPopupVisible: state => state.isWarningPopupVisible,
  unsavedForms: state => state.unsavedForms,
  nextAction: state => state.nextAction,
  dirtyApplicationId: state => state.dirtyApplicationId,
  hasUnsavedForms: state => Object.keys(state.unsavedForms).length,
};

export const mutations = {
  addUnsavedForm(state, payload) {
    if (!state.unsavedForms[payload.id]) {
      const newObject = {
        ...state.unsavedForms,
        [payload.id]: payload,
      };
      state.unsavedForms = newObject;
    }
  },

  removeUnsavedForm(state, payload) {
    if (state.unsavedForms[payload.id]) {
      const newState = { ...state.unsavedForms };
      delete newState[payload.id];
      state.unsavedForms = newState;
    }
  },

  clearUnsavedForms(state) {
    state.unsavedForms = {};
  },

  setIsWarningPopupVisible(state, payload) {
    const { isVisible, next } = payload;
    state.isWarningPopupVisible = isVisible;
    state.nextAction = next;
    state.dirtyApplicationId = -1;
  },

  checkIsFormUnsaved(state, payload) {
    const { next, applicationId } = payload;

    if (state.unsavedForms[applicationId]) {
      state.isWarningPopupVisible = true;
      state.nextAction = next;
      state.dirtyApplicationId = applicationId;
    } else {
      next();
    }
  },

  checkForUnsavedForms(state, payload) {
    const { next } = payload;

    if (Object.keys(state.unsavedForms).length) {
      state.isWarningPopupVisible = true;
      state.nextAction = next;
    } else {
      next();
    }
  },
};
