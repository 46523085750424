export const state = () => ({
  auctionData: {},
  auction: {},
  ordersErrors: [],
  auctionHistory: [],
  productGroups: [],
  unitTypes: [],
  products: [],
});

export const getters = {
  getAuctionData: state => state.auctionData,
  getAuction: state => state.auction,
  getOrdersErrors: state => state.ordersErrors,
  getAuctionHistory: state => state.auctionHistory,
  getProductGroups: state => state.productGroups,
  getUnitTypes: state => state.unitTypes,
  getProducts: state => state.products,
};

export const mutations = {
  setAuctionData(state, payload) {
    state.auctionData = payload;
  },

  setAuction(state, payload) {
    state.auction = payload;
  },

  setOrdersErrors(state, payload) {
    state.ordersErrors = payload;
  },

  clearOrdersErrors(state) {
    state.ordersErrors = [];
  },

  setAuctionHistory(state, payload) {
    state.auctionHistory = payload;
  },

  setProductGroups(state, payload) {
    state.productGroups = payload;
  },

  setUnitTypes(state, payload) {
    state.unitTypes = payload;
  },

  setProducts(state, payload) {
    state.products = payload;
  },

  addProductGroup(state, payload) {
    state.productGroups.push(payload);
  },
};

export const actions = {
  createAuction({ commit, dispatch }, payload) {
    return this.$axios.post('auctions', {
      ...payload,
    })
      .then(({ data }) => data.success);
  },

  updateAuction({ commit, dispatch }, payload) {
    return this.$axios.put(`auctions/${payload.id}`, {
      ...payload,
    })
      .then(({ data }) => data.success);
    // Для теста ошибки
    // .catch(error => {
    //   console.error(error);
    // });
  },

  createTrading({ commit, dispatch }, payload) {
    return this.$axios.post('trading-auctions', {
      ...payload,
    })
      .then(({ data }) => data.success);
  },

  updateTrading({ commit, dispatch }, payload) {
    return this.$axios.put(`trading-auctions/${payload.id}`, {
      ...payload,
    })
      .then(({ data }) => data.success);
  },

  loadAuction({ commit, dispatch }, id) {
    return this.$axios.get(`/auctions/${id}`)
      .then(({ data }) => {
        commit('setAuction', data.auction);
      })
      .catch(error => {
        console.error(error);
      });
  },

  loadAuctionData({ commit, dispatch }) {
    return this.$axios.get('auctions/create')
      .then(({ data }) => {
        commit('setAuctionData', data.data);
      })
      .catch(error => {
        console.error(error);
      });
  },

  loadHistory({ commit, dispatch }, id) {
    return this.$axios.get(`/auctions/${id}/history`)
      .then(({ data }) => {
        commit('setAuctionHistory', Object.values(data.history));
      }).catch(error => {
        console.error(error);
      });
  },

  cancelAuction({ commit, dispatch }, { id, message }) {
    return this.$axios.put(`auctions/${id}/cancel`, { end_reason: message })
      .then(({ data }) => data.success);
  },

  finishAuction({ commit, dispatch }, { id, message }) {
    return this.$axios.put(`auctions/${id}/finish`, { end_reason: message })
      .then(({ data }) => data.success)
      .catch(error => {
        console.error(error);
      });
  },

  loadProductGroups({ commit }) {
    return this.$axios.get(`purchase/group/list`)
      .then(({ data }) => {
        commit('setProductGroups', data?.data);
      })
      .catch(error => {
        console.error(error);
      });
  },

  addProductGroup({ commit }, params) {
    return this.$axios.post(`purchase/group/store`, params)
      .then(({ data }) => {
        commit('addProductGroup', data?.group);
        return data?.group;
      });
  },

  loadUnitTypes({ commit }) {
    return this.$axios.get(`product-types`)
      .then(({ data }) => {
        commit('setUnitTypes', data?.data);
      })
      .catch(error => {
        console.error(error);
      });
  },

  loadProducts({ commit }, params) {
    commit('setProducts', []);
    return this.$axios.get(`products`,
      { params: params && params?.id ? { division_id: params.id } : null }
    )
      .then(({ data }) => {
        commit('setProducts', data?.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
};
