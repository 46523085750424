<template>
  <svg-icon
    :name="name"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :fill="fill"
    :stroke="stroke || $options.colors.BLUE_LIGHT"
    @click="!disabled && $emit('click', $event)"
  />
</template>

<script>
import colors from '@/assets/scss/_variables.scss';

export default {
  name: 'TIconSvg',

  colors,

  props: {
    name: {
      type: String,
      default: '',
    },

    fill: {
      type: String,
      default: 'currentColor',
    },

    stroke: {
      type: [String, Function],
      default: '',
    },

    width: {
      type: [Number, String],
      default: 15,
    },

    height: {
      type: [Number, String],
      default: 15,
    },

    viewBox: {
      type: String,
      default: '0 0 15 15',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
