<template>
  <nuxt-link
    v-if="!children || !children.length"
    v-slot="{ isActive, navigate, href }"
    :to="link"
    :exact="link === '/'"
    custom
  >
    <li
      :class="{ 'sidepanel-menu-item--active': isActive
        || href.includes('users')
        && $route.name
        && $route.name.includes('users')}"
      class="sidepanel-menu-item"
    >
      <a
        v-tooltip.top-end="{
          content: isShrunk ? text : '',
          classes: 'sidepanel-menu-item__tooltip',
        }"
        :href="href"
        class="sidepanel-menu-item__inner"
        :class="{'sidepanel-menu-item__inner--shrunk': isShrunk}"
        @click="navigate"
      >
        <TIconSvg
          :name="icon"
          class="sidepanel-menu-item__icon"
          :class="{
            'sidepanel-menu-item__icon--active': isActive,
            'sidepanel-menu-item__icon--shrunk': !isShrunk
          }"
          :width="iconWidth"
          :height="iconHeight"
          :view-box="iconViewBox"
          :stroke="iconStroke"
        />
        <div
          v-show="!isShrunk"
          class="sidepanel-menu-item__title"
        >
          {{text}}
        </div>
        <div class="sidepanel-menu-item__counter">
          <SidepanelCounter
            v-if="isCounter"
            :count="quantity"
          />
        </div>
      </a>
    </li>
  </nuxt-link>
  <li
    v-else-if="children.length"
    class="sidepanel-menu-item"
    :class="{ 'sidepanel-submenu-item--active': isActive }"
  >
    <div
      v-tooltip.top-end="{
        content: isShrunk ? text : '',
        classes: 'sidepanel-menu-item__tooltip',
      }"
      :class="{ 'sidepanel-menu-item__inner--shrunk': isShrunk }"
      class="sidepanel-menu-item__inner"
      @click="toggleSubmenu"
    >
      <TIconSvg
        :name="icon"
        class="sidepanel-menu-item__icon"
        :class="{
          'sidepanel-menu-item__icon--active': isActive,
          'sidepanel-menu-item__icon--shrunk': !isShrunk
        }"
        :width="iconWidth"
        :height="iconHeight"
        :view-box="iconViewBox"
        :stroke="iconStroke"
      />
      <transition name="g-slide-down">
        <div
          v-show="!isShrunk"
          class="sidepanel-menu-item__title"
          :class="{ 'sidepanel-submenu-item--active': hasActiveChildren}"
        >
          {{text}}
        <!-- В дальнейшем может понадобиться иконка для дропдауна -->
        <!-- <TIconSvg
          slot="after"
          class="sidepanel-menu-item__title-arrow"
          :class="{ 'sidepanel-menu-item__title-arrow--active': isSubmenuOpen }"
          :name="'arrow-up'"
          :fill="'#347af0'"
          width="12"
          height="12"
          view-box="0 0 512 512"
        /> -->
        </div>
      </transition>
    </div>
    <transition name="g-slide-down">
      <SidepanelSubmenu
        v-show="!isShrunk
          && (isSubmenuOpen || hasActiveChildren)"
        :items="children"
        class="sidepanel-menu-item__submenu"
      />
    </transition>
  </li>
</template>

<script>

// libs
import { isEqual } from 'chober';

// Components
import SidepanelCounter from './SidepanelCounter';

export default {
  name: 'SidepanelMenulItem',

  components: {
    SidepanelCounter,
    SidepanelSubmenu: () => import('./SidepanelSubmenu'),
  },

  props: {
    text: {
      type: String,
      required: true,
    },

    link: {
      type: String,
      default: null,
    },

    children: {
      type: Array,
      default: null,
    },

    isShrunk: {
      type: Boolean,
      required: true,
    },

    isCounter: {
      type: Boolean,
      required: false,
    },

    quantity: {
      type: Number,
      default: 0,
    },

    icon: {
      type: String,
      required: true,
    },

    iconWidth: {
      type: Number,
      default: 24,
    },

    iconHeight: {
      type: Number,
      default: 24,
    },

    iconViewBox: {
      type: String,
      default: '0 0 24 24',
    },

    iconStroke: {
      type: String,
      default: 'none',
    },
  },

  data: () => ({
    isSubmenuOpen: false,
  }),

  computed: {
    activePage() {
      return this.$nuxt.$route.path;
    },

    isActive() {
      if (this.hasActiveChildren) {
        return this.isShrunk;
      }

      return this.activePage === this.link;
    },

    hasActiveChildren() {
      return this.children
        && this.children.some(item => isEqual(this.activePage.split('/').splice(1, 1),
          item.link.split('/').splice(1, 1)));
    },
  },

  created() {
    if (this.hasActiveChildren) {
      this.isSubmenuOpen = true;
    }
  },

  methods: {
    isEqual,

    toggleSubmenu() {
      if (this.hasActiveChildren) {
        return;
      }

      this.isSubmenuOpen = !this.isSubmenuOpen;

      if (!this.isSubmenuOpen) {
        this.$emit('open-children');
      }

      if (this.isShrunk) {
        this.$emit('unshrunk-menu');
        this.isSubmenuOpen = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.sidepanel-menu-item {
  $self: &;

  list-style: none;

  &:not(:last-child){
    margin-bottom: 8px;
  }

  &:hover{
    background-color: $gray-ultra-light-2;

    #{$self}__title{
      color: $blue-light;
    }
  }

  &--active {
    color: $black;
    font-weight: 500;
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 32px;
    background-color: inherit;
    text-decoration: none;
    border-width: 0;
    cursor: pointer;
    transition: padding 0.2s;

    &--shrunk {
      padding: 12px 24px;
        #{$self}__counter{
          position: absolute;
          right: 0;
        }
    }
  }

  &__icon-container {
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
  }

  &__icon {
    max-width: 100%;
    flex-shrink: 0;
    stroke: $blue-light;
  }

  &__title {
    display: flex;
    align-items: center;
    flex: auto;
    max-width: 200px;
    max-height: 50px;
    overflow: hidden;
    margin-left: 16px;
    white-space: nowrap;
  }

  &__title-arrow {
    margin-left: 8px;
    transition: transform 0.2s;
    transform: rotate(180deg);

    &--active {
      transform: rotate(0deg);
    }
  }

  &__submenu {
    white-space: nowrap;
    overflow: hidden;
    flex-basis: 100%;
  }

  &__tooltip {
    .tooltip-inner {
      background: #000;
      color: $white;
    }
  }

  &__tooltip {
    z-index: 250;
    .tooltip-arrow {
      display: none;
    }
    .tooltip-inner {
      position: absolute;
      top: 11px;
      left: 10px;
      background: $light-almost-black;
      color: #fff;
      border-radius: 2px;
      padding: 5px 10px 4px;
      border: none;
      box-shadow: 4px 4px 10px $almost-black-shadow;
    }
  }
}
</style>
