import Vue from 'vue';
import * as VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
import ru, { dict as dictRu } from '~/assets/js/veeValidate/ru';
import { normalizeNumber } from '~/assets/js/utils';

export default ({ app }) => {
  Vue.use(VeeValidate, {
    events: 'change',
    locale: 'ru',
    dictionary: {
      ru: { messages: ru },
    },
  });
};

Validator.localize('ru', dictRu);

const isValidInn = (value, { innLength1, innLength2 } = {}) => (
  String(value).length === Number(innLength1) || String(value).length === Number(innLength2)
);
const paramNames = ['innLength1', 'innLength2'];

Validator.extend('inn', isValidInn, {
  paramNames,
});

const isValidItem = (value, { items } = { items: [] }) => items.includes(value);

Validator.extend('items', isValidItem, {
  paramNames: ['items'],
});

const isValidTimePeriod = (value, { innLength1 } = {}) => value > innLength1;

Validator.extend('time_period', isValidTimePeriod, {
  paramNames,
});

const isMaxValue = (value, [maxValue]) => normalizeNumber(value) <= normalizeNumber(maxValue);

Validator.extend('max_integer_value', isMaxValue);

const isMinValue = (value, [minValue]) => normalizeNumber(value) >= normalizeNumber(minValue);

Validator.extend('min_integer_value', isMinValue);

const isLoadingRestrictionPassed = (value, [maxValue]) => normalizeNumber(value) <= normalizeNumber(maxValue);

Validator.extend('loading_restriction', isLoadingRestrictionPassed);
