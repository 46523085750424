import {
  ADMIN_ROLE_SLUG,
  TRANSPORTER_ROLE_SLUG,
  CLIENT_ROLE_SLUG,
  DISPATCHER_ROLE_SLUG,
  MENU_SECTIONS,
} from '~/assets/js/constants';

const authPages = {
  '/auth': true,
};

const exeptOuterRoutes = {
  '/auth': true,
  '/public-orders': true,
  '/unsubscribe': true,
  '/no-access': true,
  '/company-details': true,
  '/company-documents': true,
  '/company-transport': true,
  '/company-driver': true,
  '/company-contract': true,
  '/company-contract/status': true,
  '/company-details/edit': true,
  '/company-documents/edit': true,
  '/company-transport/edit': true,
  '/company-driver/edit': true,
};

const routeIdentifier = {
  orders: 'applications',
  trips: 'trips',
  cars: 'transport',
  fines: 'fines',
  transporters: 'transporters',
  customers: 'companies',
  drivers: 'drivers',
  users: 'users',
  settings: 'administration',
  branches: '',
  references: '',
  directories_transporters: '',
  client_forwarders: '',
  road_trains: 'available-transport',
  auctions: 'bidding',
  instructions: 'instructions',
};

const exeptOuterRoutesName = {
  'public-orders-id': true,
};

const exeptInnerRoutes = {
  '/': true,
};

const currentRoles = {
  2: ADMIN_ROLE_SLUG,
  3: CLIENT_ROLE_SLUG,
  4: TRANSPORTER_ROLE_SLUG,
  5: DISPATCHER_ROLE_SLUG,
};

const isEdit = {
  users: [
    'users-index-edit-id',
    'users-index-create',
  ],
};

let previousRoute = '';

function protectPages(route, store) {
  const routeName = route.path.split('/')[1];
  const { currentAccess, currentUser } = store.state.main;
  const isAdmin = currentUser.roles.find(({ id }) => currentRoles[id] === ADMIN_ROLE_SLUG);
  const isClientOrForwarder = store.getters['main/isClientOrForwarder'];
  const isTransporter = currentUser.roles.find(({ id }) => currentRoles[id] === TRANSPORTER_ROLE_SLUG);
  const isUserCompanyRequiringAccreditation = store.getters['main/isUserCompanyRequiringAccreditation'];
  const isDispatcher = currentUser.roles.find(({ id }) => currentRoles[id] === DISPATCHER_ROLE_SLUG);

  const adminForSettings = currentUser.roles.find(item => item.name === ADMIN_ROLE_SLUG);
  if (routeName === 'administration' && adminForSettings) {
    return true;
  }

  // Вкладка "Инструкции" не доступна всем для просмотра (II этап)
  // if (routeName === 'instructions') {
  //   return false;
  // }

  // TODO: отрефакторить проверку
  let currentAccessIdentifier = routeName;
  if (routeName === 'transport') {
    currentAccessIdentifier = 'cars';
  }
  if (routeName === 'applications') {
    currentAccessIdentifier = 'orders';
  }
  if (routeName === 'companies') {
    currentAccessIdentifier = 'customers';
  }
  if (routeName === 'available-transport') {
    currentAccessIdentifier = 'road_trains';
  }
  if (routeName === 'administration') {
    currentAccessIdentifier = 'admin';
  }
  if (routeName === 'bidding') {
    currentAccessIdentifier = 'auctions';
  }

  return currentUser.roles?.reduce(
    (isForbidden, role) => (MENU_SECTIONS[role?.name]?.includes(currentAccessIdentifier)
        && currentAccess[currentAccessIdentifier]?.view)
        || isForbidden, false
  );
}

export default async function({ route, store, redirect }) {
  const keys = Object.keys(store.state.main.currentAccess);

  if (store.state.general.token && authPages[route.path] && keys.includes('orders')) {
    redirect('/');
  }

  if (store.state.general.token && authPages[route.path] && !keys.includes('orders')) {
    redirect(`/${routeIdentifier.keys[0]}`);
  }

  // store, $axios , query, params, route
  if (exeptOuterRoutes[route.path]
    || route.name === previousRoute
    || exeptOuterRoutesName[route.name]
  ) {
    return;
  }

  previousRoute = route.name;
  await store.dispatch('main/getInfo');

  if (exeptInnerRoutes[route.path]) return;

  if (!protectPages(route, store) && !keys.length) {
    redirect('/no-access');
  }

  if (!protectPages(route, store)) {
    redirect(`/${routeIdentifier[keys[0]]}`);
  }
}
