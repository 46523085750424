var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t-popup"},[(_vm.hasOverlay)?_c('div',{staticClass:"t-popup__overlay",on:{"click":function($event){_vm.overlayClose ? _vm.$emit('close') : ''}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"t-popup__wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"t-popup__content",class:{
        't-popup__content--center': !_vm.isSidePopup,
        't-popup__content--side' : _vm.isSidePopup,
        't-popup__content--offset-bottom': _vm.isHiddenBottom,
      },style:({ width: _vm.width })},[_c('div',{staticClass:"t-popup__header",class:{
          't-popup__header--side' : _vm.isSidePopup
        }},[_vm._t("header"),_vm._v(" "),(_vm.hasCloseButton)?_c('button',{staticClass:"t-popup__close-button",class:{
            't-popup__close-button--center': !_vm.isSidePopup,
            't-popup__close-button--side' : _vm.isSidePopup
          },attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('TIconSvg',{attrs:{"name":"close","stroke":_vm.iconColor,"height":"24","width":"24","view-box":"0 0 24 24"}})],1):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"t-popup__text"},[_vm._t("default")],2),_vm._v(" "),(!_vm.isHiddenBottom)?_c('div',{staticClass:"t-popup__bottom",class:{
          't-popup__bottom--side' : _vm.isSidePopup
        },style:(_vm.variables)},[_vm._t("bottom")],2):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }