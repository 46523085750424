// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.t-table{position:relative}.t-table__panel{position:absolute;top:0;bottom:0;width:80px;z-index:300;pointer-events:none}.t-table__panel-left{left:0}.t-table__panel-right{right:0}.t-table__panel-button{position:absolute;top:200px;cursor:pointer;display:none}.t-table__panel-button:hover{filter:saturate(70%)}.t-table__blur{opacity:0;height:100%;background:linear-gradient(270deg,#fff,hsla(0,0%,100%,0));transition:opacity .3s;display:flex;align-items:center}.t-table__blur-left{transform:matrix(-1,0,0,1,0,0)}.t-table__blur--visible{opacity:1}.t-table__tb-tr{cursor:pointer}.t-table__tb-tr--flex{display:flex}.t-table__tb-tr td{word-wrap:break-word}.t-table__scroll-area--visible{overflow:unset!important}.t-table__scroll-area .ps__rail-x{display:none!important}.t-table__empty{position:relative}.t-table__preloader{left:50%;transform:translateX(-50%)}.t-table__preloader-wrapper{width:100%;padding:16px}.scroll_wrapper{overflow-x:hidden;overflow-y:scroll;overflow:overlay;height:calc(100vh - 300px);scrollbar-color:#c9d2e1 rgba(0,50,160,.1)}.ps{touch-action:auto;overflow:visible!important}.scroll_wrapper::-webkit-scrollbar{height:6px;width:6px}.scroll_wrapper:hover::-webkit-scrollbar{height:8px;width:8px}.scroll_wrapper::-webkit-scrollbar-track{background:#c9d2e1;width:24px;height:auto;opacity:.4;border-radius:6px}.scroll_wrapper::-webkit-scrollbar-thumb{background-color:rgba(0,50,160,.16);border-radius:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
