<template>
  <div
    class="t-slide"
  >
    <div
      class="t-slide__header"
      :class="{'t-slide__header--opened': isOpen}"
      @click="handleOpen"
    >
      <TIconSvg
        class="t-slide__icon"
        name="arrow-down"
        stroke="#fff"
        view-box="0 0 24 24"
        height="20"
        width="20"
      />
    </div>
    <Transition name="g-slide-down">
      <div
        v-show="isOpen"
        class="t-slide__content"
      >
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'TSlide',

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
  },

  methods: {
    handleOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-slide {
  $self: &;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-ultra-light-2;
    width: 100%;
    transition: height 3s;
    cursor: pointer;

    &--opened {
      background-color: $gray-dark-2;

      #{$self}__icon {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
