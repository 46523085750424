// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.sidepanel-menu-item{list-style:none}.sidepanel-menu-item:not(:last-child){margin-bottom:8px}.sidepanel-menu-item:hover{background-color:#eff3fa}.sidepanel-menu-item:hover .sidepanel-menu-item__title{color:#347af0}.sidepanel-menu-item--active{color:#001e42;font-weight:500}.sidepanel-menu-item__inner{position:relative;display:flex;align-items:center;padding:12px 16px 12px 32px;background-color:inherit;text-decoration:none;border-width:0;cursor:pointer;transition:padding .2s}.sidepanel-menu-item__inner--shrunk{padding:12px 24px}.sidepanel-menu-item__inner--shrunk .sidepanel-menu-item__counter{position:absolute;right:0}.sidepanel-menu-item__icon-container{width:25px;display:flex;justify-content:center;align-items:center;flex:none}.sidepanel-menu-item__icon{max-width:100%;flex-shrink:0;stroke:#347af0}.sidepanel-menu-item__title{display:flex;align-items:center;flex:auto;max-width:200px;max-height:50px;overflow:hidden;margin-left:16px;white-space:nowrap}.sidepanel-menu-item__title-arrow{margin-left:8px;transition:transform .2s;transform:rotate(180deg)}.sidepanel-menu-item__title-arrow--active{transform:rotate(0deg)}.sidepanel-menu-item__submenu{white-space:nowrap;overflow:hidden;flex-basis:100%}.sidepanel-menu-item__tooltip .tooltip-inner{background:#000}.sidepanel-menu-item__tooltip{z-index:250}.sidepanel-menu-item__tooltip .tooltip-arrow{display:none}.sidepanel-menu-item__tooltip .tooltip-inner{position:absolute;top:11px;left:10px;background:#39464d;color:#fff;border-radius:2px;padding:5px 10px 4px;border:none;box-shadow:4px 4px 10px rgba(91,91,91,.2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
