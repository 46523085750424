// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.t-radio{display:flex;align-items:center;justify-content:center;flex:none;width:16px;height:16px;border:1px solid #dbe0ea;border-radius:50%;transition:border-color .2s ease;cursor:pointer;position:relative;background-color:#fff}.t-radio--checked,.t-radio:hover{border-color:#dbe0ea}.t-radio:hover{border-color:#979797}.t-radio--disabled{cursor:default;background-color:#f1f6fe}.t-radio--disabled:hover{border-color:#dbe0ea}.t-radio--disabled.t-radio--checked:before{background-color:#b5c3db;width:6px;height:6px;top:4px;left:4px}.t-radio-wrapper .t-col{padding:0 5px}.t-radio__text--disabled{color:#a7b5ce}.t-radio--checked:before{content:\"\";position:absolute;top:3px;left:3px;width:8px;height:8px;border-radius:50%;background-color:#347af0}.t-radio__input{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
