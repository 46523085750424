// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.t-link{position:relative;font:400 12px/14px \"Graphik LCG\";color:#347af0;outline:none;cursor:pointer;transition:color .3s,opacity .3s}.t-link:hover:not(.t-link--underlined):not(.t-link--disabled){color:#0f54c9}.t-link:active:not(.t-link--disabled){color:rgba(52,122,240,.7)}.t-link.t-font--default{font:400 12px/14px \"Graphik LCG\"}.t-link.t-font--small{font:400 14px/18px \"Graphik LCG\"}.t-link--disabled{cursor:default;color:#dbe0ea}.t-link--multiline{white-space:normal}.t-link--underlined:after{content:\"\";width:100%;height:1px;position:absolute;left:0;right:0;bottom:1px;background-color:#347af0;transition:background-color .3s}.t-link--underlined:hover:after{background-color:transparent}.t-link--underlined.t-link--multiline{text-decoration:underline;-webkit-text-decoration-color:#347af0;text-decoration-color:#347af0;transition:-webkit-text-decoration-color .3s;transition:text-decoration-color .3s;transition:text-decoration-color .3s,-webkit-text-decoration-color .3s}.t-link--underlined.t-link--multiline:after{display:none}.t-link--underlined.t-link--multiline:hover{-webkit-text-decoration-color:transparent;text-decoration-color:transparent}.t-link--has-icon{display:inline-flex;align-items:center}.t-link__icon{margin-right:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
