<template>
  <DefaultLayout :header-shown="false" />
</template>

<script>
import DefaultLayout from '../components/Layouts/DefaultLayout';

export default {
  name: 'DefaultNoHeader',
  components: { DefaultLayout },
};
</script>
