<template>
  <div
    v-if="loading"
    class="loading-wrapper"
  >
    <div class="indeterminate"/>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true;
    },
    finish() {
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/variables';

.loading-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  height: 2px;
  background-color: $light-blue;
}

.indeterminate {
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $blue-light;
  animation: indeterminate_first 2s infinite ease-out;
}

.indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $blue-light;
  animation: indeterminate_second 2s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}
</style>
