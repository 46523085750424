import moment from 'moment';

export const state = () => ({
  isRequestTableLoading: false,
  query: {},
  requestData: {},
  timeFromLoad: null,
  requestsLastLoadedParams: null,
  requestList: [],
  pagination: {},
  statusList: [],
});

export const getters = {
  getRequestTableLoading: state => state.isRequestTableLoading,
  getQuery: state => state.query,
  getAuctionOrdersList: state => state.ordersListForAuction,
  getTimeFromLoad: state => state.timeFromLoad,
  getRequestsLastLoadedParams: state => state.requestsLastLoadedParams,
  getRequestList: state => state.requestList,
  getPaginationData: state => state.pagination,
  getStatusList: state => state.statusList,
};

export const mutations = {
  setRequestTableLoading(state, payload) {
    state.isRequestTableLoading = payload;
  },

  setQuery(state, payload) {
    state.query = payload;
  },

  setRequestData(state, payload) {
    state.requestData = payload;
    state.requestList = payload.data;
    state.pagination = {
      current_page: payload.current_page,
      from: payload.from,
      last_page: payload.last_page,
      path: payload.path,
      per_page: payload.per_page,
      to: payload.to,
      total: payload.total,
    };
  },

  setLastLoadingDate(state) {
    state.timeFromLoad = moment();
  },

  setRequestLastLoadedParams(state, payload) {
    state.requestsLastLoadedParams = payload;
  },

  setAuctionOrdersList(state, payload) {
    state.ordersListForAuction = payload;
  },

  clearOrdersListForAuction(state) {
    state.ordersListForAuction = [];
  },

  setStatusList(state, payload) {
    state.statusList = Object.values(payload);
  },
};

export const actions = {
  loadRequest({ commit }, { params, disableLoading }) {
    if (!disableLoading) {
      commit('setRequestTableLoading', true);
    }

    return this.$axios.get('/proposal-request', {
      params: {
        ...params,
        clients: params?.clients?.length ? JSON.parse(params.clients).map(({ id }) => Number(id)) : undefined,
      },
    })
      .then(({ data }) => {
        commit('setRequestData', data.data);
        commit('setRequestLastLoadedParams', params);
        commit('setLastLoadingDate');
        return data.data;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        commit('setRequestTableLoading', false);
      });
  },

  loadRequestData({ commit }) {
    return this.$axios.get('proposal-request/create')
      .then(({ data }) => {
        commit('setStatusList', data.labels.statuses);
      })
      .catch(error => {
        console.error(error);
      });
  },

  saveQuery({ commit }, payload) {
    commit('setQuery', payload);
  },
};
