<template>
  <div
    class="t-spinner"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <TIconSvg
      class="t-spinner__icon"
      name="stroke"
      :width="size"
      :height="size"
      :view-box="iconViewBox"
      :fill="iconFill"
      :stroke="iconStroke"
    />
  </div>
</template>

<script>
import colors from '~/assets/scss/_variables.scss';

export default {
  props: {
    icon: {
      type: String,
      default: 'stroke',
    },

    size: {
      type: [Number, String],
      default: 20,
    },

    iconViewBox: {
      type: String,
      default: '0 0 20 20',
    },

    iconFill: {
      type: String,
      default: colors.WHITE,
    },

    iconStroke: {
      type: String,
      default: colors.BLUE_LIGHT,
    },

  },
};
</script>

<style lang="scss" scoped>
.t-spinner {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  svg {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__icon {
    z-index: 50;
  }
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    // 12.5% {
    //     top: 20px;
    // }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    // 62.5% {
    //     top: 50px;
    // }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);;
    }
    // from {
    //     transform:rotate(0deg);
    // }

    // to {
    //     transform:rotate(360deg);
    // }
}
</style>
