<template>
  <client-only>
    <ValidationProvider
      v-slot="{ errors }"
      tag="div"
      :vid="vid"
      :rules="validationRules"
      class="t-field"
      :class="{
        't-field--no-label': !hasLabel,
        't-field--loading': loading,
        't-field--focused-loading': canEditLoading && loading,
      }"
    >
      <TInput
        v-model="localValue"
        v-bind="{...$props, ...$attrs}"
        :error-messages="[
          ...($attrs['error-messages'] || []),
          ...errors
        ]"
        v-on="$listeners"
      />
    </ValidationProvider>
  </client-only>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TInputProvider',

  components: {
    ValidationProvider,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Date, Boolean],
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    canEditLoading: {
      type: Boolean,
      default: false,
    },

    validationRules: {
      type: [String, Object],
      default: null,
    },

    vid: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    localValue: '',
  }),

  computed: {
    hasLabel() {
      return Boolean(this.label);
    },
  },

  watch: {
    value(value) {
      this.localValue = value;
    },
  },

  mounted() {
    this.localValue = this.value;
  },

  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
