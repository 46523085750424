<template>
  <client-only>
    <ValidationProvider
      v-slot="{ errors }"
      tag="div"
      class="t-multiselect-new"
      :rules="validationRules"
    >
      <TMultiselect
        v-model="localValue"
        v-bind="{...$props, ...$attrs}"
        :tooltip-hint="tooltipHint"
        :error-messages="errors"
        v-on="$listeners"
      >
        <template slot="noResult">
          <slot name="noResult" />
        </template>
      </TMultiselect>
    </ValidationProvider>
  </client-only>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TMultiselectProvider',

  components: {
    ValidationProvider,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Array, Object],
      default: '',
    },

    validationRules: {
      type: [String, Object],
      default: null,
    },

    tooltipHint: {
      type: [String, Boolean],
      default: null,
    },
  },

  data: () => ({
    localValue: null,
  }),

  watch: {
    value(value) {
      this.localValue = value;
    },
  },

  mounted() {
    this.localValue = this.value;
  },

  methods: {
    unselectTag(index) {
      if (this.multiple) {
        const localValue = this.value.slice();

        localValue.splice(index, 1);
        this.$emit('input', localValue);
      }
    },
  },
};
</script>

<style lang="scss">
.t-multiselect-new {
  width: 100%;
}
</style>
