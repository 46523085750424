export const state = () => ({
  labels: {},
  transportersList: [],
  pagination: {},
  isTableLoading: false,
  query: {},
  backendQuery: {},
  isGroupsLoading: false,
  groupsList: [],
  accreditationLabels: {},
});

export const getters = {
  getLabels: state => state.labels,
  getTransportersList: state => state.transportersList,
  getPagination: state => state.pagination,
  isTableLoading: state => state.isTableLoading,
  getQuery: state => state.query,
  getBackendQuery: state => state.backendQuery,
  isGroupsLoading: state => state.isGroupsLoading,
  getGroupsList: state => state.groupsList,
  getAccreditationLabels: state => state.accreditationLabels,
};

export const mutations = {
  setLabels(state, payload) {
    state.labels = payload;
  },

  setTransportersList(state, payload) {
    state.transportersList = payload;
  },

  setPagination(state, payload) {
    state.pagination = payload;
  },

  setIsTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  saveQuery(state, payload) {
    state.query = payload;
  },

  setBackendQuery(state, payload) {
    state.backendQuery = payload;
  },

  setGroupsLoading(state, payload) {
    state.isGroupsLoading = payload;
  },

  setGroupsList(state, payload) {
    state.groupsList = payload;
  },

  addGroupToList(state, payload) {
    state.groupsList.push(payload);
  },

  setTransporterInsuranceRate(state, { contract_id, newInsuranceRate }) {
    const transporter = state.transportersList
      .find(transporter => transporter.contract?.id === contract_id);

    if (transporter) {
      transporter.contract = {
        ...transporter.contract,
        insurance_rate: newInsuranceRate,
      };
    }
  },

  setForwarderInsuranceRate(state, { contract_forwarder_id, newInsuranceRate }) {
    const forwarder = state.transportersList
      .find(forwarder => forwarder.customers[0]?.pivot.id === contract_forwarder_id);

    if (forwarder) {
      forwarder.customers[0].pivot.agreements[0] = {
        ...forwarder.customers[0].pivot.agreements[0],
        insurance_rate: newInsuranceRate,
      };
    }
  },

  setAccreditationLabels(state, payload) {
    state.accreditationLabels = payload;
  },
};

export const actions = {
  async loadLabels({ commit }) {
    const { data } = await this.$axios.get('transporters/labels');

    commit('setLabels', data.labels);
  },

  async loadTransporters({ commit }, params) {
    commit('setIsTableLoading', true);

    try {
      // const { data } = await this.$axios.get(
      // 'companies/transporters-list-for-reference',
      // 'companies',
      // { params: { ...params, tab: params.tab || 'all' } },
      // { params: { ...params, roles: ['transporter'] } },
      // const { data } = await this.$axios.get(
      //   // 'companies/transporters-list-for-reference',
      //   'companies',
      //   // { params: { ...params, tab: params.tab || 'all' } },
      //   { params: { ...params, roles: ['transporter'] } },
      // );

      const { data } = await this.$axios.get(
        '/companies-areal/transporters',
        { params },
      );

      const { data: transportersList, meta: pagination } = data;
      const { meta: backendQuery } = data;

      commit('setTransportersList', transportersList);
      commit('setPagination', pagination);
      commit('setBackendQuery', backendQuery);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setIsTableLoading', false);
    }
  },

  async loadGroups({ commit }, params) {
    commit('setGroupsLoading', true);

    try {
      const { data } = await this.$axios.get(
        'transporters/check-groups',
        { params },
      );

      commit('setGroupsList', data.groups);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setGroupsLoading', false);
    }
  },

  async loadAccreditationLabels({ commit }) {
    try {
      const { data } = await this.$axios.get('companies/labels');
      commit('setAccreditationLabels', data.labels.statuses);
    } catch (error) {
      console.log(error);
    }
  },

  async storeInGroups({ commit, dispatch, getters }, payload) {
    try {
      await this.$axios.post('/transporters/store-in-groups', { groups: payload });

      dispatch('loadTransporters', getters.getQuery);
    } catch (error) {
      console.log(error);
    }
  },

  async updateTransporterInsuranceRate({ dispatch }, payload) {
    await this.$axios.post('/companies/insurance-rate', {
      ...payload,
    }).catch(error => {
      console.log(error);
    });
  },
};
