<template>
  <span class="sidepanel-counter">
    {{count}}
  </span>
</template>

<script>

export default {
  name: 'SidepanelCounter',

  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.sidepanel-counter{
  font: $t5-medium;
  font-weight: 600;
  color: $gray-dark-1;
  border-radius: 5px;
  padding: 4px 5px;
  border: 1px solid $gray-dark-1;
  background-color: $white;
}
</style>
