export const regex = {
  phoneRegex: /^((7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
};

export const I18N_COOKIE = 'i18n_locale';

export const RELOAD_TRIGGER = 'reloadTrigger';

export const FESCO_SUPPORT_EMAIL = 'MFSupport@fesco.com';

export const FESCO_SUPPORT_PHONE = '8 800 23-444-99';

export const isTmsCore = process.env.IS_CORE === 'true';

export const APP_NAME = process.env.APP_NAME || 'Fesco';

// Profile
export const RUSSIA_ISO_CODE = 0;
export const PHYSICAL_ENTITY_TYPE = 2;
export const LEGAL_ENTITY_TYPE = 1;

export const ADMIN_ROLE_SLUG = 'admin';
export const TRANSPORTER_ROLE_SLUG = 'transporter';
export const CLIENT_ROLE_SLUG = 'client';
export const FORWARDER_ROLE_SLUG = 'forwarder';
export const DISPATCHER_ROLE_SLUG = 'dispatcher';

export const MENU_SECTIONS = {
  [ADMIN_ROLE_SLUG]: [
    'applications',
    'orders',
    'trips',
    'customers',
    'fines',
    'transporters',
    'cars',
    'drivers',
    'users',
    'admin',
    'auctions',
    'instructions',
  ],
  [DISPATCHER_ROLE_SLUG]: [
    'applications',
    'orders',
    'trips',
    'road_trains',
    'customers',
    'fines',
    'transporters',
    'cars',
    'drivers',
    'users',
    'auctions',
    'instructions',
  ],
  [FORWARDER_ROLE_SLUG]: [
    'applications',
    'orders',
    'trips',
    'road_trains',
    'customers',
    'fines',
    'transporters',
    'cars',
    'drivers',
    'users',
    'auctions',
    'instructions',
  ],
  [TRANSPORTER_ROLE_SLUG]: [
    'trips',
    'road_trains',
    'cars',
    'drivers',
    'users',
    'profile',
    'auctions',
    'instructions',
  ],
};

export const INCORRECT_DOCUMENTS = 9;

export const ORDER_STATUSES = {
  DRAFT_STATUS: 1,
  WAITING_FOR_TRANSPORTER_STATUS: 2,
  TAKEN_STATUS: 3,
  IN_PROCESS_STATUS: 4,
  MOVED_STATUS: 5,
  CLOSED_STATUS: 6,
  CANCELED_STATUS: 7,
  ON_APPROVAL_STATUS: 8,
  STATUS_NOT_DISTRIBUTED: 9,
  DELETED_STATUS: 10,
  PLANNED: 12,
};

export const INSURANCE_STATUSES = {
  FOR_CONSIDERATION_STATUS: 1,
  INSURED_STATUS: 2,
  NOT_INSURED_STATUS: 3,
};

// Roles
export const TRANSPORTER_ROLES = {
  TRANSPORTERS_PLATFORM_ROLE: 0,
  TECHNICAL_COUNTERPARTIES_ROLE: 1,
};

// Correspondence
export const LETTER_STATUSES = {
  RECEIVED_STATUS: 1,
  PROCESSED_STATUS: 2,
  DELETED_STATUS: 3,
};
export const DEFAULT_DELIVERY_SERVICE_ID = 1;

// Documents
export const APPROVE_TYPE = 2;
export const CANCEL_TYPE = 3;

export const DOC_APPROVED = 3;
export const DOC_CANCELED = 2;
export const DOC_NEW = 1;

// Transports
export const TRANSPORT_STATUSES = {
  NEW: 1,
  CANCELED: 2,
  APPROVED: 3,
  ONE: 4,
  TWO: 5,
};

export const OFFICE_STATUSES = {
  MSK: 1,
  SPB: 2,
  EKB: 3,
  HBR: 4,
  VLV: 5,
};

// Drivers
export const DRIVER_STATUSES = {
  NEW: 1,
  CANCELED: 2,
  APPROVED: 3,
};

// Companies
export const COMPANY_STATUSES = {
  ACCREDITED: 4,
  NEW: 1,
  CANCELED: 2,
  APPROVED: 3,
  ACCREDITATION_OVERDUE: 5,
  BLOCKED: 6,
};

// Colors
export const YELLOW_COLOR = 'yellow';
export const RED_COLOR = 'red';
export const GREEN_COLOR = 'green';

// Role
export const TRANSPORTER_ID = 4;
export const PARTNER = 8;

// Order modules
export const ORDER_MODULE_TYPE_AUTO = 0;

// Type
export const TYPE_CONTRACTOR_ALL = -1;

// Orders
export const ORDER_STATUSES_ICONS = {
  NEW: {
    icon: 'question-circle',
    id: 1,
    color: 'warning',
  },
  AGREED: {
    icon: 'check-circle',
    id: 2,
    color: 'dark-orange',
  },
  PLANNED: {
    icon: 'calendar',
    id: 3,
    color: 'warning',
  },
  PENDING_APPROVAL: {
    icon: 'time-square',
    id: 4,
    color: 'warning',
  },
  CONFIRMED: {
    icon: 'check-circle',
    id: 5,
    color: 'regular',
  },
  IN_PROGRESS: {
    icon: 'car',
    id: 6,
    color: 'regular',
  },
  RUNNING: {
    icon: 'check-circle',
    id: 7,
    color: 'success',
  },
  REFUSAL: {
    icon: 'close-square-2',
    id: 8,
    color: 'danger',
  },
  CANCELED: {
    icon: 'decline',
    id: 9,
    color: '#6F7B89',
  },
  BIDDING_RUNNING: {
    icon: 'arrow-square-right',
    id: 10,
    color: 'regular',
  },
  COMPLETED: {
    icon: 'check-2',
    id: 11,
    color: 'success',
  },
};

// Trips
export const TRIP_STATUSES_ICONS = {
  SCHEDULED: { // Запланирован
    icon: 'whiteCalendar',
    id: 1,
    color: 'warning',
  },
  PENDING_APPROVAL: { // На подтверждении
    icon: 'time-square',
    id: 2,
    color: 'warning',
  },
  CONFIRMED: { // Подтвержден
    icon: 'check-circle',
    id: 3,
    color: 'regular',
  },
  IN_PROGRESS: { // Выполняется
    icon: 'car',
    id: 4,
    color: 'regular',
  },
  DELIVERED: { // Груз доставлен
    icon: 'location',
    id: 5,
    color: 'regular',
  },
  COMPLETED: { // Пройден
    icon: 'check-circle',
    id: 6,
    color: 'success',
  },
  CANCELED: { // Удалён
    icon: 'delete',
    id: 7,
    color: 'danger',
  },
  REVOCATION: {
    icon: 'decline',
    id: 8,
    color: 'info',
  },
  ENDED: { // Выполнен
    icon: 'check-2',
    id: 9,
    color: 'success',
  },
  DRAFT: { // Черновик
    icon: 'question-circle',
    id: 10,
    color: 'info',
  },
};

// Containers
export const ORDER_CONTAINER_STATUSES_ICONS = {
  NO_STATUS: {
    icon: 'question-circle',
    id: 1,
    color: '#6f7b89',
  },
  ARRIVED: {
    icon: 'location',
    id: 2,
    color: '#fe991f',
  },
  READY_FOR_EXPORT: {
    icon: 'transshipment',
    id: 3,
    color: '#347af0',
  },
  EXPORTED: {
    icon: 'truck',
    id: 4,
    color: '#65d0cb',
  },
  INSPECTION: {
    icon: 'danger-triangle',
    id: 5,
    color: '#fb5858',
  },
  CLOSED: {
    icon: 'tick-square',
    id: 6,
    color: '#65d0cb',
  },
};

// Available transport
export const PLATFORM_TYPES = {
  20: 1,
  '20-40': 2,
  40: 3,
  '20-40-45': 4,
};

// Transport types
export const TRANSPORT_TYPES = {
  CARS: 1,
  TRAILERS: 2,
};

// Trailer types
export const TRAILER_TYPES = {
  TRACTOR: 1,
  CAR: 2,
  LOADER: 3,
  TRAILER: 4,
};

// Order operation status
export const ORDER_OPERATION_STATUSES = {
  STATUS_ARRIVED: 1,
  STATUS_DEPARTED: 2,
  STATUS_TRIP_PROBLEM: 3,
  STATUS_UNREACHED: 4,
};

// Role Driver
export const DRIVER_ROLE = 'driver';

// Trips
export const TRIPS_STATUSES = {
  SCHEDULED: 1,
  PENDING_APPROVAL: 2,
  CONFIRMED: 3,
  IN_PROGRESS: 4,
  DELIVERED: 5,
  COMPLETED: 6,
  STATUS_DELETED: 7,
  DRAFT: 10,
};
