<template>
  <div>
    <slot />
    <TToast
      v-if="toastMessage"
      :with-timer="toastMessage.withTimer"
      :timer-seconds="toastMessage.timerSeconds"
      @close="hideToastMessage"
    >
      <div slot="body">
        <div v-html="toastMessage.html" />
      </div>
    </TToast>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AppEntry',
  computed: {
    ...mapGetters({
      toastMessage: 'general/getToastMessage',
      currentUser: 'main/getCurrentUser',

    }),
  },

  methods: {
    ...mapMutations({
      hideToastMessage: 'general/hideToastMessage',
    }),
  },
};
</script>
