// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.t-slider .t-field__label{position:relative;font:400 12px/14px \"Graphik LCG\";color:#a7b5ce}.t-slider__container{position:relative;margin:5px 0}.t-slider__fake-rail{background-color:#c9d2e1;border-radius:15px;width:100%;height:3px;position:absolute;top:7px}.t-slider__inputs{display:flex;justify-content:space-between}.t-slider__input{width:100px!important}.t-slider__input .t-field__field{padding:0 12px}.vue-slider-disabled{opacity:.5;cursor:not-allowed}.vue-slider-rail{background-color:#c9d2e1;border-radius:15px}.vue-slider-process{background-color:#fe991f;border-radius:15px}.vue-slider-dot-handle{cursor:pointer;width:100%;height:100%;border-radius:50%;background-color:#fff;box-sizing:border-box;border:2px solid #fe991f}.vue-slider-dot-handle-disabled{cursor:not-allowed;background-color:#c9d2e1}.vue-slider-dot{position:absolute;width:13px!important;height:13px!important;transition:all 0s;z-index:4}.vue-slider-dot-hover:hover .vue-slider-dot-tooltip,.vue-slider-dot-tooltip-show{visibility:visible}.vue-slider{position:relative;box-sizing:content-box;-webkit-user-select:none;-moz-user-select:none;user-select:none;display:block;-webkit-tap-highlight-color:rgba(0,0,0,0);width:calc(100% - 14px)!important;margin-left:7px}.vue-slider-rail{position:relative;width:100%;height:3px;transition-property:width,height,left,right,top,bottom}.vue-slider-process{position:absolute;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
