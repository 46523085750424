export default [
  {
    page: 'orders',
    routeName: 'orders-index-edit-id',
    link: '/applications',
    text: 'К списку заявок',
  },
  {
    page: 'applications',
    routeName: 'orders-trip-edit-id',
    link: '/applications',
    text: 'К списку',
  },
  {
    page: 'trips',
    routeName: 'trips-index-trip-id',
    link: '/trips',
    text: 'К списку рейсов',
  },
  {
    page: 'trips',
    routeName: 'trips-edit-id',
    link: '/trips',
    text: 'К списку рейсов',
  },
  {
    page: 'users',
    routeName: 'users-index-edit-id',
    link: '/users',
    text: 'К списку сотрудников',
  },
  {
    page: 'users',
    routeName: 'users-index-create',
    link: '/users',
    text: 'К списку сотрудников',
  },
  {
    page: 'drivers',
    routeName: 'drivers-index-info-id-companyId',
    link: '/drivers',
    text: 'К списку водителей',
  },
  {
    page: 'drivers',
    routeName: 'drivers-index-edit-id-companyId',
    link: '/drivers',
    text: 'К списку водителей',
  },
  {
    page: 'transport',
    routeName: 'transport-index-info-id',
    link: '/transport',
    text: 'К списку транспорта',
  },
  {
    page: 'transport',
    routeName: 'transport-index-edit-id',
    link: '/transport',
    text: 'К списку транспорта',
  },
  {
    page: 'fines',
    routeName: 'fines-index-info-id',
    link: '/fines',
    text: 'К списку штрафов',
  },
  {
    page: 'fines',
    routeName: 'fines-index-edit-id',
    link: '/fines',
    text: 'К списку штрафов',
  },
  {
    page: 'companies',
    routeName: 'companies-index-info-id',
    link: '/companies',
    text: 'К списку контрагентов',
  },
  {
    page: 'transporters',
    routeName: 'transporters-index-info-id',
    link: '/transporters',
    text: 'К списку перевозчиков',
  },
  {
    page: 'bidding',
    routeName: 'bidding-index-create',
    link: '/bidding',
    text: 'К списку процедур',
  },
  {
    page: 'bidding',
    routeName: 'bidding-index-info-id',
    link: '/bidding',
    text: 'К списку процедур',
  },
  {
    page: 'bidding',
    routeName: 'bidding-index-edit-id',
    link: '/bidding',
    text: 'К списку процедур',
  },
];
