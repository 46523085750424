<template>
  <DefaultLayout :header-shown="true" />
</template>

<script>
import DefaultLayout from '../components/Layouts/DefaultLayout';

export default {
  components: { DefaultLayout },
};
</script>
