<template>
  <svg
    class="t-sort"
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :opacity="isSortByAsc ? 1 : 0.3"
      d="M7.45163 2.79091C7.71676 2.40824 8.2825 2.40824 8.54763 2.79091L10.7388 5.95361C11.0451 6.39575 10.7287 6.99994 10.1908 6.99994H5.80844C5.27056 6.99994 4.95413 6.39575 5.26045 5.95361L7.45163 2.79091Z"
      fill="#347AF0"
      @click="changeSort('asc')"
    />
    <path
      :opacity="isSortByDesc ? 1 : 0.3"
      d="M7.45163 13.209C7.71676 13.5917 8.2825 13.5917 8.54763 13.209L10.7388 10.0463C11.0451 9.6042 10.7287 9 10.1908 9H5.80844C5.27056 9 4.95413 9.6042 5.26045 10.0463L7.45163 13.209Z"
      fill="#347AF0"
      @click="changeSort('desc')"
    />
  </svg>
</template>

<script>
export const SortingState = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
  DEFAULT: 'default',
};

export default {
  name: 'TSort',

  props: {
    value: {
      type: String,
      default: SortingState.DEFAULT,
    },

    width: {
      type: String,
      default: '16',
    },

    height: {
      type: String,
      default: '16',
    },
  },

  data() {
    return {
      sortBy: '',
    };
  },

  computed: {
    isSortByAsc() {
      return this.sortBy === SortingState.ASCENDING;
    },

    isSortByDesc() {
      return this.sortBy === SortingState.DESCENDING;
    },

    isSortByDefault() {
      return this.sortBy === SortingState.DEFAULT;
    },
  },

  watch: {
    value(newValue) {
      this.sortBy = newValue || SortingState.DEFAULT;
    },
  },

  created() {
    this.sortBy = this.value;
  },

  methods: {
    changeSort(sortBy) {
      const newSort = sortBy === this.sortBy ? SortingState.DEFAULT : sortBy;

      this.$emit('input', newSort);
    },
  },
};
</script>

<style lang="scss" >
.t-sort {
  cursor: pointer;
  pointer-events: fill;
}
</style>
