var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{staticClass:"t-field",class:{
    't-field--no-label': !_vm.hasLabel,
    't-field--loading': _vm.loading,
    't-field--focused-loading': _vm.canEditLoading && _vm.loading,
  },attrs:{"tag":"div","rules":_vm.validationRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [(_vm.hasLabel)?_c('span',{staticClass:"t-field__label",class:{
      't-field__label--error': _vm.hasErrors || errors.length,
      't-field__label--success': !_vm.hasErrors && !errors.length && _vm.hasSuccessMessages,
    }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"t-field__field-wrapper t-field__field--multiline"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"inputComponentRef",staticClass:"t-field__field",class:{
        't-field__field--error': _vm.hasErrors || errors.length,
        't-field__field--success': !_vm.hasErrors && !errors.length && _vm.hasSuccessMessages,
        't-field__field--auto-expanded': _vm.isAutoExpanded,
      },attrs:{"id":_vm.id,"rows":_vm.rows,"placeholder":_vm.placeholder || undefined,"name":_vm.name,"maxlength":_vm.maxLength,"disabled":_vm.isDisabled,"readonly":_vm.readOnly},domProps:{"value":(_vm.localValue)},on:{"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')},"input":[function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value},_vm.handleInput],"change":function($event){return _vm.$emit('change', $event.target.value)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.$emit('enter', $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"t-field__actions"},[_c('div',{staticClass:"t-field__actions-icon"},[(_vm.hasErrors)?_c('TIconSvg',{attrs:{"name":'danger-triangle',"width":24,"height":24,"view-box":'0 0 24 24',"fill":"none","stroke":_vm.$options.RED_LIGHT}}):_vm._e()],1),_vm._v(" "),_vm._t("actions")],2)]),_vm._v(" "),(_vm.hasErrors || errors.length)?_c('div',{staticClass:"t-field__hint t-field__hint--error j-error"},[_vm._v("\n    "+_vm._s(errors.length ? errors[0] : '')+"\n    "+_vm._s(_vm.errorMessages[0])+"\n  ")]):_vm._e(),_vm._v(" "),(!_vm.hasErrors && !errors.length && _vm.hasSuccessMessages)?_c('div',{staticClass:"t-field__hint t-field__hint--success-message"},[_vm._v("\n    "+_vm._s(Array.isArray(_vm.successMessages) ? _vm.successMessages[0] : _vm.successMessages)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.hint && !_vm.hasErrors && !errors.length && !_vm.hasSuccessMessages)?_c('div',{staticClass:"t-field__hint"},[_vm._v("\n    "+_vm._s(_vm.hint)+"\n  ")]):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }