<template>
  <div class="t-divider" />
</template>

<script>
export default {
  name: 'TDivider',
};
</script>

<style lang="scss">
  @import 'assets/scss/_variables';

  .t-divider {
    height: 1px;
    width: 100%;
    background-color: $gray-light-1;
  }
</style>
