<template>
  <div
    v-tooltip="disabled ? {
      content: tooltipHint,
      trigger: 'hover',
      delay: { show: 100, hide: 200 },
      autoHide: true,
    } : null"
    class="t-field"
    :class="{
      't-field--no-label': !hasLabel,
      't-field--loading': loading,
      't-field--focused-loading': canEditLoading && loading,
      't-field--active': isActive || value || value === 0,
      't-field--actions': hasActions
    }"
  >
    <div :class="[!iconPrepend ? 't-field__field-wrapper' : 'prepend-wrapper']">
      <div
        v-if="iconPrepend"
      >
        <slot name="start-adornment" />
      </div>
      <Component
        :is="multiline ? 'textarea' : 'input'"
        :id="id"
        ref="inputComponentRef"
        :class="{
          't-field__field': !iconPrepend,
          't-field__field--error': hasErrors,
          't-field__field--success': !hasErrors && hasSuccessMessages,
          't-field__field--multiline': multiline,
          't-field__field--auto-expanded': isAutoExpanded,
          't-field__field--border-transparent': borderTransparent,
          't-field__field prepend-icon-input': iconPrepend,
          ...sizeableClasses
        }"
        :type="multiline ? undefined : type"
        :rows="multiline ? rows : undefined"
        :placeholder="isActive || !label ? placeholder : ''"
        :required="required"
        :min="min"
        :max="max"
        :step="step || 1"
        :value.prop="value"
        :name="name"
        :maxlength="maxLength"
        :disabled="isDisabled"
        :autocomplete="multiline ? undefined : autocomplete"
        :readonly="readOnly"
        @blur="$emit('blur'), isActive = false"
        @focus="$emit('focus'), isActive = true"
        @input="handleInput"
        @change="$emit('change', $event.target.value)"
        @keydown.enter="onEnter"
        @keyup="$emit('keyup', $event.target.value)"
      />
      <span
        v-if="hasLabel"
        class="t-field__label t-field__label-input"
        :class="{
          't-field__label--error': hasErrors,
          't-field__label--success': !hasErrors && hasSuccessMessages,
          required,
          ...fontSizeableClasses,
        }"
      >
        {{label}}
      </span>
      <div class="t-field__actions">
        <TIconSvg
          v-if="calendar"
          name="calendar"
          :stroke="disabled ? $options.colors.GRAY_LIGHT : $options.colors.BLUE_LIGHT"
          view-box="0 0 24 24"
          width="24"
          height="24"
          class="t-datepicker__icon"
          @click="openCal"
        />
        <v-fade-transition>
          <TIconSvg
            v-if="hasClearValue && value && !(disabled || loading)"
            class="s-datepicker__clear"
            name="close"
            view-box="0 0 24 24"
            height="24"
            width="24"
            :stroke="$options.colors.GRAY_DARK_2"
            :fill="$options.GRAY_DARK_1"
            @click.stop="$emit('input', null)"
          />
        </v-fade-transition>
        <div
          v-if="hasErrors"
          class="t-field__actions-icon"
        >
          <TIconSvg
            v-if="hasErrors && !hasToast"
            :name="'danger-triangle'"
            :width="24"
            :height="24"
            :view-box="'0 0 24 24'"
            fill="none"
            :stroke="$options.RED_LIGHT"
          />
        </div>
        <slot name="actions" />
      </div>
    </div>
    <div
      v-if="hasErrors && !hasToast && !dangerCode"
      class="t-field__hint t-field__hint--error j-error"
    >
      {{errorMessages[0]}}
    </div>
    <slot
      v-if="hasErrors"
      name="toast"
    />
    <div
      v-if="!hasErrors && hasSuccessMessages"
      class="t-field__hint t-field__hint--success-message"
    >
      {{Array.isArray(successMessages) ? successMessages[0] : successMessages}}
    </div>
    <div
      v-if="hint && !hasErrors && !hasSuccessMessages"
      class="t-field__hint"
    >
      {{hint}}
    </div>
  </div>
</template>

<script>
// Utils
import { isEmpty } from 'chober';

// Constants
import colors from '@/assets/scss/_variables.scss';

// Mixins
import sizes from '~/assets/js/mixins/sizes';

export default {
  name: 'TInput',

  mixins: [
    sizes,
  ],

  colors,

  RED_LIGHT: colors.RED_LIGHT,
  GRAY_LIGHT: colors.GRAY_LIGHT,

  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    value: {
      type: [String, Number, Boolean],
      default: null,
    },

    name: {
      type: String,
      default: '',
    },

    hint: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: 'text',
    },

    min: {
      type: [Number, String],
      default: null,
    },

    max: {
      type: [Number, String],
      default: null,
    },

    required: {
      type: [Boolean, String],
      default: false,
    },

    iconPrepend: {
      type: Boolean,
      default: false,
    },

    step: {
      type: [Number, String],
      default: 1,
    },

    autocomplete: {
      type: String,
      default: 'off',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isSearch: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    canEditLoading: {
      type: Boolean,
      default: false,
    },

    multiline: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: [String, Number],
      default: -1, // unlimited
    },

    rows: {
      type: [Number, String],
      default: 1,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    successMessages: {
      type: [Array, String],
      default: '',
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    isAutoExpanded: {
      type: Boolean,
      default: false,
    },

    borderTransparent: {
      type: Boolean,
      default: false,
    },

    tooltipHint: {
      type: String,
      default: '',
    },

    dangerCode: {
      type: Boolean,
      default: false,
    },

    calendar: {
      type: Boolean,
      default: false,
    },

    isOpenedCalendar: {
      type: Boolean,
      default: false,
    },

    hasClearValue: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isActive: false,
  }),

  computed: {
    hasLabel() {
      return Boolean(this.label);
    },

    hasErrors() {
      return !isEmpty(this.errorMessages);
    },

    hasSuccessMessages() {
      return !isEmpty(this.successMessages);
    },

    isDisabled() {
      return this.disabled || (this.loading && !this.canEditLoading);
    },

    hasToast() {
      return this.$slots.toast !== undefined;
    },

    hasActions() {
      return this.$slots.actions !== undefined;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.isAutoExpanded) {
        this.setAutoExpandedTextareaHeight(this.$refs.inputComponentRef);
      }
    });
    if (this.autofocus) {
      this.$refs.inputComponentRef.focus();
    }
  },

  methods: {
    handleInput(event) {
      if (this.isAutoExpanded) {
        this.setAutoExpandedTextareaHeight(event.target);
      }

      this.$emit('input', event.target.value);
    },

    setAutoExpandedTextareaHeight(textarea) {
      const field = textarea;

      if (field) {
        const fieldComputedStyles = window.getComputedStyle(field);

        field.style.height = 'inherit';

        const heightNeeded = parseInt(fieldComputedStyles.getPropertyValue('border-top-width'), 10)
          + field.scrollHeight
          + parseInt(fieldComputedStyles.getPropertyValue('border-bottom-width'), 10);

        field.style.height = `${heightNeeded}px`;
      }
    },

    onEnter(event) {
      if (!this.multiline) {
        event.preventDefault();
        this.$emit('enter', event.target.value);
      }
    },

    openCal(value) {
      this.$emit('click', !this.isOpenedCalendar);
    },
  },
};
</script>
<style lang="scss">
@import '~/assets/scss/_variables';
@import '~/assets/scss/_mixins';

  .t-field {
    &__field {
      &--disabled {
        border: 1px solid red;
      }

      &--multiline::-webkit-scrollbar {
        position: relative;
        z-index: 999;
        height: 6px;
        width: 6px;
      }

      &--multiline::-webkit-scrollbar-track {
        background: $gray-light-2;
        width: auto;
        height: auto;
        opacity: 0.4;
        border-radius: 6px;
      }

      &--multiline::-webkit-scrollbar-thumb {
        background-color: rgba(0, 50, 160, 0.16);
        border-radius: 6px;
      }
    }
  }

  .t-field__field--disabled {
    border: 1px solid red;
  }

  .t-field__label.t-field__label-input{
    @include centerAbsoluteY;
    color: $gray-dark-2;
    left: 16px;
    transition: all .3s;
    padding-right: 10px !important;
    max-width: fit-content;

    &.required {
      &::after {
        position: absolute;
        content: '*';
        right: 0;
        top: 0;
        color: $orange-light;
        font-size: 12px;
      }
    }
  }

  .t-field--active .t-field__label-input{
    font: $t4-regular;
    background-color: $white;
    padding: 0 6px;
    top: 0;
    left: 10px;
    right: auto;
  }

  .t-field--actions{
    .t-field__field, input{
      padding-right: 45px;
    }
    .t-field__field--error, input{
      padding-right: 75px;
    }
  }

  .t-field__actions{
    @include centerAbsoluteY;
    right: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 3;
    .t-button.t-button--without-text{
      width: 100%;
      display: flex;
    }
  }

  .t-field__actions-icon{
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    height: 44px;
    cursor: pointer;
  }

  .prepend-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .prepend-icon-input {
    position: relative;
    z-index: 2;
    background-color: #fff;
    outline: none;
    width: 100%;
    border: 1px solid #c9d2e1;
    padding: 19px 19px 19px 30px !important;
    resize: vertical;
    transition: border-color 0.15s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
</style>
