// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.t-switch{height:20px;width:32px;position:relative;margin-bottom:0;display:block;cursor:pointer}.t-switch input{margin-top:0;padding:0;position:absolute;z-index:-1;opacity:0}.t-switch:before{background-color:#a7b5ce;height:20px;top:0;width:32px;left:0}.t-switch:after,.t-switch:before{border-radius:12px;content:\"\";position:absolute;transition:all .2s ease-in-out}.t-switch:after{top:50%;transform:translateY(-50%);background-color:#fff;height:16px;left:2px;width:16px}.t-switch:hover:before{background-color:#6f7b89}.t-switch--checked:before{background-color:#347af0}.t-switch--checked:after{left:14px}.t-switch--checked:hover:before{background-color:#0032a0}.t-switch--disabled:before{background-color:#e2eaf6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
