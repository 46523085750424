<template>
  <AppEntry>
    <div class="class">
      <div class="sidebar-layout">
        <Sidepanel />
        <div class="sidebar-layout__content">
          <MainHeader v-if="headerShown" />
          <nuxt />
        </div>
      </div>
    </div>
  </AppEntry>
</template>

<script>
import Sidepanel from '../Features/Sidepanel';
import MainHeader from '../Features/MainHeader';
import AppEntry from '~/AppEntry';

export default {
  components: {
    AppEntry,
    Sidepanel,
    MainHeader,
  },
  props: {
    headerShown: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.sidebar-layout {
  display: flex;

  &__content {
    position: relative;
    min-width: $min-main-content-width;
    flex: 1;

    &--not-overflown {
      min-width: auto;
      margin-left: -16px;
    }
  }
}
</style>
