// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme-light.v-banner.v-sheet{background-color:transparent}.theme-light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped) .v-banner__wrapper{border-bottom:none}.header-menu{position:absolute;bottom:0;right:0;z-index:10;padding:8px 1px;width:100%;background-color:#fff;transform:translateY(100%);border:1px solid #dbe0ea;min-width:200px;max-width:200px}.header-menu:hover{background-color:#eff3fa}.header-menu__companies{margin:0;padding-left:0}.header-menu__company{display:flex;justify-content:space-between;align-items:center;color:#c4c4c4;font-size:12px;text-overflow:ellipsis;overflow:hidden}.header-menu__company:not(:last-child){margin-bottom:5px}.header-menu__company-text{max-width:90%;text-overflow:ellipsis;white-space:nowrap;overflow:hidden}.header-menu__company .t-radio.t-radio--checked:before{background-color:#ffcc67}.header-menu__company .t-radio__text-container{display:none}.header-menu__button{width:100%;border-radius:0}.header-menu__button:hover:not([disabled]){color:#001e42}.header-menu__error{white-space:normal;background-color:hsla(0,0%,100%,.4)}.header-menu .t-button{justify-content:flex-start;font:400 14px/18px \"Graphik LCG\";transition:opacity .3s;padding:8px 16px;color:#001e42}.header-menu .t-button--transparent:hover:not(.t-button--disabled){color:#001e42}.header-menu .t-button__icon{margin-right:12px}.header-menu .t-button:active{opacity:.5}.header-menu-enter-active,.header-menu-leave-active{transition:opacity .3s}.header-menu-enter,.header-menu-leave-to{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WHITE": "#fff",
	"BLUE_LIGHT": "#347af0",
	"BLUE_DARK": "#0032a0",
	"GRAY_LIGHT": "#dbe0ea",
	"GRAY_LIGHT_2": "#c9d2e1",
	"CYAN_DARK": "#6ebca5",
	"CYAN_LIGHT": "#65d0cb",
	"RED_LIGHT": "#fb5858",
	"RED_ULTRA_LIGHT": "#ffebec",
	"RED_DARK": "#f41414",
	"ORANGE_LIGHT": "#fe991f",
	"ORANGE_ULTRA_DARK": "#ff6900",
	"GRAY_DARK_1": "#b5c3db",
	"GRAY_DARK_2": "#a7b5ce",
	"GRAY_ULTRA_DARK": "#6f7b89",
	"GRAY_ULTRA_LIGHT_2": "#eff3fa",
	"PINK_LIGHT_1": "#fbd4d4"
};
module.exports = ___CSS_LOADER_EXPORT___;
